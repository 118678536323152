import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

export const CascadeLogo = () => (
  <Svg width={24} height={24} viewBox="0 0 300 300">
    <Path
      d="M274.86,24.67v113.31h-29.46c-5.33-43.79-40.05-78.52-83.85-83.85v-29.46h113.31Z"
      fill="#ff7e35"
      strokeWidth={0}
    />
    <Path
      d="M137.45,245.94v29.46H24.14v-113.31h29.5c5.33,43.79,40.02,78.52,83.81,83.85Z"
      fill="#ff7e35"
      strokeWidth={0}
    />
    <Path
      d="M134.81,53.9c-41.57,6.5-74.4,39.36-80.9,80.93L0,80.89,80.9,0l53.91,53.9Z"
      fill="#ff7e35"
      strokeWidth={0}
    />
    <Path
      d="M300,219.07l-80.9,80.92-53.87-53.9c41.53-6.54,74.36-39.36,80.86-80.93l53.91,53.9Z"
      fill="#ff7e35"
      strokeWidth={0}
    />
  </Svg>
)
