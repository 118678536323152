import React, { useContext, useEffect, useState } from 'react'
import { formatISO } from 'date-fns'
import { useQuery } from 'react-query'

import Pagination from '@components/pagination'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { CollateralPoolResponse } from '@interfaces/manage-monitor-borrowing-base'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

import { is_number, is_percentage, SEPARATOR_COLUMN } from '../helper'

const CollateralL4 = () => {
  const { company, appliedFilters, activeFilters } = useContext(AuthContext)

  const { calculationDate } = activeFilters
  const {
    activeFacilityId: appliedActiveFacilityId,
    calculationDate: appliedCalculationDate,
  } = appliedFilters

  const poolFilters = {
    slug_name: company?.slug_name ?? '',
    facility_id: appliedActiveFacilityId,
    calculation_date: formatISO(appliedCalculationDate),
  }

  const { data: poolData, isFetching: isPoolFetching } = useQuery(
    ['pool', poolFilters],
    () => BorrowingBaseService.collateralPool(poolFilters),
    {
      ...getStaleMins(),
      enabled: !!calculationDate,
    }
  )

  const no_pool = { pool_name: 'All', pool_id: 0 }
  const tabs = [no_pool, ...(poolData ?? [])]

  const [activePool, setActivePool] = useState<CollateralPoolResponse>(tabs[0])
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])
  const PER_PAGE = 10
  const LIMITS = Array(5)
    .fill('')
    .map((_, i) => PER_PAGE * (i + 1))
  const [page, setPage] = useState<number>(1)
  const [per_page, setPerPage] = useState<number>(LIMITS[0])

  const filters = {
    ...poolFilters,
    page,
    per_page,
    pool:
      activePool?.pool_id === no_pool.pool_id ? undefined : activePool.pool_id,
  }

  const { data, isFetching } = useQuery(
    ['collateral', filters],
    () => BorrowingBaseService.collateral(filters),
    {
      ...getStaleMins(),
      enabled: !!calculationDate,
    }
  )

  const first_data: { [key: string]: any } = (data?.data ?? [])[0] ?? {}
  const separatorIndex = parseInt(first_data[SEPARATOR_COLUMN] ?? 0)

  const [columns, expandedColumns] = Object.keys(first_data).reduce(
    (_p: any[], _c) => {
      let [_columns, _expandedColumns] = _p
      if (_c === SEPARATOR_COLUMN) {
        return [_columns, _expandedColumns]
      }

      const is_right_aligned =
        is_percentage(first_data[_c]) || is_number(first_data[_c])

      const new_column = {
        title: _c.replaceAll('_', ' '),
        field: _c,
        align: is_right_aligned ? 'right' : 'center',
        render: (r: any) => r[_c],
      }

      _expandedColumns =
        _columns.length === separatorIndex
          ? [..._expandedColumns, new_column]
          : _expandedColumns

      _columns =
        _columns.length < separatorIndex ? [..._columns, new_column] : _columns

      return [_columns, _expandedColumns]
    },
    [[], []]
  )

  const expandedRowRender = (record: any) => {
    return (
      <div className="grid grid-cols-3">
        {expandedColumns.map((item: any, i: number) => (
          <div
            key={i}
            className={`flex justify-between px-4 py-2 border-neutral-border-2 ${
              i % 3 === 2 ? '' : 'border-r'
            } ${i < expandedColumns.length - 3 ? 'border-b' : ''}`}
          >
            <span className="font-medium capitalize">{item.title}</span>
            <span>{item.render(record)}</span>
          </div>
        ))}
      </div>
    )
  }
  const onExpand = (index: number) => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(i => i !== index)
      : [...expandedIndexes, index]

    setExpandedIndexes(indexes)
  }

  useEffect(() => {
    setPage(1)
  }, [activePool])

  return (
    <div className="flex flex-col p-4">
      <>
        <div className="flex justify-center">
          <div className="rounded p-0.5 bg-neutral-surface-2 flex flex-wrap justify-center items-center mb-6 ">
            {tabs.map((f, i) => (
              <button
                key={i}
                onClick={() => setActivePool(f)}
                className={`rounded text-sm font-medium min-w-[150px] px-4 py-0.5 capitalize ${
                  activePool?.pool_id === f.pool_id
                    ? 'bg-secondary-main text-neutral-white'
                    : 'text-neutral-body-2'
                }`}
              >
                {f.pool_name}
              </button>
            ))}
          </div>
        </div>
        <Table
          loading={isFetching || isPoolFetching}
          columns={columns}
          data={data?.data}
          expandable={{
            expandedIndexes,
            expandedRowRender,
            onExpand,
          }}
          containerClass="secondary [&>table>thead>tr>th]:capitalize"
        />
        <Pagination
          id="collateral-table-pagination"
          total={data?.total ?? 0}
          page={page}
          onChangePage={p => setPage(p)}
          limit={PER_PAGE}
          limits={LIMITS}
          onChangeLimit={l => setPerPage(l)}
        />
      </>
    </div>
  )
}

export default CollateralL4
