import React, { useEffect, useState } from 'react'

import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import {
  CovenantDetailResponse,
  CovenantListResponse,
} from '@interfaces/manage-monitor-covenant'

import { useSpecialCovenantDetail } from '../../covenant/helper'

interface CovenantL4Props {
  covenant: CovenantDetailResponse
  summary: CovenantListResponse
}

const CovenantVintageL4 = ({ covenant, summary }: CovenantL4Props) => {
  const { tableData, tableColumns, overviewData } = useSpecialCovenantDetail(
    covenant,
    summary
  )

  const triggers = overviewData.map((o: any) => o.mob)
  const table_column: any[] = [
    {
      ...tableColumns[0],
      title: summary.covenant_name,
    },
    ...tableColumns
      .filter((t: any) => triggers.includes(t.title))
      .map((t: any) => ({ ...t, title: `MoB ${t.title}` })),
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [covenant])

  return (
    <Table
      loading={!!isProcessing}
      containerClass="bg-neutral-border-2 max-h-[calc(100vh-100px)] overflow-auto [&>table]:w-auto [&>table>thead]:sticky [&>table>thead]:top-0 [&>table>thead>tr>th]:capitalize [&>table>tfoot>tr>td:nth-child(1)]:text-right [&>table>tfoot>tr>td>*]:font-bold"
      columns={table_column}
      data={tableData}
    />
  )
}

export default CovenantVintageL4
