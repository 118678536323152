import React, { useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { Facility } from '@interfaces/facility'
import {
  SignatureRequest,
  TemplateDialogProps,
} from '@interfaces/manage-signature'
import { Dialog, DialogBody } from '@material-tailwind/react'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { FacilityDetailsService } from '@services/api-manage/facility-details'

import { useAfterEffects } from '../after-effects'
import { CustomFields, SignerInput } from '../components'
import { getCustomData } from '../custom-data'

import { renderTemplate } from './templates'

export const GenericTemplateAction = (
  templateDialogProps: TemplateDialogProps
) => {
  const {
    form,
    user,
    createEmbeddedSignRequest,
    uploadDocument,
    handler,
    dialogOpen,
    docData,
    category,
  } = templateDialogProps
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { facilities = [] } = optionFilters
  const { activeFacilityId = 0 } = appliedFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )
  const [guarantors, setGuarantors] = useState<string>('')
  const [agent, setAgent] = useState<string>('')
  const [borrower, setBorrower] = useState<string>('')
  const [effectiveDate, setEffectiveDate] = useState<Moment>(
    moment.utc(docData?.effective_date)
  )
  const { afterEffect } = useAfterEffects(category)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = form

  const signers = category?.signers ? JSON.parse(category.signers) : undefined

  // Get custom data
  const [isLoadingCustomData, customData] = getCustomData(
    category,
    effectiveDate
  )

  // Get party details data
  const {
    data: partyDetailsData,
    mutate: getPartyDetails,
    isLoading: isLoadingPartyDetails,
  } = useMutation(
    (params: { facility_id: number; facility: string; slug_name: string }) => {
      return FacilityDetailsService.getDealPartyDetailsRequest(params)
    }
  )

  const isLoading = isLoadingPartyDetails || isLoadingCustomData

  const onSubmit = (cd: any) => {
    if (!!signers?.signers && !signers.signers.length) {
      uploadDoc(cd)
    } else {
      createSignableDoc(cd)
    }
  }

  const uploadDoc = async (cd: any) => {
    const effDate = signers?.hideDateInput
      ? moment(cd['effective-date'])
      : effectiveDate
    const formData = new FormData()
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append('effective_date', effDate.format('DD-MM-YYYY'))
    docData.id && formData.append('id', docData.id)
    formData.append('category', category?.category)
    formData.append(
      'title',
      `Form of ${category?.category} - ${effDate.format('DD MMMM yyyy')}`
    )
    formData.append('facility', activeFacility?.facility_name)
    user?.email && formData.append('author_email', `${user.email}`)
    user?.name && formData.append('author_name', `${user.name}`)
    formData.append('form_values', JSON.stringify(cd))
    formData.append(
      'files',
      await pdf(
        renderTemplate(
          category,
          {
            agent,
            borrower,
            guarantors,
            agreementDate: moment
              .utc(activeFacility?.agreement_date)
              .format('YYYY-MM-DD'),
            effectiveDate: effDate.format('YYYY-MM-DD'),
            customData,
            formData: cd,
          },
          templateDialogProps
        )
      ).toBlob()
    )

    await uploadDocument(formData)
    afterEffect(
      moment(
        signers?.hideDateInput ? cd['effective-date'] : effectiveDate
      ).format('YYYY-MM-DD'),
      customData
    )
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  const createSignableDoc = async (cd: any) => {
    const effDate = signers?.hideDateInput
      ? moment(cd['effective-date'])
      : effectiveDate
    const formData = new FormData()
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append('facility', activeFacility?.facility_name)
    formData.append('category', category?.category)
    formData.append('effective_date', effDate.format('DD-MM-YYYY'))
    docData.id && formData.append('id', docData.id)
    formData.append(
      'title',
      `${category?.category} - ${effDate.format('DD MMMM yyyy')}`
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(
          category,
          {
            agent,
            borrower,
            guarantors,
            agreementDate: moment
              .utc(activeFacility?.agreement_date)
              .format('YYYY-MM-DD'),
            effectiveDate: effDate.format('YYYY-MM-DD'),
            customData,
            formData: cd,
          },
          templateDialogProps
        )
      ).toBlob()
    )
    formData.append('subject', category?.category)
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        cd.signers?.map(
          (s: { name: string; email: string; title?: string }, i: number) => ({
            name: s.name,
            order: i,
            emailAddress: s.email,
          })
        )
      )
    )
    formData.append('form_values', JSON.stringify(cd))
    formData.append('tags', 'true')
    createEmbeddedSignRequest(formData as SignatureRequest)
    afterEffect(
      moment(
        signers?.hideDateInput ? cd['effective-date'] : effectiveDate
      ).format('YYYY-MM-DD'),
      customData
    )
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  useEffect(() => {
    if (dialogOpen) {
      !!activeFacility.facility_name &&
        company?.slug_name &&
        getPartyDetails({
          facility_id: activeFacility?.facility_id,
          facility: activeFacility?.facility_name,
          slug_name: activeFacility?.slug_name ?? company?.slug_name,
        })
    }
  }, [dialogOpen, activeFacility])

  useEffect(() => {
    if (partyDetailsData) {
      const guarantorsList = partyDetailsData
        .filter((entry: { type: string }) => entry.type === 'Guarantor')
        .map((entry: { legal_name: any }) => entry.legal_name)
      if (guarantorsList.length) {
        setGuarantors(guarantorsList.join(' and '))
      }
      const borrower = partyDetailsData.filter(
        (entry: { type: string }) => entry.type === 'Borrower'
      )?.[0]?.legal_name
      const agent = partyDetailsData.filter(
        (entry: { type: string }) => entry.type === 'Agent'
      )?.[0]?.legal_name
      setAgent(agent)
      setBorrower(borrower)
      const bankingInfo = JSON.parse(
        partyDetailsData.find(entry => entry.type === 'Banking')
          ?.supplementary ?? '{}'
      )
      setValue('banking_info', bankingInfo)
    }
  }, [partyDetailsData])

  return (
    <Dialog
      open={dialogOpen}
      size={'xl'}
      dismiss={{ enabled: false }}
      handler={handler}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody className="flex flex-row h-[80vh] overflow-hidden relative">
          <XMarkIcon
            className="absolute right-3 w-7 h-7 cursor-pointer"
            onClick={handler}
          />
          {isLoading ? (
            <div className="flex flex-col justify-center items-center w-full">
              <ArrowPathIcon className="w-10 h-10 mr-2 text-primary-main animate-spin" />
            </div>
          ) : (
            <PDFViewer showToolbar={false} className="h-full w-full">
              {renderTemplate(
                category,
                {
                  agent,
                  borrower,
                  guarantors,
                  agreementDate:
                    moment
                      .utc(activeFacility?.agreement_date)
                      .format('YYYY-MM-DD') || '',
                  effectiveDate: effectiveDate.format('YYYY-MM-DD'),
                  customData,
                  formData: getValues(),
                },
                templateDialogProps
              )}
            </PDFViewer>
          )}
          <div className="m-5 w-1/3 overflow-auto">
            <div className="flex flex-col content-between">
              <div className="font-bold text-lg pb-5">
                Fill in below fields to generate document
              </div>
              {!signers?.hideDateInput && (
                <FormInput
                  type="date"
                  label={{ start: 'As of Date' }}
                  value={effectiveDate}
                  onSelected={val => setEffectiveDate(val)}
                  error={errors?.date?.message as string}
                  isDisabled={!!docData?.effective_date}
                />
              )}
              <CustomFields
                category={category}
                form={form}
                customData={customData}
                docData={docData}
              />
              <SignerInput form={form} user={user} signers={signers} />
              {!signers?.hideDisclosure && (
                <FormInput
                  type="textarea"
                  label={{ start: 'Disclosure Notice' }}
                  value={getValues('disclosure')}
                  {...register('disclosure', {
                    disabled: isSubmitting,
                    onChange: (e: any) => {
                      setValue('disclosure', e.target.value, {
                        shouldValidate: true,
                      })
                    },
                  })}
                  error={errors?.disclosure?.message as string}
                />
              )}
              <div className="flex flex-row w-full justify-end my-3">
                <Button
                  disabled={isLoading || !isValid}
                  type="submit"
                  color="primary"
                  className="w-full"
                >
                  {isLoading ? (
                    <ArrowPathIcon className="w-4 h-4 mx-20 text-primary-main animate-spin" />
                  ) : signers && !signers.length ? (
                    'Generate'
                  ) : (
                    `Prepare Signature Request`
                  )}
                </Button>
              </div>
            </div>
          </div>
        </DialogBody>
      </form>
    </Dialog>
  )
}
