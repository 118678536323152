import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { AdvanceRequestResponse } from '@interfaces/manage-monitor-action'

const statuses = {
  pending: 'Pending',
  signed: 'Signed / Not Funded',
  funded: 'Funded',
  nullified: 'Nullified',
}

const StatusLabel = ({ request }: { request: AdvanceRequestResponse }) => {
  const { user } = useAuth0()
  const status = request.status
    ? statuses[request.status as keyof typeof statuses]
    : null
  const signeesJson = request.signature_request?.signees
    ? JSON.parse(request.signature_request.signees)
    : []
  const signeeEmailList = signeesJson.reduce(
    (res: string[], signee: any) =>
      !!signee.signed_at ? res : [...res, signee.signer_email_address],
    []
  )
  return (
    <div className="flex justify-center">
      <span
        className={`px-3 py-1 font-medium rounded-full capitalize text-xs ${
          request.status === 'funded'
            ? 'text-success-main border border-success-main  bg-success-main/10'
            : request.status === 'pending'
            ? 'text-warning-main border border-warning-main bg-warning-surface'
            : request.status === 'signed'
            ? 'text-white font-medium bg-purple'
            : request.status === 'nullified'
            ? 'text-cc-primary-gray-medium border border-neutral-border-3 bg-cc-primary-gray-medium/10'
            : ''
        }`}
      >
        {status?.toLowerCase() === 'pending'
          ? signeeEmailList.includes(user?.email)
            ? 'Awaiting Your Signature'
            : 'Awaiting Other Signature(s)'
          : status}
      </span>
    </div>
  )
}

export default StatusLabel
