import React, { useContext, useEffect, useState } from 'react'

import Typography from '@components/atoms/typography'
import FilterCalculationDate from '@components/filters/filter-calculation-date'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFacility from '@components/filters/filter-facility'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'

import BorrowingBaseL4 from './borrowing-base'
import CollateralL4 from './collateral'
import ExportDialog from './export-dialog'

const MonitorBorrowingBaseL3 = () => {
  const {
    company,
    activeFilters,
    setActiveFilters,
    appliedFilters,
    setAppliedFilters,
    optionFilters,
  } = useContext(AuthContext)

  const { facilities = [], calculationDateIsFetching } = optionFilters

  const { calculationDate } = activeFilters

  const {
    activeFacilityId: appliedActiveFacilityId,
    calculationDate: appliedCalculationDate,
  } = appliedFilters

  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `${appliedActiveFacilityId}_manage_monitor_borrowing-base`
  const can_export_bb = get_access(`${FEATURE}_borrowing-base`, 'export')
  const can_export_collateral = get_access(`${FEATURE}_collateral`, 'export')

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && calculationDateIsFetching === false) {
      if (!appliedCalculationDate) {
        setAppliedFilters(activeFilters)
      } else if (
        JSON.stringify(activeFilters) !== JSON.stringify(appliedFilters)
      ) {
        setActiveFilters(appliedFilters)
      }
      setFilterReady(true)
    }
  }, [calculationDate, calculationDateIsFetching])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    appliedActiveFacilityId === facilities?.[0]?.facility_id &&
    (appliedCalculationDate === appliedFilters.calculationDate ||
      appliedCalculationDate === company?.date_end)

  const tabs =
    facilities.length === 0 || !appliedCalculationDate
      ? []
      : [
          {
            label: 'Borrowing Base',
            component: <BorrowingBaseL4 />,
          },
          { label: 'Collateral', component: <CollateralL4 /> },
        ].filter(t =>
          filter_access(
            `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`
          )
        )

  const activeFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === appliedActiveFacilityId
  )

  return (
    <L3TabsLayout
      title="Borrowing Base"
      filters={{
        left: (
          <FilterCurrency
            hideSelect
            dateAsOfType={filterDateAsOfType.bankData}
          />
        ),
        middle: <FilterFacility />,
        right: <FilterCalculationDate />,
      }}
      disableReset={disableReset}
      tabs={tabs}
      empty={
        !calculationDateIsFetching ? (
          <Typography className="text-center">{`No borrowing base found for ${
            company?.legal_name
          } ${
            activeFacility ? ` - ${activeFacility.facility_name}` : ''
          }`}</Typography>
        ) : (
          <div className="flex justify-center">
            <ArrowPathIcon className="w-6 h-6 text-primary-main animate-spin m-6" />
          </div>
        )
      }
      rightFooterContent={
        appliedCalculationDate && (can_export_bb || can_export_collateral) ? (
          <ExportDialog />
        ) : undefined
      }
    />
  )
}
export default MonitorBorrowingBaseL3
