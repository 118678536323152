import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { isArray } from 'lodash'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth0, User } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import OptionSearch from '@components/atoms/search'
import FilterDateRange from '@components/filters/filter-date-range'
import FilterTypeKey from '@components/filters/filter-type-key'
import FormInput from '@components/form/form-input'
import Stepper from '@components/stepper'
import Step from '@components/stepper/step'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import {
  Alert,
  Avatar,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { AuditService } from '@services/api-manage/audit'

import { fetchUsers } from '../../helpers'

interface GenerateAuditDialogProps {
  open: boolean
  handler: () => void
  auditType: 'Payment' | 'Underwriting'
  refetchHandler: () => void
}

export const GenerateAuditDialog = ({
  open,
  handler,
  auditType,
  refetchHandler,
}: GenerateAuditDialogProps) => {
  const { company, optionFilters, appliedFilters, activeFilters } =
    useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id == activeFacilityId
  )

  const { user } = useAuth0()
  const [searchParams, setSearchParams] = useSearchParams()

  const [activeStep, setActiveStep] = useState<number>(0)
  const [isLastStep, setIsLastStep] = useState<boolean>(false)
  const [isFirstStep, setIsFirstStep] = useState<boolean>(false)
  const [requiredUploads, setRequiredUploads] = useState<any>([])
  const [sampleSize, setSampleSize] = useState<number>(5)
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const { categoryTypeOptions } = optionFilters
  const [error, setError] = useState<string>('')
  const filterChips: string[] = []
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    shouldUnregister: false,
  })

  activeFilters?.categoryTypes?.forEach((act: number) => {
    categoryTypeOptions?.forEach((ct: any) => {
      if (ct.type_key != 'All') {
        ct.type_val.forEach((c: any) => {
          if (c.id == act) {
            filterChips.push(`${ct.type_key} : ${c.type}`)
          }
        })
      }
    })
  })
  const { data: internalUsers } = useQuery(['userListInternal'], () =>
    fetchUsers({ slug_name: company?.slug_name ?? '', is_external: false })
  )

  const { data: externalUsers } = useQuery(['userListExternal'], () =>
    fetchUsers({ slug_name: company?.slug_name ?? '', is_external: true })
  )

  const { mutate: generateAudit, isLoading: isGeneratingAudit } = useMutation(
    () => {
      setError('')
      return AuditService.generateAudit({
        slug_name: company?.slug_name,
        user_id: user?.sub,
        filters: activeFilters.categoryTypes,
        subscribers: selectedUsers.map((su: User) => su.email),
        start_date: moment(activeFilters.dateStart).format('DD-MM-YYYY'),
        end_date: moment(activeFilters.dateEnd).format('DD-MM-YYYY'),
        sample_size: sampleSize,
        audit_type: auditType.toLowerCase(),
        required_uploads: requiredUploads.map((ru: any) => ru ?? ''),
        facility_id: activeFacilityId,
      })
    },
    {
      onSuccess: data => {
        toast.success('Audit generated successfully')
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set(
          auditType == 'Payment' ? 'paId' : 'uaId',
          data.data.audit_id
        )
        setSearchParams(newSearchParams.toString())
        refetchHandler()
        handler()
      },
      onError: (e: AxiosError) => {
        setError(
          (e.response?.data as any).error ??
            'Audit generation Failed. Please try again!'
        )
      },
    }
  )

  const onSelect = (userId: any) => {
    setShowOptions(false)
    if (!!users) {
      const selectedUserIdx = users.findIndex(
        (user: User) => user.user_id == userId
      )
      isArray(selectedUsers) &&
        setSelectedUsers([...selectedUsers, users[selectedUserIdx]])
      users.splice(selectedUserIdx, 1)
    }
  }

  const removeSubscriber = (index: number) => {
    const newUserList = [...selectedUsers]
    const removedSub = newUserList.splice(index, 1)
    setSelectedUsers(newUserList)
    setUsers(prevUsers => [...prevUsers, removedSub[0]])
  }

  useEffect(() => {
    if (isArray(internalUsers) && isArray(externalUsers)) {
      const allUsers = [...internalUsers, ...externalUsers]
      setUsers(allUsers)
    }
  }, [internalUsers, externalUsers])

  return (
    <Dialog
      open={open}
      size="xl"
      className="flex flex-col h-[80%]"
      handler={() => null}
    >
      <DialogHeader>
        Generate {auditType} Audit
        <div onClick={() => handler()} className="absolute right-10">
          <XMarkIcon className="w-7 cursor-pointer" onClick={handler} />
        </div>
      </DialogHeader>

      <DialogBody>
        <form
          className="flex flex-col w-full"
          onSubmit={handleSubmit(() => undefined)}
        >
          <div className="p-10">
            <Stepper
              setActiveStep={(e: number) => {
                setActiveStep(e)
              }}
              setIsLastStep={value => setIsLastStep(value)}
              setIsFirstStep={value => setIsFirstStep(value)}
              activeStep={activeStep}
            >
              <Step
                onClick={() => setActiveStep(0)}
                isActive={true}
                title={'Audit Criteria'}
              >
                <div className="font-bold my-3 text-cc-text-primary">
                  Configure your audit
                </div>
                <div className="flex w-full justify-center">
                  <div
                    className={`grid grid-cols-${
                      auditType == 'Payment' ? '1' : '2'
                    } gap-5 w-full mx-20`}
                  >
                    <div>
                      <FilterDateRange />
                      <FilterTypeKey />
                      <FormInput
                        containerClass="shadow-lg font-bold"
                        label={{
                          start: 'Select Sample Size',
                        }}
                        type="select"
                        options={[5, 10, 25].map((val: number) => {
                          return {
                            title: val.toString(),
                            value: val,
                          }
                        })}
                        value={sampleSize}
                        onSelected={val => setSampleSize(val)}
                      />
                    </div>
                    {auditType == 'Underwriting' && (
                      <div className="flex flex-col">
                        <div className="flex w-full flex-row">
                          <FormInput
                            label={{
                              start: 'Required Uploads*',
                            }}
                            containerClass="shadow-lg -mt-1 w-full"
                            type="text"
                            value={getValues('uploads') ?? ''}
                            {...register('uploads', {
                              onChange: (e: any) => {
                                setValue('uploads', e.target.value, {
                                  shouldValidate: true,
                                })
                              },
                            })}
                            error={errors?.['uploads']?.message as string}
                          />

                          <Button
                            disabled={!getValues('uploads')}
                            color="default"
                            className="h-9 mt-6 relative"
                            onClick={() => {
                              const uploads = [
                                ...requiredUploads,
                                getValues('uploads'),
                              ]
                              setRequiredUploads(uploads)
                              reset()
                            }}
                          >
                            {getValues('uploads') && (
                              <div className="animate-ping absolute -top-1 -right-1 w-4 h-4 rounded-full bg-primary-main opacity-75"></div>
                            )}
                            +
                          </Button>
                        </div>
                        <div className="flex flex-wrap">
                          {requiredUploads.map((ru: User, i: number) => {
                            return (
                              <div
                                key={i}
                                className="flex flex-row bg-primary-surface-1 rounded-full px-2 pr-4 py-2 text-sm font-semibold m-2"
                              >
                                <div className="flex flex-col ml-1">{ru}</div>
                                <div className="flex flex-col ml-1 justify-center">
                                  <XMarkIcon
                                    className="w-5 cursor-pointer hover:text-primary-main"
                                    onClick={() => {
                                      const uploads = [...requiredUploads]
                                      uploads.splice(i, 1)
                                      setRequiredUploads(uploads)
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Step>
              <Step
                onClick={() => setActiveStep(1)}
                isActive={false}
                title={'Subscribers'}
              >
                <div className="flex flex-col">
                  <div className="font-bold mt-3">Select Audit Subscribers</div>
                  <div className="text-sm font-light mb-5 mt-2">
                    Subscribers will receive email notifications regarding audit
                    generation, item rejection, requests for more information
                    and audit finalisation.
                  </div>
                  <div className="w-full z-[9999] relative">
                    {isArray(users) && (
                      <OptionSearch
                        availableOptions={
                          users.map((user: User) => {
                            return {
                              actionIcon: null,
                              label: user.name ?? user.email ?? '',
                              value: user.user_id,
                              disabledHint: '',
                              displayhint: false,
                            }
                          }) ?? [
                            {
                              actionIcon: null,
                              label: 'No Options available',
                              value: '',
                              disabledHint: 'No Options available',
                              displayhint: false,
                            },
                          ]
                        }
                        searchParam={'user'}
                        handleChange={onSelect}
                        showOptions={showOptions}
                      />
                    )}
                  </div>
                  <div className="flex absolute top-72 flex-wrap z-[5000] max-h-[300px] w-[87%]">
                    <div className="flex w-full flex-wrap max-h-[200px] overflow-y-auto">
                      {selectedUsers.map((user: User, idx: number) => {
                        return (
                          <div
                            key={idx}
                            className="flex flex-row bg-primary-surface-1 rounded-full px-2 pr-4 py-2 text-sm font-semibold m-2"
                          >
                            <Avatar
                              src={user.picture}
                              alt="avatar"
                              variant="circular"
                              className="row-span-2 w-8 h-8 self-center"
                            />
                            <div className="flex flex-col ml-1">
                              <div>{user.name}</div>
                              <div className="text-xs font-light">
                                {user.email}
                              </div>
                            </div>
                            <div className="flex flex-col ml-1 justify-center">
                              <XMarkIcon
                                className="w-5 cursor-pointer hover:text-primary-main"
                                onClick={() => removeSubscriber(idx)}
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Step>
              <Step
                onClick={() => setActiveStep(2)}
                isActive={false}
                title={'Confirmation'}
              >
                <div className="font-bold mt-3">Review Audit Details below</div>

                <div className="grid grid-cols-4 gap-1 text-sm">
                  <div className="font-semibold">Audit Type</div>
                  <div className="col-span-3">{auditType} Audit</div>
                  <div className="font-semibold">Date Range</div>
                  <div className="col-span-3">
                    {moment(activeFilters.dateStart).format('DD-MMM-YYYY')} to{' '}
                    {moment(activeFilters.dateEnd).format('DD-MMM-YYYY')}
                  </div>
                  <div className="font-semibold">Sample Size</div>
                  <div className="col-span-3">{sampleSize}</div>
                  <div className="font-semibold">Facility</div>
                  <div className="col-span-3">{activeFacility.facility}</div>
                  <div className="font-semibold">Filters</div>
                  {activeFilters.activeType != 'All' ? (
                    <div className="col-span-3 max-h-[100px] flex w-full flex-wrap overflow-y-auto border rounded-lg">
                      {filterChips.map((chip: any, idx: number) => {
                        return (
                          <div
                            key={idx}
                            className="rounded-full bg-cc-primary-gray-medium p-2 px-5 m-2 text-xs text-white"
                          >
                            {chip}
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div className="flex col-span-3">
                      <div className="rounded-full bg-cc-positive py-1 px-5 text-white font-medium">
                        All
                      </div>
                    </div>
                  )}
                  <div className="font-semibold">Subscribers</div>
                  {selectedUsers.length > 0 ? (
                    <div className="col-span-3 max-h-[100px] flex w-full flex-wrap overflow-y-auto border rounded-lg">
                      {selectedUsers.map((user: User, idx: number) => {
                        return (
                          <div
                            key={idx}
                            className="flex flex-row bg-primary-surface-1 rounded-full px-2 pr-4 py-2 text-sm font-semibold m-2"
                          >
                            <Avatar
                              src={user.picture}
                              alt="avatar"
                              variant="circular"
                              className="row-span-2 w-8 h-8 self-center"
                            />
                            <div className="flex flex-col ml-1">
                              <div>{user.name}</div>
                              <div className="text-xs font-light">
                                {user.email}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div className="col-span-3">None selected</div>
                  )}
                  {auditType == 'Underwriting' && (
                    <div className="font-semibold">Required Uploads</div>
                  )}

                  {auditType == 'Underwriting' && (
                    <div className="col-span-3 max-h-[100px] flex w-full flex-wrap overflow-y-auto border rounded-lg">
                      {requiredUploads.map((ru: User, i: number) => {
                        return (
                          <div
                            key={i}
                            className="flex flex-row bg-primary-surface-1 rounded-full px-2 pr-4 py-2 text-sm font-semibold m-2"
                          >
                            <div className="flex flex-col ml-1">{ru}</div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                  {error != '' && (
                    <div className="col-span-4 flex justify-center mt-5 -mb-0">
                      <Alert className="flex w-[100%] justify-center border border-danger-main text-danger-main rounded-lg text-xs ">
                        {error}
                      </Alert>
                    </div>
                  )}
                </div>
              </Step>
            </Stepper>
          </div>
        </form>
      </DialogBody>
      <DialogFooter className="flex flex-row justify-between px-10">
        <Button
          className="mx-2 px-10"
          onClick={() => setActiveStep(activeStep - 1)}
          disabled={isFirstStep || isGeneratingAudit}
        >
          Back
        </Button>

        {isLastStep ? (
          <Button
            className="mx-2 px-10 bg-cc-primary-gray-medium  text-white hover:bg-cc-primary-orange hover:text-white"
            disabled={isGeneratingAudit}
            onClick={() => generateAudit()}
          >
            {isGeneratingAudit ? (
              <ArrowPathIcon className="animate-spin w-5" />
            ) : (
              'Generate'
            )}
          </Button>
        ) : (
          <Button
            className="mx-2 px-10"
            disabled={
              isLastStep ||
              (auditType == 'Underwriting' && requiredUploads.length < 1)
            }
            onClick={() => setActiveStep(activeStep + 1)}
          >
            Next
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  )
}

export default GenerateAuditDialog
