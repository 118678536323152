import React, { useContext } from 'react'

import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import { CovenantType } from '@interfaces/manage-monitor-covenant'

import { useCovenantList } from '../helper'
import CovenantSummary from '../summary'

const CovenantList = ({
  type,
  facility,
}: {
  type: CovenantType
  facility?: Facility
}) => {
  const { company } = useContext(AuthContext)

  const { data, isLoading } = useCovenantList({ type, facility })
  return (
    <div className="flex flex-col">
      <Typography className="font-semibold text-cc-primary-gray-dark capitalize text-center">
        {`${type} covenants`}
      </Typography>
      <div
        className={`gap-4 mt-6 grid ${
          isLoading || data?.length === 0
            ? 'grid-cols-1'
            : 'lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4'
        }`}
      >
        {isLoading ? (
          <div className="flex justify-center">
            <ArrowPathIcon className="animate-spin text-primary-main w-4" />
          </div>
        ) : (
          <>
            {data?.length === 0 && (
              <Typography className="text-center">
                No {type} covenant found for {company?.legal_name}
              </Typography>
            )}
            {data?.map(d => (
              <CovenantSummary
                key={d.covenant_id}
                covenant={d}
                type={type}
                facility={facility}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default CovenantList
