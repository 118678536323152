import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { formatDownloadURL } from '@helpers/downloadURL-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { CovenantType } from '@interfaces/manage-monitor-covenant'
import { CovenantService } from '@services/api-manage/monitor-covenant'

const getDates = () => {
  const result: Record<'monthly' | 'quarterly', string[]> = {
    monthly: [],
    quarterly: [],
  }
  const beginningOfTime = moment.utc('2023-11-29')
  const today = moment.utc()
  let date = moment(today)

  const addToQuarterly = (d: moment.Moment) => {
    const quarterMonths = [3, 6, 9, 12]
    const month = d.month() + 1
    if (quarterMonths.includes(month)) {
      result.quarterly.push(d.format('YYYY-MM-DD'))
    }
  }

  while (date.isAfter(beginningOfTime)) {
    date = date.endOf('month').startOf('day')
    // add today's date if it's the end of the month
    if (date.isSameOrBefore(today)) {
      result.monthly.push(date.format('YYYY-MM-DD'))
      addToQuarterly(date)
    }
    date = date.subtract(1, 'month')
  }

  return result
}

export const useOrangeBankQuarterlyPaymentDemandCertData = (
  cadence: 'monthly' | 'quarterly',
  getCovenants = false
) => {
  const { appliedFilters, company, optionFilters } = useContext(AuthContext)
  const { facilities } = optionFilters
  const { activeFacilityId } = appliedFilters
  const activeFacility = facilities?.find(
    (facility: Facility) => facility.facility_id === activeFacilityId
  )
  const [data, setData] = useState<[boolean, Record<string, any>]>([true, {}])
  const fileName = `${moment().unix()}_quarter_payment_demand_collateral.xlsx`

  const params = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacility.facility_id,
    type: 'portfolio' as CovenantType,
    id: 4,
  }

  const { data: covenantData, isFetching } = useQuery(
    ['covenantData', params],
    () => CovenantService.getRaw(params),
    {
      ...getStaleMins(),
      enabled: getCovenants,
    }
  )

  useEffect(() => {
    setData([
      isFetching ?? false,
      {
        fileUrl: formatDownloadURL(
          `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacilityId}&tab=document-centre&fileName=${fileName}`,
          78
        ),
        currency: 'XOF',
        effectiveDateOptions: getDates()?.[cadence],
        covenantData,
      },
    ])
  }, [isFetching])

  return data
}
