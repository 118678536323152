import React from 'react'
import moment from 'moment'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'
import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'

import { useCovenantAudit } from '../helper'

interface CovenantAuditProps {
  open?: boolean
  handler: () => void
  list_item: CovenantListResponse
  type: CovenantType
}

const CovenantAudit = ({
  open = false,
  handler,
  list_item,
  type,
}: CovenantAuditProps) => {
  const { data, isLoading } = useCovenantAudit({
    id: list_item.covenant_id,
    type,
    open,
  })

  const first_data = (data ?? [])?.[0] ?? {}
  const columns = Object.keys(first_data)
    .filter(x => first_data[x] !== null)
    .map(x => ({
      field: x,
      title: x.split('_').join(' '),
      align: 'right',
      head: { align: 'center' },
      render: (item: any) => (
        <div className="text-xs">
          {moment(item[x]).isValid() && item[x].toString().includes('Z')
            ? moment.utc(item[x]).format('YYYY-MM-DD')
            : item[x]}
        </div>
      ),
    }))

  return (
    <Dialog open={open} handler={() => handler()} size="lg">
      <DialogHeader className="border-b border-neutral-border-2 flex justify-between items-center">
        <Typography className="text-xl text-neutral-black font-medium">
          {`${list_item.covenant_name} Raw`}
        </Typography>
        <button onClick={() => handler()}>
          <XMarkIcon className="w-6 h-6" />
        </button>
      </DialogHeader>
      <DialogBody className="flex flex-col text-sm text-black max-h-[80vh] overflow-y-auto">
        {isLoading ? (
          <div className="flex justify-center gap-2">
            <ArrowPathIcon className="animate-spin text-primary-main w-4" />
            <Typography className="text-center">Loading</Typography>
          </div>
        ) : (
          <Table
            containerClass="max-h-[60vh] overflow-auto [&>table>thead]:sticky [&>table>thead]:top-0 [&>table>thead>tr>th]:uppercase"
            loading={isLoading}
            columns={columns as any}
            data={data as any}
          />
        )}
      </DialogBody>
    </Dialog>
  )
}

export default CovenantAudit
