import React from 'react'

import Button from '@components/atoms/button'
import { CategoriesResult, TemplateProps } from '@interfaces/manage-reporting'
import { TemplateDialogProps } from '@interfaces/manage-signature'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'

import BuildnowBuyBackNoticeTemplate from './buildnow/buy-back-notice'
import CoignBackUpInterestTemplate from './coign/back-up-interest-report'
import {
  OrangeBankMonthlyComplianceCertTemplate,
  OrangeBankQuarterlyComplianceCertTemplate,
  OrangeBankQuarterlyPaymentDemandTemplate,
} from './orange-bank'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: TemplateProps,
  templateDialogProps: TemplateDialogProps
) => {
  switch (category.subcategory) {
    case 'buildnow-buy-back-notice': {
      return <BuildnowBuyBackNoticeTemplate {...templateProps} />
    }
    case 'coign-backup-interest-report': {
      return <CoignBackUpInterestTemplate {...templateProps} />
    }
    case 'orange-bank-compliance-monthly': {
      return <OrangeBankMonthlyComplianceCertTemplate {...templateProps} />
    }
    case 'orange-bank-compliance-quarterly': {
      return <OrangeBankQuarterlyComplianceCertTemplate {...templateProps} />
    }
    case 'orange-bank-payment-demand': {
      return <OrangeBankQuarterlyPaymentDemandTemplate {...templateProps} />
    }
    default: {
      return (
        <Dialog
          open={templateDialogProps.dialogOpen}
          handler={templateDialogProps.handler}
          className="overflow-auto z-40"
        >
          <DialogHeader>
            <div className="flex justify-between w-full">
              <span>Feature Available Soon!</span>
            </div>
          </DialogHeader>
          <DialogBody divider>
            <div className="flex flex-col gap-6 w-full mt-5">
              <p>This feature will be available soon</p>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              className="rounded-lg flex-1 max-w-[100px]"
              color="primary"
              onClick={templateDialogProps.handler}
            >
              Ok
            </Button>
          </DialogFooter>
        </Dialog>
      )
    }
  }
}
