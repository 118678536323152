import React, { useContext } from 'react'

import FilterCalculationDate from '@components/filters/filter-calculation-date'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFacility from '@components/filters/filter-facility'
import BaseLayout from '@components/layouts/base-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'

import MasterFacilityOverview from './master-facility-overview'
import Overview from './overview'

const ManageOverviewL3 = () => {
  const { appliedFilters, optionFilters } = useContext(AuthContext)

  const { activeFacilityId: appliedFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const appliedFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === appliedFacilityId
  )
  const isMasterFacility = appliedFacility?.is_master_facility

  /**
   * observe reset button disable state criteria
   */
  const disableReset = appliedFacilityId === facilities?.[0]?.facility_id

  return (
    <BaseLayout
      title="Monitor Overview"
      filters={{
        left: (
          <FilterCurrency
            hideSelect
            dateAsOfType={filterDateAsOfType.bankData}
          />
        ),
        middle: <FilterFacility />,
        right: <FilterCalculationDate hideSelect />,
      }}
      disableReset={disableReset}
    >
      {isMasterFacility ? <MasterFacilityOverview /> : <Overview />}
    </BaseLayout>
  )
}

export default ManageOverviewL3
