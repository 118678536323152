import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import { SUPPORT_EMAIL } from '@constants/config'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Facility } from '@interfaces/facility'
import { Alert, Card, CardBody, Tooltip } from '@material-tailwind/react'
import { AuditService } from '@services/api-manage/audit'

import AuditInfo from '../common/audit-info/audit-info'
import GenerateAuditDialog from '../common/dialogs/generate-audit/generate-audit'

const PaymentAudit = () => {
  const { user } = useAuth0()
  const { userMetadata, company, optionFilters, appliedFilters } =
    useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id == activeFacilityId
  )
  const featureEnabled =
    activeFacility?.features.includes('payment-audit') ?? false
  const [isGenerateDialogOpen, setIsGenerateDialogOpen] =
    useState<boolean>(false)

  const filters = {
    slug_name: company?.slug_name,
    type: 'payment',
    user_id: user?.sub,
    facility_id: activeFacilityId,
  }
  //fetch audit summary info
  const {
    error: allAuditDataError,
    data: allAuditData = {},
    isFetching: isLoadingAllAuditData,
    refetch,
  } = useQuery(
    ['allAuditsDataPayment', filters],
    () => AuditService.getAllAudits(filters),
    { enabled: featureEnabled }
  )

  const infoContent = (
    <div className="w-full text-md">
      <div>
        {`A payment audit compares real bank data against specific payments in the
        loan tape. By conducting a payment audit
        against a loan tape, investors can verify that all cash transactions are
        properly recorded and accounted for. This process helps to identify any
        errors or fraudulent activities, such as embezzlement or manipulation of
        loan terms.`}
      </div>
    </div>
  )

  const getAccessString = () => {
    if (user) {
      //full access if super-admin
      if (userMetadata?.isSuperadmin) {
        return 'access,upload,approve/reject,export'
      } else if (
        //custom user do as assigned
        userMetadata?.companies?.[`${company?.slug_name}`].control == 'custom'
      ) {
        const { access } = userMetadata?.companies?.[`${company?.slug_name}`]
        return access[`${activeFacilityId}_manage_audit_payment-audit`]
      } else if (
        //all external review only unless custom or superadmin
        userMetadata?.companies?.[`${company?.slug_name}`].is_external
      ) {
        return 'access,approve/reject,export'
      } else {
        // all else upload only unless custom, external or superadmin (internal admin, staff)
        return 'access,upload,export'
      }
    }
  }

  const isLoading = isLoadingAllAuditData || activeFacility == undefined
  const accessString = getAccessString() ?? ''

  return (
    <Card>
      <CardBody>
        <div className="w-full grid grid-cols-3 ">
          <Tooltip content={infoContent} placement="bottom-start">
            <InformationCircleIcon className="w-8 text-primary-main ml-10 cursor-help" />
          </Tooltip>
          <div className="flex w-full justify-center">
            <Typography className="flex text-md font-medium mb-4 mt-2">
              Payment Audit
            </Typography>
          </div>
          {accessString.includes('upload') && (
            <div className="flex justify-end">
              <Button
                className="mx-2 relative hover:bg-primary-light"
                disabled={!featureEnabled}
                onClick={() => setIsGenerateDialogOpen(true)}
                color="default"
              >
                {allAuditData.data && allAuditData.data.length < 1 && (
                  <div className="animate-ping absolute -top-1 -right-1 w-4 h-4 rounded-full bg-notification opacity-50"></div>
                )}
                Generate Audit
              </Button>
            </div>
          )}
        </div>
        <>
          <hr className="border-neutral-border-1 border-1 mt-3" />
          {isLoading ? (
            <div className="flex items-center w-full h-[200px] justify-center ">
              <ArrowPathIcon className="w-7 h-6 animate-spin" />
            </div>
          ) : allAuditDataError ? (
            <div className="flex items-center w-full justify-center ">
              <Alert className="border border-danger-main mb-6 text-center text-danger-main mt-10">
                {`An error occurred while loading audits. If this error persists, please contact us via ${SUPPORT_EMAIL}`}
              </Alert>
            </div>
          ) : allAuditData.data && allAuditData.data.length > 0 ? (
            <AuditInfo
              allAuditData={allAuditData.data}
              access={accessString}
              auditType={'Payment'}
            />
          ) : (
            featureEnabled && (
              <div className="flex items-center w-full h-[200px] justify-center ">
                No data to display
              </div>
            )
          )}
        </>

        {!isLoading && !featureEnabled && !allAuditData.data && (
          <div className="flex items-center w-full justify-center ">
            <Alert className="border border-primary-main mb-6 text-center text-primary-main mt-10">
              {`Feature not enabled. please contact us via ${SUPPORT_EMAIL} to enable this feature`}
            </Alert>
          </div>
        )}
      </CardBody>
      {isGenerateDialogOpen && (
        <GenerateAuditDialog
          open={isGenerateDialogOpen}
          handler={() => setIsGenerateDialogOpen(!isGenerateDialogOpen)}
          auditType={'Payment'}
          refetchHandler={refetch}
        />
      )}
    </Card>
  )
}
export default PaymentAudit
