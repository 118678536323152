import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useActiveFacility } from '@helpers/active-facility-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { CashflowService } from '@services/api-manage/monitor-cashflow'

export const useFSVNCustomData = (): [boolean, any] => {
  const activeFacility = useActiveFacility()
  const [result, setResult] = useState<[boolean, any]>([true, {}])

  const filters = {
    slug_name: 'fsvn',
    facility: activeFacility?.facility_id,
  }

  const { data, isFetching } = useQuery(
    ['paymentScheduleStatistic', filters],
    () => CashflowService.getDisbursementSummary(filters),
    {
      ...getStaleMins(),
    }
  )

  useEffect(() => {
    !isFetching && setResult([false, data])
  }, [isFetching])

  return result
}
