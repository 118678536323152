import React from 'react'
import moment from 'moment'

import { useAuth0 } from '@auth0/auth0-react'

interface StatusLabelProps {
  statusText: string
  effectiveDate: string
  signees: string
  isComplete: boolean // Needed to know if signing is actually complete when there is more than one signer
}

const StatusLabel = ({
  statusText,
  effectiveDate,
  signees,
  isComplete,
}: StatusLabelProps) => {
  const { user } = useAuth0()
  const renderStatus = () => {
    switch (true) {
      case ['overdue'].includes(statusText): {
        return (
          <span className="px-3 py-1 font-medium text-white rounded-full bg-danger-main text-xs">
            OVERDUE
          </span>
        )
      }

      case ['signature_request_downloadable'].includes(statusText) &&
        !isComplete:
      case [
        'signature_request_sent',
        'awaiting_signature',
        'signature_request_viewed',
        'created',
      ].includes(statusText): {
        const signeesJson = signees ? JSON.parse(signees) : []
        const signeeEmailList = signeesJson.reduce(
          (res: string[], signee: any) =>
            !!signee.signed_at ? res : [...res, signee.signer_email_address],
          []
        )
        return (
          <span className="px-3 py-1 font-medium text-warning-main border border-warning-main rounded-full bg-warning-surface text-xs">
            {signeeEmailList.includes(user?.email)
              ? 'Awaiting Your Signature'
              : 'Awaiting Other Signature(s)'}
          </span>
        )
      }

      case ['due', 'upcoming'].includes(statusText) &&
        moment(effectiveDate) > moment(): {
        return (
          <span className="px-3 py-1 font-medium text-white bg-warning-main rounded-full text-xs">
            Upcoming
          </span>
        )
      }

      case ['due'].includes(statusText): {
        return (
          <span className="px-3 py-1 font-medium text-warning-main border border-secondary-main rounded-full text-xs">
            Due
          </span>
        )
      }

      case ['voided'].includes(statusText): {
        return (
          <span className="px-3 py-1 font-medium text-neutral-body-2 border border-neutral-border-3 bg-neutral-surface-2 rounded-full text-xs">
            Nullified
          </span>
        )
      }

      case ['signed_and_retracted'].includes(statusText): {
        return (
          <span className="px-3 py-1 font-medium text-neutral-body-2 border-neutral-border-3 bg-neutral-surface-2 rounded-full text-xs">
            Signed and Nullified
          </span>
        )
      }

      default: {
        return (
          <span className="px-3 py-1 text-white font-medium rounded-full bg-success-main text-xs ">
            Completed
          </span>
        )
      }
    }
  }

  return <div className="flex justify-center">{renderStatus()}</div>
}

export default StatusLabel
