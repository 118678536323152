import React from 'react'

import {
  CategoriesResult,
  WaterfallTemplateProps,
} from '@interfaces/manage-reporting'

import DefaultWaterfallTemplate from './default/default-waterfall-template'
import FlexpathWaterfallTemplate from './flexpath/flexpath-waterfall-template'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: WaterfallTemplateProps
) => {
  switch (category?.subcategory) {
    case 'flexpath-waterfall': {
      return <FlexpathWaterfallTemplate {...templateProps} />
    }
    case 'default':
    default: {
      return <DefaultWaterfallTemplate {...templateProps} />
    }
  }
}
