import React from 'react'

import { CategoriesResult, TemplateProps } from '@interfaces/manage-reporting'

import CoignComplianceCertTemplate from './coign'
import ComplianceCertTemplate from './default'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: TemplateProps
) => {
  switch (category.subcategory) {
    case 'coign-compliance-cert': {
      return <CoignComplianceCertTemplate {...templateProps} />
    }
    case 'default':
    default: {
      return <ComplianceCertTemplate {...templateProps} />
    }
  }
}
