import React from 'react'
import moment from 'moment'

import { FacilityDetailResponse } from '@interfaces/manage-monitor-action'
import {
  Component,
  WaterfallDetailResponse,
  WaterfallListResponse,
} from '@interfaces/manage-monitor-waterfall'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { styles } from '../report-template-style'

interface TemplateProps {
  agent?: FacilityDetailResponse
  agreementDate: string
  borrower?: FacilityDetailResponse
  guarantors?: FacilityDetailResponse[]
  signer?: {
    name: string
    title: string
    email: string
  }
  disclosure?: string
  fxItems: WaterfallDetailResponse[]
  data: WaterfallDetailResponse[]
  footerData?: WaterfallDetailResponse[]
  date: string
  waterfall: WaterfallListResponse
  facility?: any
  currency: string
}

const DefaultWaterfallTemplate = ({
  agent,
  agreementDate,
  borrower,
  guarantors,
  signer,
  disclosure,
  fxItems,
  data,
  footerData,
  date,
  waterfall,
  facility,
  currency,
}: TemplateProps) => {
  const agentDistributionList = agent?.distribution_list
    ? JSON.parse(agent?.distribution_list)
    : []
  const agentSigner = agentDistributionList?.[0]

  return (
    <Document>
      <Page style={styles.body}>
        {/*
         * =================================================================
         * header
         * =================================================================
         */}
        <Text style={styles.title}>
          Form of Compliance Certificate - {waterfall.waterfall}
        </Text>
        <Text style={styles.text}>{moment().format('Do MMMM YYYY')}</Text>
        <Text style={styles.text}>{agent?.legal_name || '[Agent]'}</Text>

        {/*
         * =================================================================
         * parties
         * =================================================================
         */}
        <Text style={{ ...styles.text, marginTop: 24 }}>
          Reference is made to:
        </Text>
        <Text style={styles.text}>
          Loan and Security Agreement, dated:{' '}
          {agreementDate || '[Date of Agreement]'} (as amended, restated,
          supplemented or otherwise modified from time to time), the “Loan
          Agreement”
        </Text>
        <Text style={styles.text}>Among :</Text>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ ...styles.text, minWidth: 100 }}>the Borrower</Text>
          <Text style={styles.text}>
            : {borrower?.legal_name || '[Borrower]'}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ ...styles.text, minWidth: 100 }}>the Guarantors</Text>
          <Text style={styles.text}>
            :{' '}
            {guarantors?.map(x => x.legal_name).join(' and ') ||
              '[intentionally left blank]'}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ ...styles.text, minWidth: 100 }}>the Lenders</Text>
          <Text style={styles.text}>
            : the financial institutions or entities from time to time defined
            therein
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ ...styles.text, minWidth: 100 }}>the Agent</Text>
          <Text style={styles.text}>: {agent?.legal_name || `[Agent]`}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ ...styles.text, minWidth: 100 }}>
            Certificate as of
          </Text>
          <Text style={styles.text}>: {date}</Text>
        </View>
        <Text style={styles.text}>
          Capitalized terms used but not defined herein have the meanings
          specified in the Loan Agreement.
        </Text>

        {/*
         * =================================================================
         * statements
         * =================================================================
         */}
        <Text style={{ ...styles.text, textIndent: 32, marginTop: 24 }}>
          The undersigned is an officer of the Borrower, knowledgeable of all of
          the Obligors’ financial matters, is authorized to provide this
          certification regarding, and on behalf of, the Borrower, and hereby
          certifies in such capacity, that:
        </Text>
        <Text style={{ ...styles.text, marginLeft: 32 }}>
          In accordance with the terms and conditions of the Loan Agreement, the
          undersigned has made, or has caused to be made under the undersigned’s
          supervision, a review of the Obligor’s compliance for the period
          covered by this Compliance Certificate with all covenants, conditions
          and terms under the Loan Agreement, and all representations and
          warranties contained therein, and (i) except as described in the
          disclosure attached hereto, such review did not disclose, and the
          undersigned has no knowledge of, the existence of any condition or
          event which constitutes an Event of Default that has occurred and is
          continuing as of the date of this Compliance Certificate and (ii)
          attached is disclosure that specifies, in reasonable detail, the
          nature of any such condition or event and any action taken or proposed
          to be taken with respect thereto.
        </Text>

        {/*
         * =================================================================
         * table
         * =================================================================
         */}
        {fxItems.map((x, i) => {
          return (
            <View
              key={i}
              style={{ flexDirection: 'row', marginTop: i === 0 ? 24 : 0 }}
            >
              <Text
                style={{
                  ...styles.tableColumn,
                  ...styles.tableColumnVariant,
                  minWidth: 150,
                }}
              >
                FX Rate (USD:{x?.currency})
              </Text>
              <Text
                style={{
                  ...styles.tableColumn,
                  width: 150,
                  textAlign: 'right',
                }}
              >
                {Intl.NumberFormat(undefined, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                }).format(x?.input_value ?? x?.cascade_calculation ?? 1)}
              </Text>
            </View>
          )
        })}
      </Page>
      <Page style={styles.body}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '101%',
          }}
        >
          <Text
            style={{
              ...styles.text,
              width: '16%',
              textAlign: 'center',
              paddingBottom: 0,
            }}
          >
            Remaining
          </Text>
          <Text
            style={{
              ...styles.text,
              width: '25%',
              textAlign: 'center',
              paddingBottom: 0,
            }}
          >
            Final
          </Text>
        </View>

        {data.map((d, i) => {
          return (
            <View wrap={false} key={i} style={{ marginTop: 12, width: '100%' }}>
              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{
                    ...styles.tableColumn,
                    ...styles.tableColumnVariant,
                    width: '10%',
                    textAlign: 'center',
                  }}
                >
                  {i}
                </Text>
                <Text
                  style={{
                    ...styles.tableColumn,
                    ...styles.tableColumnVariant,
                    width: '90%',
                  }}
                >
                  {d.description}
                </Text>
              </View>
              {d.component?.map((c, j) => {
                const fxCurrent = fxItems.find(x => x.currency === c.currency)
                const fxCurrentDefault =
                  fxCurrent?.input_value ?? fxCurrent?.cascade_calculation ?? 1
                return (
                  <View key={`${i}-${j}`} style={{ flexDirection: 'row' }}>
                    <Text
                      style={{
                        ...styles.tableColumn,
                        width: '50%',
                      }}
                    >
                      {c.item}
                    </Text>
                    <Text
                      style={{
                        ...styles.tableColumn,
                        width: '25%',
                        textAlign: 'right',
                      }}
                    >
                      {d.max && i !== 0
                        ? Intl.NumberFormat(undefined, {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          }).format(
                            d.max -
                              (d.component
                                ?.slice(0, j)
                                .reduce((acc: number, comp: Component) => {
                                  return (
                                    acc +
                                    comp.amount /
                                      (fxItems.find(
                                        fx => fx.currency === comp.currency
                                      )?.input_value || 1)
                                  )
                                }, 0) ?? 0)
                          ) + ' USD'
                        : ''}
                    </Text>
                    <Text
                      style={{
                        ...styles.tableColumn,
                        width: '25%',
                        textAlign: 'right',
                      }}
                    >
                      {Intl.NumberFormat(undefined, {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(c.amount / fxCurrentDefault)}
                      {' USD'}
                    </Text>
                  </View>
                )
              })}
              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{
                    ...styles.tableColumn,
                    ...styles.tableColumnVariant,
                    width: '50%',
                  }}
                >
                  Total
                </Text>
                <Text
                  style={{
                    ...styles.tableColumn,
                    ...styles.tableColumnVariant,
                    width: '50%',
                    textAlign: 'right',
                  }}
                >
                  {Intl.NumberFormat(undefined, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                  }).format(
                    d.value ?? d.input_value ?? d.cascade_calculation ?? 0
                  )}{' '}
                  {currency}
                </Text>
              </View>
            </View>
          )
        })}
        <View
          style={{
            marginTop: 12,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {footerData?.map((d, i) => (
            <View
              key={i}
              style={{
                flexDirection: 'row',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Text style={{ ...styles.text, width: '40%' }}>
                {d.description}
              </Text>
              <Text
                style={{
                  ...styles.text,
                  width: '30%',
                  textAlign: 'right',
                }}
              >
                {Intl.NumberFormat(undefined, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                }).format(
                  d.value ?? d.input_value ?? d.cascade_calculation ?? 0
                )}{' '}
                {currency}
              </Text>
            </View>
          ))}
        </View>

        {/*
         * =================================================================
         * signature
         * =================================================================
         */}
        <View wrap={false} style={{ marginTop: 24 }}>
          <Text style={styles.text}>Sincerely,</Text>
          <Text style={styles.text}>
            {borrower?.legal_name || `[Borrower]`}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ ...styles.text, minWidth: 150 }}>
              Authorized Officer Signature
            </Text>
            <Text style={styles.text}>: </Text>
            <Text style={{ ...styles.text, color: 'white' }}>
              [sig|req|signer1|signature]
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ ...styles.text, minWidth: 150 }}>
              Authorized Officer Name
            </Text>
            <Text style={styles.text}>: </Text>
            <Text style={{ ...styles.text, paddingLeft: 12 }}>
              {signer?.name}
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ ...styles.text, minWidth: 150 }}>
              Authorized Officer Title
            </Text>
            <Text style={styles.text}>: </Text>
            <Text style={{ ...styles.text, color: 'white' }}>
              [text|req|signer1|title]
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ ...styles.text, minWidth: 150 }}>Date Signed</Text>
            <Text style={styles.text}>: </Text>
            <Text style={{ ...styles.text, color: 'white' }}>
              [date|req|signer1|signature_date]
            </Text>
          </View>
        </View>

        {facility?.waterfall_includes_agent && (
          <View wrap={false} style={{ marginTop: 24 }}>
            <Text style={styles.text}>
              {`Accepted by ${agent?.legal_name || `[Agent]`}`}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.text, minWidth: 150 }}>
                Authorized Officer Signature
              </Text>
              <Text style={styles.text}>: </Text>
              <Text style={{ ...styles.text, color: 'white' }}>
                [sig|req|signer2|signature]
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.text, minWidth: 150 }}>
                Authorized Officer Name
              </Text>
              <Text style={styles.text}>: </Text>
              <Text style={{ ...styles.text, paddingLeft: 12 }}>
                {agentSigner?.name}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.text, minWidth: 150 }}>
                Authorized Officer Title
              </Text>
              <Text style={styles.text}>: </Text>
              <Text style={{ ...styles.text, color: 'white' }}>
                [text|req|signer2|title]
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.text, minWidth: 150 }}>Date Signed</Text>
              <Text style={styles.text}>: </Text>
              <Text style={{ ...styles.text, color: 'white' }}>
                [date|req|signer2|signature_date]
              </Text>
            </View>
          </View>
        )}

        {/*
         * =================================================================
         * disclosure
         * =================================================================
         */}
        <View>
          <Text style={styles.disclosure}>Disclosure Notice</Text>
          <Text style={styles.text}>
            {disclosure || '[intentionally left blank] '}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default DefaultWaterfallTemplate
