import React from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { Facility } from '@interfaces/facility'
import {
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'

/* This is the card shown on Monitor Overview */
const CovenantSummary = ({
  covenant,
  type,
  facility,
}: {
  covenant: CovenantListResponse
  type: CovenantType
  facility?: Facility
}) => {
  const { covenant_name, values, triggers, covenant_id } = covenant

  let url = `/manage/monitor/${type}-covenants?tab=${covenant_id}`

  if (facility) {
    const searchParams = new URLSearchParams()
    searchParams.set('pid', facility?.slug_name ?? '')
    searchParams.set('facilityID', String(facility?.facility_id))
    url += `&${searchParams.toString()}`
  }

  // NavLink is for clicking the covenant card
  return (
    <NavLink
      to={url}
      className="flex flex-col border rounded-md border-neutral-border-2 p-3 gap-6 min-h-[280px] hover:shadow-lg
      transition duration-150
      "
    >
      <div className="flex flex-col flex-1">
        <Typography className="font-medium text-center">
          {covenant_name}
        </Typography>
        <Typography className="text-sm text-center">
          {`as of ${moment.utc(covenant.date_as_of).format('MMM-YY')}`}
        </Typography>
      </div>
      <div className="flex flex-1 gap-4">
        {values?.map(({ value, label }, i) => {
          return (
            <div
              className={`flex flex-col flex-1 gap-2 ${
                values.length > 1 ? 'justify-between' : ''
              }`}
              key={i}
            >
              {values.length > 1 && (
                <Typography className="text-xs text-center">{label}</Typography>
              )}
              <Typography className="text-2xl font-semibold text-center">
                {value}
              </Typography>
            </div>
          )
        })}
      </div>
      {(triggers ?? []).length > 0 && (
        <div className="flex flex-1 [&>div]:!mb-0">
          <Table
            containerClass=""
            columns={[
              { title: 'Trigger', field: 'trigger', align: 'center' },
              { title: 'Value', field: 'value', align: 'center' },
              { title: 'Breach', field: 'breach_count', align: 'center' },
              {
                title: 'Current Test',
                field: 'current',
                align: 'center',
                render: (r: any) => {
                  const Icon = r.in_breach ? XCircleIcon : CheckCircleIcon
                  return (
                    <Icon
                      className={`${
                        r.in_breach ? 'text-danger-main' : 'text-success-main'
                      } w-6 h-6`}
                    />
                  )
                },
              },
            ]}
            data={triggers}
          />
        </div>
      )}
    </NavLink>
  )
}

export default CovenantSummary
