import React from 'react'

import {
  BorrowingBaseCertTemplateProps,
  CategoriesResult,
} from '@interfaces/manage-reporting'

import FsvnBorrowingBaseCertTemplate from './fsvn/fsvn-borrowing-base-cert-template'
import BorrowingBaseCertTemplate from './default'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: BorrowingBaseCertTemplateProps
) => {
  switch (category.subcategory) {
    case 'fsvn-alteriq': {
      return <FsvnBorrowingBaseCertTemplate {...templateProps} />
    }
    case 'default':
    default: {
      return <BorrowingBaseCertTemplate {...templateProps} />
    }
  }
}
