import React from 'react'

import { formatCurrency } from '@helpers/number-formatter'
import { AdvanceRequestTemplateProps } from '@interfaces/manage-signature'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../document-centre/templates/components'
import { styles } from '../template-style'

import { AutochekHeader } from './header'
import { autochekStyles } from './style'

export const AutochekLendableTemplate = ({
  borrower,
  requestDate,
  advanceDate,
  formData,
  bankInfo,
}: AdvanceRequestTemplateProps) => {
  return (
    <Document>
      <Page>
        <AutochekHeader />
        <View style={{ ...styles.body, paddingTop: 8 }}>
          <View style={autochekStyles.titleWrapper}>
            <Text style={autochekStyles.title}>Form of Drawdown Notice</Text>
          </View>
          <View
            style={{ ...autochekStyles.textContainer, alignItems: 'center' }}
          >
            <Text style={{ ...autochekStyles.text, marginRight: 37 }}>To:</Text>
            <Text style={autochekStyles.text}>
              Lendable Asset Management LLC
            </Text>
          </View>
          <Text style={autochekStyles.textAddressIndent}>
            C/O Stellar Corporate Services LLC,
          </Text>
          <Text style={autochekStyles.textAddressIndent}>
            3500 South Dupont Highway, Dover, DE 19901
          </Text>
          <Text style={autochekStyles.textAddressIndent}>
            UNITED STATES OF AMERICA (as the Facility Agent)
          </Text>
          <View style={autochekStyles.textContainer}>
            <Text
              style={{
                ...autochekStyles.textBold,
                marginRight: 13,
              }}
            >
              Attention:
            </Text>
            <Text style={autochekStyles.textBold}>
              Chief Operating Officer and Head of Legal{' '}
            </Text>
          </View>
          <Text style={autochekStyles.text}>
            {requestDate || '[Date of Request]'}
          </Text>
          <Text style={autochekStyles.text}>Dear Sir,</Text>
          <Text style={autochekStyles.textBold}>
            {`FACILITY AGREEMENT FOR US$ SENIOR SECURED TERM FACILITIES (FLOATING) DATED 08 MAY 2023 – FA (AUTOCHEK) (THE "AGREEMENT")`}
          </Text>
          <View wrap={false} style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textNumberedNumber}>1</Text>
            <Text style={autochekStyles.textNoMargin}>
              We refer to the Agreement. This is a Drawdown Notice. Terms
              defined in the Agreement have the same meaning in this Drawdown
              Notice.
            </Text>
          </View>
          <View wrap={false} style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textNumberedNumber}>2</Text>
            <Text style={autochekStyles.textNoMargin}>
              We wish to draw down an Advance of{' '}
              {formData?.amount
                ? formatCurrency(formData?.amount)
                : '[US Amount]'}{' '}
              on {advanceDate || '[draw down date]'} under the Facility.
            </Text>
          </View>
          <View wrap={false} style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textNumberedNumber}>3</Text>
            <Text style={autochekStyles.textNoMargin}>
              The funds should be credited to:
            </Text>
          </View>
          <View
            wrap={false}
            style={{ ...styles.tableWrapper, paddingVertical: 8 }}
          >
            <View style={{ ...styles.table, width: '75%' }}>
              <View style={styles.tableBody}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      Bank Name
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      {bankInfo.recipientBankName}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      Bank Address
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      {bankInfo.recipientBankAddress}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      Bank Routing (used for US Wires)
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      {bankInfo.wireRoutingNumber}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      Bank Routing (used for ACH)
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      {bankInfo.achRoutingNumber}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      SWIFT Code for International Wires
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      {bankInfo.recipientSwiftNumber}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      Account / Business Name
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      {bankInfo.recipientName}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      Account #
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={{ ...styles.tableCell, fontSize: 8 }}>
                      {bankInfo.recipientBankAccountNumber}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View wrap={false} style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textNumberedNumber}>4</Text>
            <Text style={autochekStyles.textNoMargin}>
              We represent that all of the matters described in Clause 10
              (Representations) of the Agreement are true, accurate, complete
              and not misleading today. We also warrant that they will continue
              to be true, accurate, complete and not misleading on the proposed
              Drawdown Date.
            </Text>
          </View>
          <View wrap={false} style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textNumberedNumber}>5</Text>
            <Text style={autochekStyles.textNoMargin}>We confirm that:</Text>
          </View>
          <View style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textLettered}>(a)</Text>
            <Text style={autochekStyles.textNoMargin}>
              no Default has occurred and is continuing;
              {
                '                                                                                                                                                                '
              }
            </Text>
          </View>
          <View style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textLettered}>(b)</Text>
            <Text style={autochekStyles.textNoMargin}>
              no Default will result from the making of the Advance;
              {
                '                                                                                                                                                               '
              }
            </Text>
          </View>
          <View style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textLettered}>(c)</Text>
            <Text style={autochekStyles.textNoMargin}>
              no Obligor is otherwise in breach of any of its obligations under
              the Finance Documents;
              {
                '                                                                                                                 '
              }
            </Text>
          </View>
          <View style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textLettered}>(d)</Text>
            <Text style={autochekStyles.textNoMargin}>
              the representations contained in this Drawdown Notice are true,
              complete and accurate in all respects and are not misleading in
              any material matter on the date of this Drawdown Notice and on the
              proposed Drawdown Date; and
            </Text>
          </View>
          <View style={autochekStyles.textContainer}>
            <Text style={autochekStyles.textLettered}>(e)</Text>
            <Text style={autochekStyles.textNoMargin}>
              each Obligor is in compliance with the financial covenants set out
              in Schedule 6 (Financial Covenants) of the Agreement on the date
              of this Drawdown Notice and will be in compliance on the proposed
              Drawdown Date, and accordingly attaches a Compliance Certificate
              as a schedule to this Drawdown Notice.
            </Text>
          </View>
          <View style={{ ...autochekStyles.textContainer, marginBottom: 12 }}>
            <Text style={autochekStyles.textNumberedNumber}>6</Text>
            <Text style={autochekStyles.textNoMargin}>
              This Drawdown Notice is irrevocable.
            </Text>
          </View>
          <View wrap={false}>
            <Text style={autochekStyles.textNoMargin}>Yours faithfully</Text>
            <Text style={{ ...autochekStyles.textBold, margin: 0 }}>
              {borrower || '[Borrower]'}
            </Text>
            <Text style={autochekStyles.signTags}>
              [sig|req|signer1|signature]
            </Text>
            <Text style={{ ...autochekStyles.textBold, margin: 0 }}>
              {formData?.signers?.[0]?.name || '[Signatory]'}
            </Text>
            <Text style={{ ...autochekStyles.textNoMargin, margin: 0 }}>
              Authorized Signatory
            </Text>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}
