export const bankingInputs = [
  { type: 'text', label: 'Intermediary Bank' },
  { type: 'text', label: 'Bank Address' },
  { type: 'text', label: 'SWIFT' },
  { type: 'text', label: 'Recipient Name' },
  { type: 'text', label: 'Recipient Address' },
  { type: 'text', label: 'Recipient Bank Name' },
  { type: 'text', label: 'Recipient Bank Account Number' },
  { type: 'text', label: 'Recipient SWIFT Number' },
  { type: 'text', label: 'Recipient Bank Address' },
  { type: 'text', label: 'Beneficiary Bank Account Number' },
]
