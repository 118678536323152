import React, { useEffect } from 'react'

import FormInput from '@components/form/form-input'

import Signer, { type ISigner } from './signer'

interface Props {
  form: any
  user: any
  signers: {
    hideTitle?: boolean
    useSelect?: boolean
    signers?: ISigner[]
  }
}

export const SignerInput = ({ form, user, signers }: Props) => {
  const { getValues, setValue } = form

  useEffect(() => {
    if (
      signers?.useSelect &&
      signers?.signers?.length &&
      signers?.signers?.every(s => s.email && s.name)
    ) {
      setValue('signer', 0, {
        shouldValidate: true,
      })
      if (signers.signers[0].name) {
        setValue(`signers[0].name`, signers.signers[0].name, {
          shouldValidate: true,
        })
      }
      if (signers.signers[0].email) {
        setValue('signers[0].email', signers.signers[0].email, {
          shouldValidate: true,
        })
      }
      if (signers.signers[0].title) {
        setValue('signers[0].title', signers.signers[0].title, {
          shouldValidate: true,
        })
      }
    }
  }, [])

  /**
   * There are 4 possibliities:
   * if signers is undefined display 1 signer input
   * if useSelect is true and signers has length and all entries have name and email display select input
   * if signers has length and not all entries has name and email display all inputs but disable inputs that have data
   * if signers exists but is empty don't show anything, no signers are required
   */
  return !signers?.signers ? (
    <Signer form={form} user={user} hideTitle={signers?.hideTitle} index={0} />
  ) : signers.useSelect &&
    signers.signers.length &&
    signers.signers.every(s => s.email) ? (
    <FormInput
      type="select"
      value={getValues('signer') || 0}
      onSelected={(val: number) => {
        setValue('signer', val, {
          shouldValidate: true,
        })
        setValue(`signers[0].name`, signers.signers?.[val].name, {
          shouldValidate: true,
        })
        setValue('signers[0].email', signers.signers?.[val].email, {
          shouldValidate: true,
        })
        if (signers.signers?.[val].title) {
          setValue('signers[0].title', signers.signers?.[val].title, {
            shouldValidate: true,
          })
        }
      }}
      options={signers.signers.map(
        (s: { name: string; email: string }, i: number) => ({
          value: i,
          title: s.name,
        })
      )}
    />
  ) : (
    <>
      {signers.signers.map((s, i) => (
        <Signer
          key={`signer-${i}`}
          form={form}
          hideTitle={signers?.hideTitle}
          index={i}
          user={user}
          signer={s}
        />
      ))}
    </>
  )
}
