import React from 'react'

import {
  CashBalanceCertTemplateProps,
  CategoriesResult,
} from '@interfaces/manage-reporting'

import { DefaultCashBalanceCertTemplate } from './default'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: CashBalanceCertTemplateProps
) => {
  switch (category.subcategory) {
    default: {
      return <DefaultCashBalanceCertTemplate {...templateProps} />
    }
  }
}
