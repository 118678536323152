import React from 'react'
import moment from 'moment'

import { TemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../components/footer'
import { styles } from '../../../template_style'

import { styles as localStyles } from './style'

const BuildnowBuyBackNoticeTemplate = ({
  agreementDate,
  effectiveDate,
  formData,
}: TemplateProps) => {
  const formatNumber = (amount: number, decimals = 2) => {
    return Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: decimals,
    }).format(amount ?? 0)
  }
  const formatCurrency = (amount: number) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount ?? 0)
  }

  return (
    <Document>
      <Page style={styles.body}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 12,
          }}
        >
          <Text style={{ ...localStyles.textBold, fontSize: 14, margin: 0 }}>
            APPENDIX A
          </Text>
          <Text style={{ ...localStyles.textBold, fontSize: 14, margin: 0 }}>
            BUY-BACK NOTICE
          </Text>
        </View>
        <Text style={localStyles.textBold}>
          Date: {moment.utc(effectiveDate).format('Do MMMM, YYYY')}
        </Text>
        <Text style={{ ...localStyles.textBold, margin: 0, marginLeft: 10 }}>
          Alpha Capital Management LLC
        </Text>
        <Text style={{ ...localStyles.text, margin: 0, marginLeft: 10 }}>
          Maples Corporate Services Limited,
        </Text>
        <Text style={{ ...localStyles.text, margin: 0, marginLeft: 10 }}>
          PO Box 309, Ugland House, Grand
        </Text>
        <Text style={{ ...localStyles.text, margin: 0, marginLeft: 10 }}>
          Cayman, KY1-1104, Cayman Islands
        </Text>
        <Text style={{ ...localStyles.text, marginVertical: 8 }}>
          Attn: Mr. Komiljon Kuljonov
        </Text>
        <Text
          style={{
            ...localStyles.textBold,
            fontSize: 12,
            marginBottom: 12,
            marginTop: 8,
          }}
        >
          Re: Buy-back Notice
        </Text>
        <Text style={localStyles.text}>Dear Sir/Madam</Text>
        <Text style={localStyles.text}>
          This letter is given pursuant to Clause 3 of the non-dilutive
          investment in callable equity agreement dated{' '}
          <Text style={localStyles.textBold}>
            {moment.utc(agreementDate).format('Do MMMM, YYYY')}
          </Text>{' '}
          (the <Text style={localStyles.textBold}>“Agreement”</Text>). Any
          capitalized term not defined in this letter shall have the meaning
          ascribed to it in the Agreement.
        </Text>
        <Text style={localStyles.text}>
          We are delivering to you a Buy-back Notice pursuant to Clause 3 of the
          Agreement. In accordance with the requirements for a Buy-back Notice,
          we are providing the following information required by Clause 3 of the
          Agreement:
        </Text>
        <View
          style={{ ...localStyles.viewHorizontalFlexIndented, width: '85%' }}
        >
          <Text
            style={{
              ...localStyles.text,
              marginHorizontal: 8,
              marginBottom: 0,
            }}
          >
            1.
          </Text>
          <Text style={{ ...localStyles.text, marginBottom: 0 }}>
            Buy-Back Amount: 10% of STV Subscription Stock{' '}
            {formatNumber(
              formData['no-of-shares-of-stv-subscription-stock'] * 10,
              0
            )}
            ({formatNumber(formData['no-of-shares-of-stv-subscription-stock'])})
            to be purchased
          </Text>
        </View>
        <View style={localStyles.viewHorizontalFlexIndented}>
          <Text
            style={{
              ...localStyles.text,
              marginHorizontal: 8,
              marginVertical: 0,
            }}
          >
            2.
          </Text>
          <View
            style={{ width: '85%', display: 'flex', flexDirection: 'column' }}
          >
            <Text style={{ ...localStyles.text, marginVertical: 0 }}>
              Buy-Back Consideration: USD{' '}
              {formData['no-of-shares-of-stv-subscription-stock'] &&
              formData['buy-back-consideration-multiple']
                ? formatCurrency(
                    formData['no-of-shares-of-stv-subscription-stock'] *
                      0.95 *
                      formData['buy-back-consideration-multiple']
                  )
                : 0}{' '}
              (
              {formatNumber(formData['no-of-shares-of-stv-subscription-stock'])}{' '}
              x 0.95 x{' '}
              {formatNumber(formData['buy-back-consideration-multiple'])}) to be
              paid to STV to the following bank account
            </Text>
            <View style={{ margin: 10, width: '100%' }}>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Text style={{ ...localStyles.text, width: '35%', margin: 0 }}>
                  Beneficiary Name
                </Text>
                <Text style={{ ...localStyles.text, margin: 0 }}>: </Text>
                <Text style={{ ...localStyles.text, width: '60%', margin: 0 }}>
                  {formData.banking_info?.recipientName}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Text style={{ ...localStyles.text, width: '35%', margin: 0 }}>
                  Beneficiary Address
                </Text>
                <Text style={{ ...localStyles.text, margin: 0 }}>: </Text>
                <Text style={{ ...localStyles.text, width: '60%', margin: 0 }}>
                  {formData.banking_info?.recipientAddress}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Text style={{ ...localStyles.text, width: '35%', margin: 0 }}>
                  Bank
                </Text>
                <Text style={{ ...localStyles.text, margin: 0 }}>: </Text>
                <Text style={{ ...localStyles.text, width: '60%', margin: 0 }}>
                  {formData.banking_info?.recipientBankName}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Text style={{ ...localStyles.text, width: '35%', margin: 0 }}>
                  Bank Address
                </Text>
                <Text style={{ ...localStyles.text, margin: 0 }}>: </Text>
                <Text style={{ ...localStyles.text, width: '60%', margin: 0 }}>
                  {formData.banking_info?.recipientBankAddress}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Text style={{ ...localStyles.text, width: '35%', margin: 0 }}>
                  Account Number
                </Text>
                <Text style={{ ...localStyles.text, margin: 0 }}>: </Text>
                <Text style={{ ...localStyles.text, width: '60%', margin: 0 }}>
                  {formData.banking_info?.recipientBankAccountNumber}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Text style={{ ...localStyles.text, width: '35%', margin: 0 }}>
                  ABA
                </Text>
                <Text style={{ ...localStyles.text, margin: 0 }}>: </Text>
                <Text style={{ ...localStyles.text, width: '60%', margin: 0 }}>
                  {formData.banking_info?.aba}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Text style={{ ...localStyles.text, width: '35%', margin: 0 }}>
                  Swift Code
                </Text>
                <Text style={{ ...localStyles.text, margin: 0 }}>: </Text>
                <Text style={{ ...localStyles.text, width: '60%', margin: 0 }}>
                  {formData.banking_info?.recipientSwiftNumber}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <Text style={{ ...localStyles.text, width: '35%', margin: 0 }}>
                  Currency
                </Text>
                <Text style={{ ...localStyles.text, margin: 0 }}>: </Text>
                <Text style={{ ...localStyles.text, width: '60%', margin: 0 }}>
                  USD
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{ ...localStyles.viewHorizontalFlexIndented, width: '85%' }}
        >
          <Text
            style={{
              ...localStyles.text,
              marginHorizontal: 8,
              marginVertical: 0,
            }}
          >
            3.
          </Text>
          <Text style={{ ...localStyles.text, marginVertical: 0 }}>
            Board resolution approving the relevant Buy-Back Option (enclosed as
            Schedule 1).
          </Text>
        </View>
        <View
          style={{ ...localStyles.viewHorizontalFlexIndented, width: '85%' }}
        >
          <Text
            style={{
              ...localStyles.text,
              marginHorizontal: 8,
              marginVertical: 0,
            }}
          >
            4.
          </Text>
          <Text style={{ ...localStyles.text, marginVertical: 0 }}>
            Stockholder resolution approving the relevant Buy-Back Option
            (enclosed as Schedule 2).
          </Text>
        </View>
        <View
          style={{ ...localStyles.viewHorizontalFlexIndented, width: '85%' }}
        >
          <Text
            style={{
              ...localStyles.text,
              marginHorizontal: 8,
              marginVertical: 0,
            }}
          >
            5.
          </Text>
          <Text style={{ ...localStyles.text, marginVertical: 0 }}>
            Warranty: We hereby represent and warrant that all necessary
            approvals and consents have been obtained by the Company to give
            effect to the exercise of the relevant Buy-Back Option by the
            Company. The Company hereby agrees to indemnify and hold STV
            harmless against any claims, proceedings, liabilities or losses
            incurred by STV due to a breach by the Company of the foregoing
            warranty.
          </Text>
        </View>
        <Text style={localStyles.text}>
          This letter is confidential and should be held in confidence by the
          recipient. Please do not hesitate to contact me should you have any
          questions.
        </Text>
        <Text style={localStyles.text}>Yours faithfully</Text>
        <Text style={styles.signTags}>[sig|req|signer1|signature]</Text>
        <Text style={{ ...localStyles.textBold, marginBottom: 0 }}>
          {formData.signers?.[0]?.name}
        </Text>
        <Text style={{ ...localStyles.text, marginVertical: 0 }}>
          {formData.signers?.[0]?.title}
        </Text>
        <Footer />
      </Page>
    </Document>
  )
}

export default BuildnowBuyBackNoticeTemplate
