import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import { SUPPORT_EMAIL } from '@constants/config'
import AuthContext from '@contexts/auth'
import { pdfDownloader } from '@helpers/file-downloader'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  Alert,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'
import { AuditService } from '@services/api-manage/audit'

import { generateReport } from '../../reports/helpers/provider'

interface GenerateReportDialogProps {
  auditDisplayId: string
  auditId: string
  auditType: 'Payment' | 'Underwriting'
  open: boolean
  handler: () => void
}

export const GenerateReportDialog = ({
  auditDisplayId,
  auditId,
  auditType,
  open,
  handler,
}: GenerateReportDialogProps) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const { user } = useAuth0()
  const [url, setUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isRetrievingUsers, setIsRetrievingUsers] = useState<boolean>(true)
  const [auditData, setAuditData] = useState<any>(null)
  const [reportDataError, setReportDataError] = useState<any>(null)
  const filters = {
    slug_name: company?.slug_name,
    id: auditId,
    user_id: user?.sub,
    type: auditType.toLowerCase(),
    facility_id: activeFacilityId,
  }

  const getUserName = async (userId: string) => {
    const userName = await UserService.getUserById(userId)
    return userName
  }
  //fetch audit summary info
  const { isFetching: isLoadingReportData } = useQuery(
    ['reportData', filters],
    async () => {
      try {
        setIsRetrievingUsers(true)

        const reportDataRes = await AuditService.getReportData(filters)
        const allPromises: Promise<any>[] = []

        const formatDateFields = (data: any, fields: string[]) => {
          fields.forEach(field => {
            if (data[field]) {
              data[field] = moment(data[field]).format('YYYY-MM-DD')
            }
          })
        }

        const setUserName = async (
          userId: string,
          targetObj: any,
          targetField: string
        ) => {
          const user = await getUserName(userId)
          targetObj[targetField] = user?.name
        }

        const mapUserIdsToNames = async (userIds: string[]): Promise<any> => {
          return Promise.all(
            userIds.map(async userId => {
              const user = await getUserName(userId)
              return user?.name
            })
          )
        }

        formatDateFields(reportDataRes.audit_summary, [
          'audit_finalised_on',
          'report_generated_on',
          'audit_commenced_on',
        ])

        if (reportDataRes.audit_summary.audit_finalised_by) {
          const auditorPromise = setUserName(
            reportDataRes.audit_summary.audit_finalised_by,
            reportDataRes.audit_summary,
            'audit_finalised_by'
          )
          allPromises.push(auditorPromise)
        }

        const itemPromises = reportDataRes.item_data.map(async (item: any) => {
          const [uploaders, reviewers] = await Promise.all([
            mapUserIdsToNames(item.info.evidence_uploaded_by),
            mapUserIdsToNames(item.info.reviewed_by),
          ])

          item.info.evidence_uploaded_by = uploaders.join(', ')
          item.info.reviewed_by = reviewers.join(', ')

          return item
        })

        allPromises.push(...itemPromises)

        await Promise.all(allPromises)

        setIsRetrievingUsers(false)
        setAuditData(reportDataRes)
        return reportDataRes
      } catch (error) {
        setIsRetrievingUsers(false)
        setReportDataError(error)
        return error
      }
    }
  )

  useEffect(() => {
    if (!isRetrievingUsers && !!auditData) {
      generateReport(auditData, auditType, company?.slug_name, activeFacilityId)
        .then(url => {
          setIsLoading(false)
          setUrl(url)
        })
        .catch(err => {
          setIsLoading(false)
          setReportDataError(err.message)
        })
    }
  }, [auditData, isRetrievingUsers])

  const isProcessing = isLoading || isLoadingReportData || isRetrievingUsers

  return (
    <Dialog open={open} handler={handler} size="xl" className="h-[90%]">
      <DialogHeader>
        Generate Report for {auditDisplayId}
        <div onClick={() => handler()} className="absolute right-10">
          <XMarkIcon className="w-7 cursor-pointer" onClick={handler} />
        </div>
      </DialogHeader>
      <DialogBody className="flex flex-col h-[80%]">
        <div className="flex h-full w-full">
          {!!reportDataError ? (
            <div className="w-full h-full flex justify-center items-center">
              <Alert className="flex text-center border border-rounded-lg border-danger-main text-danger-main  p-4">
                An Error Occured while generating report. If this error
                persists, please contact us via {SUPPORT_EMAIL}
              </Alert>
            </div>
          ) : isProcessing ? (
            <div className="flex flex-col w-full h-full justify-center items-center">
              <ArrowPathIcon className="animate-spin w-7" />
              <div className="text-xs my-2">Generating report...</div>
            </div>
          ) : (
            <iframe src={url} className="h-full w-full" title="pdf viewer" />
          )}
        </div>
      </DialogBody>
      <DialogFooter className="w-full flex justify-center">
        <Button
          disabled={isLoading || reportDataError}
          color="primary"
          onClick={() =>
            pdfDownloader(
              url,
              `${
                company?.legal_name
              } - ${auditDisplayId} - Audit Report ${moment().format(
                'YYYY-MM-DD'
              )}`
            )
          }
        >
          Download
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default GenerateReportDialog
