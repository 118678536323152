import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

const MasterFacilityOverview = () => {
  const {
    company,
    activeFilters,
    appliedFilters,
    setAppliedFilters,
    optionFilters,
  } = useContext(AuthContext)
  const { activeFacilityId } = activeFilters
  const { activeFacilityId: appliedFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const appliedFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === appliedFacilityId
  )
  const isMasterFacility =
    typeof appliedFacility?.is_master_facility === 'boolean' &&
    appliedFacility?.is_master_facility === true

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && activeFacilityId !== undefined) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  const { data, isFetching } = useQuery(
    ['master-facility-overview', [company?.slug_name, appliedFacilityId]],
    () =>
      BorrowingBaseService.getMasterFacilityOverviewData(
        company?.slug_name ?? '',
        appliedFacilityId
      ),
    { ...getStaleMins(), enabled: isMasterFacility }
  )

  return (
    <Table
      loading={isFetching}
      columns={[
        { title: 'Facility', field: 'facility', align: 'center' },
        {
          title: 'Borrowing Base as of',
          field: 'borrowingBaseAsOf',
          align: 'center',
          render: i => moment.utc(i.borrowingBaseAsOf).format('YYYY-MM-DD'),
        },
        {
          title: 'Borrowing Base [Surplus / Deficiency]',
          field: 'borrowingBaseSurplus',
          align: 'right',
          render: i => {
            const isNegative = i.borrowingBaseSurplus < 0
            const formattedValue = Intl.NumberFormat(undefined, {
              style: 'decimal',
              maximumFractionDigits: 2,
            }).format(i.borrowingBaseSurplus)
            return isNegative
              ? `(${formattedValue.replace('-', '')})`
              : formattedValue
          },
        },
        {
          title: 'Portfolio Covenant Breaches',
          field: 'portfolioCovenantBreaches',
          align: 'right',
        },
        {
          title: 'Financial Covenant Breaches',
          field: 'financialCovenantBreaches',
          align: 'right',
        },
        {
          title: 'Overdue Reporting',
          field: 'overdueReports',
          align: 'right',
        },
      ]}
      data={data}
    />
  )
}

export default MasterFacilityOverview
