import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useMutation } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import Typography from '@components/atoms/typography'
import FormInput from '@components/form/form-input'
import { SUPPORT_EMAIL } from '@constants/config'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Alert } from '@material-tailwind/react'
import { AuditService } from '@services/api-manage/audit'

import { ReviewDialog } from '../dialogs/review/review-dialog'

import { SummaryTableWithActions } from './item-summary'

interface InfoSummaryProps {
  allAuditData: any
  access: string
  auditType: 'Payment' | 'Underwriting'
}

export const AuditInfo = ({
  allAuditData,
  access,
  auditType,
}: InfoSummaryProps) => {
  const { user } = useAuth0()
  const { company, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const [auditId, setAuditId] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState<boolean>(false)

  const itemIdParam = searchParams.get('itemId')
  const auditIdParam = searchParams.get(
    auditType == 'Payment' ? 'paId' : 'uaId'
  )

  const filters = {
    slug_name: company?.slug_name,
    type: auditType.toLowerCase(),
    user_id: user?.sub,
    facility_id: activeFacilityId,
  }

  const {
    data: auditData = {},
    mutate: getAllAuditData,
    isLoading: isLoadingAuditData,
    error: auditDataError,
  } = useMutation(() =>
    AuditService.getAuditDataById({ ...filters, id: auditId })
  )

  const closeReviewDialog = () => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete('itemId')
    setSearchParams(newSearchParams.toString())
    setIsReviewDialogOpen(false)
    getAllAuditData()
  }

  //sets the url parameters when auditId is changed
  useEffect(() => {
    if (auditIdParam != auditId) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(auditType != 'Payment' ? 'paId' : 'uaId')
      newSearchParams.set(auditType == 'Payment' ? 'paId' : 'uaId', auditId)
      setSearchParams(newSearchParams.toString())
    }
    getAllAuditData()
  }, [auditId])

  //resets the url parameters when active facilty is changed
  useEffect(() => {
    return () => {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(auditType == 'Payment' ? 'paId' : 'uaId')
      newSearchParams.delete('itemId')
      setSearchParams(newSearchParams.toString())
    }
  }, [activeFacilityId])

  useEffect(() => {
    auditId == '' && allAuditData[0].uuid && setAuditId(allAuditData[0].uuid)
  }, [])

  useEffect(() => {
    !!auditIdParam && setAuditId(auditIdParam)
  }, [auditIdParam])

  useEffect(() => {
    if (itemIdParam) {
      setIsReviewDialogOpen(true)
    }
  }, [itemIdParam])

  return (
    <div className="flex flex-col min-h-[500px]">
      <div className="w-full grid grid-cols-2 px-5 mt-5 gap-4">
        <div className="flex grid grid-cols-3 gap-4 my-1 items-center">
          <Typography className="pb-2 text-sm font-medium my-1 text-left mr-2">
            Audit ID :
          </Typography>
          <div className="col-span-2 mr-5">
            <FormInput
              type="select"
              value={auditId}
              onSelected={(val: string) => setAuditId(val)}
              options={allAuditData.map((item: any) => {
                return {
                  value: item.uuid,
                  title: item.display_id,
                }
              })}
            />
          </div>
        </div>
        {!isLoadingAuditData && auditData.summary && (
          <>
            <div className="flex grid grid-cols-3 gap-4 items-center">
              <Typography className="text-sm font-medium my-1 text-left">
                Audit Status :{' '}
              </Typography>
              <Typography className="text-sm font-medium my-1 flex content-start col-span-2">
                {auditData.summary.audit_status_control.toUpperCase() ==
                  'FINALISATION REQUIRED' &&
                auditData.summary.audit_status.toUpperCase() == 'FINALISED'
                  ? 'FINALISED'
                  : auditData.summary.audit_status_control.toUpperCase()}
              </Typography>
            </div>
            <div className="flex grid grid-cols-3 gap-4">
              <Typography className="text-sm font-medium my-1 justify-start">
                Date created :{' '}
              </Typography>
              <Typography className="text-sm font-medium my-1 justify-start">
                {moment(auditData.summary.audit_date, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD'
                )}
              </Typography>
            </div>
            <div className="flex grid grid-cols-3 gap-4">
              <Typography className="text-sm font-medium my-1 justify-start">
                Created by :{' '}
              </Typography>
              <Typography className="text-sm font-medium my-1 justify-start">
                {auditData.summary?.generated_by_user}
              </Typography>
            </div>
          </>
        )}
      </div>
      {isLoadingAuditData ? (
        <div className="flex items-center w-full h-[200px] justify-center ">
          <ArrowPathIcon className="w-7 h-6 animate-spin" />
        </div>
      ) : auditDataError ? (
        <div className="flex items-center w-full justify-center ">
          <Alert className="border border-danger-main mb-6 text-center text-danger-main mt-10">
            {`An error occurred while loading audit information. If this error persists, please contact us via ${SUPPORT_EMAIL}`}
          </Alert>
        </div>
      ) : (
        auditData.data && (
          <SummaryTableWithActions
            auditData={auditData.data}
            refetch={getAllAuditData}
            access={access}
            auditSummary={auditData.summary}
            auditType={auditType}
          />
        )
      )}
      {!!auditData.data && auditData.data.length > 0 && isReviewDialogOpen && (
        <ReviewDialog
          open={isReviewDialogOpen}
          handler={() => closeReviewDialog()}
          auditSummary={auditData.summary}
          dataArray={auditData.data}
          artefact={auditType == 'Payment' ? 'Payment' : 'Loan'}
          showCols={
            auditType == 'Payment' && company?.slug_name == 'mfs'
              ? [
                  'id',
                  'date',
                  'balance',
                  'account_number',
                  'currency',
                  'provider_name',
                  'audit_status',
                ]
              : auditType == 'Payment'
              ? [
                  'payment_id',
                  'payment_date',
                  'payment_total',
                  'loan_id',
                  'audit_status',
                ]
              : [
                  'loan_id',
                  'disbursed_date',
                  'amount',
                  'audit_status',
                  'credit_limit',
                ]
          }
          access={access}
          auditType={auditType}
        />
      )}
    </div>
  )
}
export default AuditInfo
