import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getFromLS, saveToLS } from '@helpers/local-storage'
import { getStaleMins } from '@helpers/stale-timer'
import {
  DashboardResponse,
  DashboardTrigger,
  SeriesConfig,
} from '@interfaces/manage-monitor-dashboard'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { DashboardService } from '@services/api-manage/monitor-dashboard'

import Overview from '../overview/overview'

import MonitorDashboardTabL4 from './tab'

export const useDashboard = (activeFacilityId: string) => {
  const { company, optionFilters } = useContext(AuthContext)

  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === Number(activeFacilityId)
  )
  const isMasterFacility = activeFacility?.is_master_facility

  const filters = {
    slug_name: company?.slug_name ?? '',
    facility: activeFacilityId,
  }

  const {
    data: covenantData,
    isFetching: isFetchingCovenants,
    error,
  } = useQuery(
    ['covenant-list', filters],
    () => DashboardService.getDashboards(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacilityId && !!company?.slug_name,
    }
  )

  const { data: childFacilitiesData, isFetching: isFetchingChildFacilities } =
    useQuery(
      ['master-child-facilities', [filters.slug_name, filters.facility]],
      () =>
        DashboardService.getMasterFacilityOverviewData(
          filters.slug_name,
          Number(filters.facility)
        ),
      { ...getStaleMins(), enabled: !!isMasterFacility }
    )

  if (!isMasterFacility) {
    const tabs = (covenantData ?? [])
      .reduce((p: any[], c: DashboardResponse) => {
        if (p.find(x => x.label === c.tab)) {
          return p
        }
        return [
          ...p,
          {
            label: c.tab,
            component: (
              <MonitorDashboardTabL4
                items={(covenantData ?? []).filter(x => x.tab === c.tab)}
              />
            ),
          },
        ]
      }, [])
      .sort((a, b) => (a.label < b.label ? -1 : 1))

    return {
      data: covenantData,
      isFetching: isFetchingCovenants,
      tabs,
      activeFacilityId,
      error,
    }
  } else {
    const tabs: any = (childFacilitiesData ?? []).map(f => {
      return {
        label: f.facility_name,
        component: <Overview facility={f} />,
      }
    })
    return {
      data: childFacilitiesData,
      isFetching: isFetchingChildFacilities,
      tabs,
      activeFacilityId,
      error,
    }
  }
}

export const useDashboardData = (dashboardData: DashboardResponse) => {
  const { appliedFilters } = useContext(AuthContext)
  const { calculationDate } = appliedFilters

  const unit = dashboardData.data?.[0]?.unit
  const dateField = dashboardData.calculation_date_filter_field ?? ''
  let maxVal = 5
  let minVal = 0

  const updateMinMaxVal = (value: number) => {
    if (value > maxVal) {
      maxVal = value + 10
    }

    if (value < minVal) {
      minVal = value - 10
    }
  }

  const filterDataByDate = (data: any[]) =>
    data.filter(d =>
      moment
        .utc(d.data[dateField] ?? d.data.calculation_date)
        .isSame(moment(calculationDate), 'day')
    )

  const formatData = (data: any) =>
    data.map((d: any) => {
      const formattedObject: any = {
        x:
          dashboardData.x_axis_type === 'CategoryAxis'
            ? d[dashboardData.x_axis_field]
            : moment.utc(d[dashboardData.x_axis_field]).valueOf(),
        [dashboardData.y_axis_field]: d[dashboardData.y_axis_field],
      }
      updateMinMaxVal(d[dashboardData.y_axis_field])

      if (dashboardData.triggers && dashboardData.triggers.length > 0) {
        dashboardData.triggers.forEach(
          (trigger: { field: string; label: string }) => {
            updateMinMaxVal(d[trigger.field])
            return (formattedObject[trigger.field] = d[trigger.field])
          }
        )
      }

      return formattedObject
    })

  const reduceStackedData = (data: any[]) =>
    data.reduce((acc, curr) => {
      curr.data.data.forEach((dt: { [x: string]: number }) =>
        updateMinMaxVal(dt[dashboardData.y_axis_field])
      )

      const xValue =
        dashboardData.x_axis_type === 'CategoryAxis'
          ? curr.data[dashboardData.x_axis_field]
          : moment.utc(curr.data[dashboardData.x_axis_field]).valueOf()
      const existingIndex = acc.findIndex((item: any) => item.x === xValue)

      if (existingIndex >= 0) {
        acc[existingIndex][curr.data[dashboardData.series_field ?? '']] =
          curr.data[dashboardData.y_axis_field]
      } else {
        const newItem: any = { x: xValue }
        curr.data.data?.forEach((item: any) => {
          newItem[item[dashboardData.series_field ?? '']] =
            item[dashboardData.y_axis_field]
        })
        acc.push(newItem)
      }

      return acc
    }, [])

  const getType = (dashboardData: DashboardResponse, field: string) => {
    if (
      dashboardData.display === 'stacked_area' ||
      dashboardData.display === 'total_stacked_area' ||
      dashboardData.display === 'line_graph'
    ) {
      return 'SmoothedXLineSeries'
    } else if (
      dashboardData.display === 'total_stacked_graph' ||
      dashboardData.display === 'stacked_graph'
    ) {
      return 'ColumnSeries'
    } else {
      const series = (dashboardData.series_config ?? []).find(
        (series: any) => series.series_key === field
      )
      if (!series) {
        return 'ColumnSeries'
      }
      switch (series.series_type) {
        case 'column':
          return 'ColumnSeries'
        case 'line':
          return 'SmoothedXLineSeries'
        default:
          return 'UnknownSeriesType'
      }
    }
  }

  const getLabels = (field: string) => {
    if (!Array.isArray(dashboardData.series_config)) {
      return field
    }
    const series = (dashboardData.series_config ?? []).find(
      (series: SeriesConfig) => series.series_key === field
    )
    return series?.label ?? field
  }

  const getFilteredTableData = (data: any[]) => {
    const dateFilteredData = filterDataByDate(data)[0]
    const unpreppedTableData = dateFilteredData?.data.data

    if (!Array.isArray(unpreppedTableData)) {
      return []
    }
    return unpreppedTableData.map((d: any) => ({
      [dashboardData.x_axis_field]: d[dashboardData.x_axis_field],
      ...d.data,
    }))
  }

  const getSeriesConfig = (field: string) => {
    if (!Array.isArray(dashboardData.series_config)) {
      return null
    }
    return (dashboardData.series_config ?? []).find(
      (series: SeriesConfig) => series.series_key === field
    )
  }

  const getTooltipLabelFormatting = (field = '') => {
    const seriesConfig = getSeriesConfig(field)
    return `${
      seriesConfig?.tooltip_format ?? dashboardData.y_axis_format ?? '#.00a'
    } ${unit ?? ''}`
  }

  //chart related config
  const filteredData =
    calculationDate &&
    dateField &&
    !['line_graph'].includes(dashboardData.display)
      ? filterDataByDate(dashboardData.data ?? [])
      : dashboardData.data

  const chartData = [
    'stacked_area',
    'total_stacked_area',
    'total_stacked_graph',
    'stacked_graph',
    'combo_series',
    'line_graph',
  ].includes(dashboardData.display)
    ? reduceStackedData(dashboardData.data ?? [])
    : ['donut_chart'].includes(dashboardData.display)
    ? filteredData?.map((d: any) => d.data.data).flat()
    : formatData(filteredData?.flatMap(d => d.data.data ?? []) ?? [])

  const filteredChartData =
    dashboardData.x_axis_type === 'DateAxis' && calculationDate && dateField
      ? chartData.filter((cd: any) => {
          const cdDate = moment.utc(cd.x)
          const endDate = moment.utc(calculationDate)
          let startDate = endDate
          /*Giving data control over the chart to display the interval of data being displayed
           with relevance to selected calculation date
           exp: 7 day charts, 14 day charts, 30 day charts etc
           */
          if (dashboardData.limit_historical_value) {
            startDate = endDate
              .clone()
              .subtract(
                dashboardData.limit_historical_value,
                dashboardData.limit_historical_interval ?? 'days'
              )
          }

          if (dashboardData.limit_future_value) {
            endDate.add(
              dashboardData.limit_future_value,
              dashboardData.limit_future_interval ?? 'days'
            )
          }

          return dashboardData.limit_historical_value ||
            dashboardData.limit_future_value
            ? cdDate.isBetween(startDate, endDate, 'days', '(]')
            : cdDate.isSameOrBefore(endDate, 'day')
        })
      : chartData

  const triggerSeries = (dashboardData.triggers ?? []).map(
    (t: DashboardTrigger) => ({
      label: t.label ?? '',
      tooltipValueFormat: `${dashboardData.y_axis_format ?? '#.00a'} ${
        unit ?? ''
      }`,
      type: 'SmoothedXLineSeries',
      color: '#' + Math.floor(Math.random() * 16777215).toString(16),
      hasBullet: true,
      bulletType: 'line',
      field: t.field ?? '',
    })
  )
  //obtain data for the series
  const uniqueSeries: Set<string> = new Set<string>()
  dashboardData.data?.forEach(d => {
    d.data.data?.forEach((element: any) => {
      uniqueSeries.add(element[dashboardData.series_field ?? ''])
    })
  })
  const seriesData = Array.from(uniqueSeries).map(el => {
    return {
      [dashboardData.series_field ?? '']: el,
    }
  })

  const unsortedchartSeries = [
    'stacked_area',
    'total_stacked_area',
    'total_stacked_graph',
    'stacked_graph',
    'combo_series',
    'line_graph',
  ].includes(dashboardData.display)
    ? seriesData.reduce((acc: any[], curr: any) => {
        if (
          !acc.some(x => x.label === curr[dashboardData.series_field ?? ''])
        ) {
          const seriesType = getType(
            dashboardData,
            curr[dashboardData.series_field ?? '']
          )

          acc.push({
            label: getLabels(curr[dashboardData.series_field ?? '']),
            tooltipValueFormat: getTooltipLabelFormatting(
              curr[dashboardData.series_field ?? '']
            ),

            type: seriesType,
            oppositeYAxis:
              dashboardData.display == 'combo_series' &&
              seriesType == 'SmoothedXLineSeries',
            isStack: ![
              'combo_series',
              'line_graph',
              'multiline_graph',
            ].includes(dashboardData.display),
            isTotal: dashboardData.display?.includes('total'),
            field: curr[dashboardData.series_field ?? ''],
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            fill: ['stacked_area', 'total_stacked_area'].includes(
              dashboardData.display
            ),
            hasBullet: false,
          })
        }
        return acc
      }, [])
    : [
        {
          label: dashboardData.y_axis_label ?? '',
          tooltipValueFormat: `${dashboardData.y_axis_format ?? '#.00a'} ${
            unit ?? ''
          }`,
          type:
            dashboardData.display === 'line_graph'
              ? 'SmoothedXLineSeries'
              : 'ColumnSeries',
          field: dashboardData.y_axis_field,
        },
        ...triggerSeries,
      ]

  //ensures line series are plotted over any other series
  const chartSeries = unsortedchartSeries.sort(
    (a: { type: string }, b: { type: string }) =>
      a.type === 'SmoothedXLineSeries'
        ? 1
        : b.type === 'SmoothedXLineSeries'
        ? -1
        : 0
  )

  //forces an xAxisType
  const xAxisType: 'DateAxis' | 'CategoryAxis' =
    dashboardData.x_axis_type === 'DateAxis' ? 'DateAxis' : 'CategoryAxis'

  const secondaryYAxisConfig = {
    secondaryYLabel: dashboardData.y_axis_2_label ?? '',
    secondaryYFormat: dashboardData.y_axis_2_format ?? '#.00a',
  }

  //table related config
  const columnNames = (dashboardData.y_axis_label ?? '').split(',')

  const tableColumns = (dashboardData.y_axis_field ?? '')
    .split(',')
    .map((col: string, i: number) => ({
      title: columnNames[i] ?? col, //falls back to column name(ugly) if label not given so header isnt empty
      field: col.trim(),
      align: 'left',
    }))

  const tableData = dateField
    ? getFilteredTableData(dashboardData?.data ?? [])
    : (dashboardData.data ?? []).map((d: any) => ({
        [dashboardData.x_axis_field]: d[dashboardData.x_axis_field],
        ...d.data,
      }))

  return {
    unit,
    dashboardData,
    chartData,
    allChartData: filteredData?.map((d: any) => d.data)[0],
    chartSeries,
    xAxisType,
    filteredChartData,
    ySetting: { minVal, maxVal },
    xSetting: dashboardData.x_axis_setting,
    xAxisLabel: dashboardData.x_axis_label,
    yAxisLabel: dashboardData.y_axis_label,
    yAxisFormat: dashboardData.y_axis_format ?? '#.00a',
    title: dashboardData.title,
    dashboardId: dashboardData.dashboard_id,
    displayName: dashboardData.external_name,
    dateFilterField: dashboardData.calculation_date_filter_field,
    tableColumns,
    tableData,
    numberTableColumns: dashboardData?.data[0].cols,
    numberTableData: dashboardData?.data[0].data,
    centerField: dashboardData.center_field,
    centerLabel: dashboardData.center_label,
    categoryField: dashboardData.category_field,
    valueField: dashboardData.value_field,
    secondaryYAxisConfig,
  }
}

export const getInitDashboardLayout = () => {
  const ls = getFromLS(DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY)
  return ls
}

export const saveDashboardLayoutToLs = (key: string, value: any) => {
  const ls = getFromLS(DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY) || {}
  const updatedLs = { ...ls, [key]: value }
  saveToLS(DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY, updatedLs)
}
