import React from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { getStaleMins } from '@helpers/stale-timer'
import { UserService } from '@services/api-admin/settings-user'

interface ActivityBoxProps {
  data: any
}

export const ActivityBox = ({ data }: ActivityBoxProps) => {
  const { data: userData, isFetching } = useQuery(
    ['commentor_data', [data.id, data.user_info]],
    () => UserService.getUserById(data.user_info),
    { ...getStaleMins() }
  )

  return (
    <div>
      {!isFetching &&
        ['FURTHER', 'UPLOAD', 'APPROVED', 'REJECTED', 'DELETED'].includes(
          data.activity_type
        ) && (
          <div className="flex flex-row w-full items-center py-3 border-b-2 border-gray-200 text-sm">
            {data.activity_type == 'FURTHER' && (
              <div className="flex">
                {`${userData?.name} Requested more information on
                ${moment(data.event_time).format(
                  'YYYY-MM-DD'
                )} with comment : "${data.description}"  `}
              </div>
            )}
            {data.activity_type == 'UPLOAD' && (
              <div className="flex">
                {`${data.description} by ${userData?.name} on
                ${moment(data.event_time).format('YYYY-MM-DD')}`}
              </div>
            )}
            {data.activity_type == 'APPROVED' && (
              <div className="flex">
                {`Approved by ${userData?.name} on
                ${moment(data.event_time).format('YYYY-MM-DD')}`}
              </div>
            )}
            {data.activity_type == 'REJECTED' && (
              <div className="flex">
                {`Rejected by ${userData?.name} on
                ${moment(data.event_time).format('YYYY-MM-DD')}`}
              </div>
            )}
            {data.activity_type == 'DELETED' && (
              <div className="flex">
                {`${data.description} by ${userData?.name} on
                ${moment(data.event_time).format('YYYY-MM-DD')}`}
              </div>
            )}
          </div>
        )}
    </div>
  )
}
