import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },

  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  disclosure: {
    width: '100%',
    marginBottom: 10,
    paddingBottom: 4,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    borderBottom: '1px solid black',
  },

  footerImage: {
    paddingTop: 5,
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },

  footerText: {
    fontSize: 7,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },

  image: {
    textAlign: 'center',
    width: '20',
    marginVertical: 5,
    marginHorizontal: 100,
  },

  pageFooter: {
    width: '100%',
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  signingContainer: {
    marginBottom: 80,
  },

  signTags: {
    margin: 10,
    fontSize: 12,
    fontFamily: 'Times-Roman',
    color: 'white',
  },

  table: {
    width: '70%',
    borderStyle: 'solid',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 1,
  },

  tableBody: {
    flexDirection: 'column',
  },

  tableCell: {
    margin: 2,
    paddingLeft: 4,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    alignContent: 'flex-start',
  },

  tableCellRightAligned: {
    margin: 2,
    paddingRight: 4,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    textAlign: 'right',
  },

  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableRow: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableView: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 20,
  },

  tableWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  text: {
    margin: 10,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  textBold: {
    margin: 10,
    fontSize: 12,
    fontFamily: 'Times-Bold',
  },

  textLettered: {
    margin: 15,
    marginLeft: 45,
    marginTop: 5,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  textNumbered: {
    margin: 15,
    marginTop: 5,
    marginLeft: 30,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  title: {
    width: '100%',
    marginBottom: 10,
    paddingBottom: 4,
    fontSize: 15,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    borderBottom: '1px solid black',
  },
})
