import { useContext, useEffect, useState } from 'react'

import AuthContext from '@contexts/auth'

export const useFlexpathWaterfallCustomData = () => {
  const { appliedFilters, company } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const [data, setData] = useState<any>([true, {}])

  const fileName = `${Date.now()}_waterfall_remittance_report.xlsx`

  useEffect(() => {
    setData([
      false,
      {
        fileUrl: `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacilityId}&tab=document-centre&fileName=${fileName}`,
      },
    ])
  }, [])

  return data
}
