import { UserService } from '@services/api-admin/settings-user'

export const fetchUsers = async ({
  slug_name,
  is_external,
}: {
  slug_name: string
  is_external: boolean
}) => {
  const { total } = await UserService.getUsersBasic({
    slug_name,
    is_external,
    per_page: 5,
    page: 0,
  })
  const { data } = await UserService.getUsersBasic({
    slug_name,
    is_external,
    per_page: total,
    page: 0,
  })
  return data
}
