import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  containerView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  containerCenteredContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  table: {
    width: '70%',
    borderStyle: 'solid',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 1,
  },

  tableBody: {
    flexDirection: 'column',
  },

  tableCell: {
    margin: 2,
    paddingLeft: 4,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    alignContent: 'flex-start',
  },

  tableCellRightAligned: {
    margin: 2,
    paddingRight: 4,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    textAlign: 'right',
  },

  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableRow: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableView: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },

  tableWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  text: {
    margin: 4,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  textBold: {
    margin: 4,
    fontSize: 12,
    fontFamily: 'Times-Bold',
  },

  textIndented: {
    margin: 4,
    fontSize: 12,
    fontFamily: 'Times-Roman',
    textIndent: 36,
  },

  textUnderline: {
    margin: 4,
    fontSize: 12,
    fontFamily: 'Times-Roman',
    textDecoration: 'underline',
  },

  title: {
    width: '100%',
    marginBottom: 4,
    paddingBottom: 2,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    textDecoration: 'underline',
  },

  underlineView: {
    width: '200px',
    borderBottom: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: '8px',
  },
})
