import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import FilesUploadDropZone from '@components/file-upload-modal/drop-zone'
import FormInput from '@components/form/form-input'
import { MultiEmbedView } from '@components/multi-embed-view'
import AuthContext from '@contexts/auth'
import {
  ArrowPathIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  Alert,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import {
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_TEXT,
} from '@pages/manage/monitor/actions/document-centre/constants'
import { AuditService } from '@services/api-manage/audit'

import { AuditInfoAccordionGroup } from './audit-info-accordions'
import { ReviewActions } from './review-actions'

interface ReviewDialogProps {
  open: boolean
  handler: any
  dataArray: any[]
  auditSummary: any
  artefact: string
  showCols: string[]
  access: string
  auditType: 'Underwriting' | 'Payment'
}
export const ReviewDialog = ({
  open,
  handler,
  dataArray,
  auditSummary,
  artefact,
  showCols,
  access,
  auditType,
}: ReviewDialogProps) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const { user } = useAuth0()

  const [auditPermissions, setAuditPermissions] = useState<string>('')
  const [attachments, setAttachments] = useState<any>([])
  const [isUploading, setIsUploading] = useState<boolean>()
  const [isViewEvidence, setIsViewEvidence] = useState<boolean>(false)
  const [uploads, setUploads] = useState<any>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const itemIdParam = searchParams.get('itemId')
  const currentIdx = !!itemIdParam
    ? dataArray.findIndex((ui: any) => ui.item_id == itemIdParam)
    : 0

  const record = dataArray[currentIdx]

  const isFinalisedStatus = ['FINALISED', 'VOIDED'].includes(
    auditSummary.audit_status
  )

  const filters = {
    slug_name: company?.slug_name,
    slug: company?.slug_name,
    id: record.item_id,
    user_id: user?.sub,
    type: auditType.toLowerCase(),
    facility_id: activeFacilityId,
  }

  //fetch audit item info
  const {
    data,
    isLoading: isLoadingItemData,
    refetch: refetchData,
    error,
  } = useQuery(['AuditItemData', filters], () =>
    AuditService.getAuditItemInfo(filters)
  )

  const getNext = () => {
    if (currentIdx + 1 < dataArray.length) {
      setIsViewEvidence(false)
      setUploads([])
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('itemId', dataArray[currentIdx + 1].item_id)
      setSearchParams(newSearchParams.toString())
    }
  }

  const getPrev = () => {
    if (currentIdx > 0) {
      setIsViewEvidence(false)
      setUploads([])
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('itemId', dataArray[currentIdx - 1].item_id)
      setSearchParams(newSearchParams.toString())
    }
  }

  useEffect(() => {
    if (data) {
      if (auditType == 'Underwriting') {
        const allAttachments: any = []
        data.attachments?.forEach((item: any) => {
          const itemArray = item.key.split('/')
          const itemId = itemArray[itemArray.length - 1]
          const index = data.data.required_uploads.findIndex(
            (requiredUpload: any) =>
              requiredUpload.attachment_id &&
              requiredUpload.attachment_id.includes(itemId)
          )
          index > -1 &&
            allAttachments.push({
              uploadType: data.data.required_uploads[index].item,
              key: item.key,
            })
        })
        setAttachments(allAttachments)
      } else {
        const allAttachments = data.attachments?.map((a: { key: string }) => {
          return { uploadType: 'Payment Evidence', key: a.key }
        })
        setAttachments(allAttachments)
      }
    }
  }, [data])

  const {
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  })

  // set super admin default access to uploader (selector)
  useEffect(() => {
    if (access.includes('upload') && access.includes('approve/reject')) {
      setAuditPermissions('access,upload')
    } else {
      setAuditPermissions(access)
    }
  }, [access])

  const cancelSelection = (itemKey: any) => {
    const updatedUploads = { ...uploads }
    delete updatedUploads[itemKey]
    setUploads(updatedUploads)
  }

  return (
    <Dialog
      open={open}
      handler={() => undefined}
      size="xl"
      className="flex h-[90%] justify-between w-screen"
    >
      <form className="w-full flex flex-col justify-between">
        <DialogHeader className="flex justify-between relative">
          <div className="flex flex-col w-full items-center">
            <div>Review Item {record.item_display_id}</div>
            <div className="text-lg font-light">
              {currentIdx + 1} of {dataArray.length}
            </div>
          </div>
          <div onClick={() => handler()} className="absolute right-10">
            <XMarkIcon className="w-7 cursor-pointer" onClick={handler} />
          </div>
        </DialogHeader>
        <DialogBody
          className={`${
            isFinalisedStatus && 'mb-20'
          } h-[60%] flex items-center`}
        >
          {isLoadingItemData ? (
            <div className="flex items-center justify-center w-full h-[50%]">
              <ArrowPathIcon className="animate-spin text-primary-main w-8" />
            </div>
          ) : !!error ? (
            <div className="flex items-center text-center w-full h-full px-20">
              <Alert className="text-danger-main border border-danger-main ">
                An unexpected error has occured
              </Alert>
            </div>
          ) : (
            data?.data && (
              <div className="w-full flex flex-row px-10 h-full">
                <div className="flex items-center">
                  <Button disabled={currentIdx < 1} onClick={() => getPrev()}>
                    <ChevronDoubleLeftIcon className="w-5" />
                  </Button>
                </div>

                <div className="flex px-10 w-full h-full">
                  <div className="flex w-1/3 flex-col border-r pr-10">
                    {access.includes('access') &&
                      access.includes('upload') &&
                      access.includes('approve/reject') &&
                      auditSummary.audit_status != 'VOIDED' && (
                        <div className="w-full">
                          Change Roles
                          <FormInput
                            type="select"
                            value={auditPermissions}
                            onSelected={val => setAuditPermissions(val)}
                            options={[
                              {
                                value: 'access,upload',
                                title: 'Uploader',
                              },
                              {
                                value: 'access,approve/reject',
                                title: 'Auditor',
                              },
                            ]}
                            error={errors?.currency?.message as string}
                          />
                        </div>
                      )}
                    {!isLoadingItemData && data?.data && (
                      <AuditInfoAccordionGroup
                        data={data}
                        showCols={showCols}
                        artefact={artefact}
                      />
                    )}
                  </div>
                  <div className="w-2/3 h-full ml-10">
                    <div className="text-lg font-bold pb-3">
                      {auditPermissions.includes('upload') && !isFinalisedStatus
                        ? 'Submit'
                        : 'Review'}{' '}
                      Evidence
                    </div>
                    {(auditPermissions.includes('upload') ||
                      auditPermissions.includes('approve/reject')) && (
                      <div className="flex flex-col justify-between w-full h-full overflow-y-auto pb-10 relative">
                        {auditPermissions.includes('upload') &&
                          !isFinalisedStatus &&
                          !isViewEvidence && (
                            <>
                              <div className="flex flex-col justify-between">
                                {!isUploading ? (
                                  <div className="grid grid-cols-2 gap-4 ">
                                    {auditType == 'Payment' ? (
                                      <div className="flex flex-col text-sm border rounded-lg p-3 justify-between col-span-2">
                                        <div className="text-sm pb-2">
                                          {company?.slug_name == 'mfs'
                                            ? 'Evidence*'
                                            : 'Payment Evidence*'}
                                        </div>
                                        <div className="">
                                          <FilesUploadDropZone
                                            id={itemIdParam ?? ''}
                                            containerClass="relative border-dashed border-2 border-neutral-border-1 rounded-lg cursor-pointer bg-[#f5f5f5] h-20  px-2"
                                            fileTypeText={'.pdf'}
                                            isLoading={false}
                                            maxSize={MAX_FILE_SIZE}
                                            maxFiles={1}
                                            titleMaxFilesSize={
                                              MAX_FILE_SIZE_TEXT
                                            }
                                            fileTypes={{
                                              'application/pdf': ['.pdf'],
                                            }}
                                            handleCancel={() =>
                                              cancelSelection(
                                                'Payment Evidence'
                                              )
                                            }
                                            uploader={(file: Array<any>) => {
                                              setValue('file', file)
                                              setUploads({
                                                ...uploads,
                                                ['Payment Evidence']: file,
                                              })
                                            }}
                                            isHintHidden={true}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      data.data.required_uploads?.map(
                                        (requiredUpload: any, i: number) => (
                                          <div
                                            className={`flex flex-col text-sm border rounded-lg p-3 justify-between ${
                                              data.data.required_uploads
                                                .length < 2 && 'col-span-2'
                                            }`}
                                            key={i}
                                          >
                                            <div className="text-sm pb-2">
                                              {requiredUpload.item}*
                                            </div>
                                            <div className="">
                                              <FilesUploadDropZone
                                                id={`${itemIdParam}-${i}-${requiredUpload.item}`}
                                                containerClass="relative border-dashed border-2 border-neutral-border-1 rounded-lg cursor-pointer bg-[#f5f5f5] h-24 px-2"
                                                fileTypeText={'.pdf'}
                                                isLoading={false}
                                                maxSize={MAX_FILE_SIZE}
                                                maxFiles={1}
                                                titleMaxFilesSize={
                                                  MAX_FILE_SIZE_TEXT
                                                }
                                                fileTypes={{
                                                  'application/pdf': ['.pdf'],
                                                }}
                                                handleCancel={() =>
                                                  cancelSelection(
                                                    requiredUpload.item
                                                  )
                                                }
                                                uploader={(
                                                  file: Array<any>
                                                ) => {
                                                  setValue('file', file)
                                                  setUploads({
                                                    ...uploads,
                                                    [requiredUpload.item]: file,
                                                  })
                                                }}
                                                isHintHidden={true}
                                              />
                                            </div>
                                          </div>
                                        )
                                      )
                                    )}
                                    {!isViewEvidence &&
                                      auditPermissions.includes('upload') &&
                                      attachments.length > 0 && (
                                        <div className="sticky flex justify-center items-center col-span-2 absolute bottom-3 w-full">
                                          <Button
                                            onClick={() =>
                                              setIsViewEvidence(!isViewEvidence)
                                            }
                                          >
                                            {'View Submitted Evidence >>'}
                                          </Button>
                                        </div>
                                      )}
                                  </div>
                                ) : (
                                  <div className="w-full flex item-center justify-center">
                                    <ArrowPathIcon className="animate-spin w-7 text-primary" />
                                  </div>
                                )}
                              </div>
                            </>
                          )}

                        {(auditPermissions.includes('approve/reject') ||
                          !!isViewEvidence ||
                          isFinalisedStatus) && (
                          <div className="flex flex-col w-full h-[95%]">
                            <div className="h-full pb-2">
                              {!isLoadingItemData ? (
                                <MultiEmbedView
                                  itemsArray={attachments}
                                  auditType={auditType}
                                />
                              ) : (
                                <div className="w-full h-full flex item-center justify-center">
                                  <ArrowPathIcon className="animate-spin w-7 text-primary" />
                                </div>
                              )}
                            </div>
                            {!!isViewEvidence &&
                              auditPermissions.includes('upload') && (
                                <div className="flex justify-center items-center col-span-2 absolute bottom-3 w-full">
                                  <Button
                                    onClick={() =>
                                      setIsViewEvidence(!isViewEvidence)
                                    }
                                  >
                                    {'<< Back to Submit'}
                                  </Button>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center">
                  <Button
                    disabled={currentIdx + 1 >= dataArray.length}
                    onClick={() => getNext()}
                  >
                    <ChevronDoubleRightIcon className="w-5" />
                  </Button>
                </div>
              </div>
            )
          )}
        </DialogBody>
        <DialogFooter className="flex items-center w-full px-16">
          {!isLoadingItemData && !!data && !isFinalisedStatus && (
            <ReviewActions
              auditStatus={auditSummary.audit_status}
              isLoadingItemData={isLoadingItemData}
              auditPermissions={auditPermissions}
              getValues={getValues}
              data={data}
              register={register}
              setValue={setValue}
              refetchData={refetchData}
              currentIdx={currentIdx}
              reset={reset}
              getNextArtifact={() => getNext()}
              itemId={record.item_id}
              auditId={auditSummary.audit_id}
              setIsUploading={setIsUploading}
              total={dataArray.length}
              uploads={uploads}
              auditType={auditType}
            />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  )
}
