import React from 'react'
import moment from 'moment'

import { TemplateProps } from '@interfaces/manage-reporting'
import { Document, Link, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../components'
import { styles } from '../../../template_style'

const CoignComplianceCertTemplate = ({
  agent,
  borrower,
  agreementDate,
  customData,
  formData,
}: TemplateProps) => (
  <Document>
    <Page style={styles.body}>
      <Text style={{ ...styles.title, borderBottom: 'none' }}>
        Form of Compliance Certificate
      </Text>
      <Text style={styles.text}>
        Date: {moment(formData['effective-date']).format('MMMM DD, YYYY')}
      </Text>
      <Text style={styles.text}>
        TO:{' '}
        {agent
          ? `${agent}, a Delaware limited liability company, as Lender`
          : '[Agent]'}
      </Text>
      <Text style={styles.text}>FROM: {borrower || '[Borrower]'}</Text>
      <Text style={{ ...styles.text, marginTop: 16 }}>
        The undersigned authorized officer of {borrower}, a Delaware limited
        liability company (“Borrower”) and Zing America, Inc., a Delaware
        corporation (“Parent”), hereby certifies that in accordance with the
        terms and conditions of that certain Loan Agreement, dated as of{' '}
        {moment.utc(agreementDate).format('MMMM DD, YYYY')} (as amended,
        restated, amended and restated, supplemented or otherwise modified from
        time to time, the “Loan Agreement”), made and entered into by and among
        Borrower, Parent, and {agent}, a Delaware limited liability company (the
        “Lender”), as follows:
      </Text>
      <Text style={styles.text}>
        If there exists any Event of Default, the Borrower has specified below:
        (a) the nature and period of existence thereof and (b) what action, if
        any, the Borrower is taking with respect thereto.
      </Text>
      <Text style={styles.text}>
        Since the Effective Date and except as disclosed in prior Certificates
        delivered to Lender or otherwise in writing to Lender, the Borrower has
        not created any new Deposit Account, except{' '}
      </Text>
      <View style={{ marginLeft: 32 }}>
        <Text style={{ ...styles.text, marginBottom: 0 }}>
          <Text style={{ ...styles.textBold, marginBottom: 0 }}>
            Bank Name:{' '}
          </Text>
          {customData?.bankingInfo?.bank_name ?? '[Bank Name]'}
        </Text>
        <Text style={{ ...styles.text, marginVertical: 0 }}>
          <Text style={{ ...styles.textBold, marginVertical: 0 }}>
            Account Name:{' '}
          </Text>
          {customData?.bankingInfo?.account_name ?? '[Account Name]'}
        </Text>
        <Text style={{ ...styles.text, marginTop: 0 }}>
          <Text style={{ ...styles.textBold, marginTop: 0 }}>
            Account Number:{' '}
          </Text>
          {customData?.bankingInfo?.account_number ?? '[Account Number]'}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.text, marginBottom: 0 }}>
          Attached herewith are computations supporting the financial covenants
          and calculations set forth below.
        </Text>
        <Link
          style={{ ...styles.textLink, marginTop: 0 }}
          src={customData?.fileUrl}
        >
          {customData?.fileUrl}
        </Link>
      </View>
      <Text style={styles.text}>
        The financial statements of the Borrower or Parent, as applicable,
        delivered to the Lender with this Compliance Certificate have been
        prepared in accordance with GAAP consistently applied except as
        explained in an accompanying letter or footnotes or unless otherwise
        permitted in the Loan Agreement
      </Text>
      <Text style={styles.text}>
        All capitalized terms used herein shall have the respective meanings
        specified in the Loan Agreement unless otherwise defined herein or
        unless context requires otherwise.
      </Text>
      <Footer />
    </Page>
  </Document>
)

export default CoignComplianceCertTemplate
