import React from 'react'

import { Text } from '@react-pdf/renderer'

import { styles } from '../../template_style'

interface Props {
  href: string
  width?: number | string
}

export const Link = ({ href, width }: Props) => {
  return (
    <Text
      style={{
        ...styles.text,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: width ?? '100%',
      }}
      hyphenationCallback={word => ['', word, '']}
    >
      {href.match(/\w+|\W+/g)?.map((seg: string, i: number) => {
        return <Text key={`segment-${i}`}>{seg}</Text>
      })}
    </Text>
  )
}
