import React, { useEffect } from 'react'

import Typography from '@components/atoms/typography'
import FormInput from '@components/form/form-input'
import { IsValidEmail } from '@helpers/validator'

interface Props {
  form: any
  user: any
  index: number
  hideTitle?: boolean
  signer?: ISigner
}

export interface ISigner {
  name: string
  email: string
  title?: string
}

const Signer = ({ form, user, index, hideTitle, signer }: Props) => {
  const {
    formState: { errors, isSubmitting },
    getValues,
    register,
    setValue,
  } = form

  useEffect(() => {
    if (signer?.name) {
      setValue(`signers[${index}].name`, signer.name, {
        shouldValidate: true,
      })
    }
    if (signer?.email) {
      setValue(`signers[${index}].email`, signer.email, {
        shouldValidate: true,
      })
    }
    if (!hideTitle && signer?.title) {
      setValue(`signers[${index}].title`, signer.title, {
        shouldValidate: true,
      })
    }
  }, [])

  return (
    <div className="mb-4">
      <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
        <Typography className="font-semibold text-sm">
          Signer{index > 0 ? ` ${index + 1}` : ''}
        </Typography>
      </div>
      <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
        {!signer?.email && (
          <div className="my-1">
            <FormInput
              type="checkbox"
              label={{ end: 'I am the signee' }}
              value={getValues(`signers?.[${index}]?.selfSigned`) || false}
              {...register(`signers[${index}].selfSigned`, {
                disabled: isSubmitting,
                onChange: (e: any) => {
                  setValue(`signers[${index}].selfSigned`, e.target.checked, {
                    shouldValidate: true,
                  })
                  setValue(
                    `signers[${index}].name`,
                    e.target.checked ? user?.name : '',
                    {
                      shouldValidate: true,
                    }
                  )
                  setValue(
                    `signers[${index}].email`,
                    e.target.checked ? user?.email : '',
                    {
                      shouldValidate: true,
                    }
                  )
                },
              })}
            />
          </div>
        )}
        <FormInput
          type="text"
          label={{
            start: 'Authorized Officer Name (Signee)',
          }}
          value={getValues(`signers[${index}].name`) || ''}
          disabled={
            getValues(`signers[${index}].selfSigned`) ||
            signer?.name ||
            isSubmitting
          }
          {...register(`signers[${index}].name`, {
            required: 'Name is required',
            onChange: (e: any) => {
              setValue(`signers[${index}].name`, e.target.value, {
                shouldValidate: true,
              })
            },
          })}
          error={errors?.signers?.[index]?.name?.message as string}
        />
        {!hideTitle && (
          <FormInput
            type="text"
            label={{
              start: getValues(`signers[${index}].selfSigned`)
                ? 'Your title'
                : 'Authorized Officer Title (Signee)',
            }}
            disabled={signer?.title || isSubmitting}
            value={getValues(`signers[${index}].title`) || ''}
            {...register(`signers[${index}].title`, {
              required: !hideTitle && `Title is required`,
              onChange: (e: any) => {
                setValue(`signers[${index}].title`, e.target.value, {
                  shouldValidate: true,
                })
              },
            })}
            error={errors?.signers?.[index]?.title?.message as string}
          />
        )}
        <FormInput
          type="text"
          label={{ start: 'Authorized Officer Email (Signee)' }}
          value={getValues(`signers[${index}].email`) || ''}
          disabled={
            getValues(`signers[${index}].selfSigned`) ||
            signer?.email ||
            isSubmitting
          }
          {...register(
            `signers[${index}].email`,
            !getValues('selfSigned')
              ? {
                  required: 'Email is required',
                  onChange: (e: any) => {
                    setValue(`signers[${index}].email`, e.target.value, {
                      shouldValidate: true,
                    })
                  },
                  validate: {
                    validEmail: (v: string) =>
                      v ? IsValidEmail(v) || 'Invalid Email' : undefined,
                  },
                }
              : {}
          )}
          error={errors?.signers?.[index]?.email?.message as string}
        />
      </div>
    </div>
  )
}

export default Signer
