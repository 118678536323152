import React from 'react'
import moment from 'moment'

import { formatCurrency } from '@helpers/number-formatter'
import { AdvanceRequestTemplateProps } from '@interfaces/manage-signature'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { generateTables } from '../../../document-centre/templates/borrowing-base-cert/data-helpers'
import { Footer, Table } from '../../../document-centre/templates/components'

import { styles as localStyles } from './style'

export const RaistoneAdvanceRequestTemplate = ({
  requestDate,
  advanceDate,
  loanAgreementDate,
  formData,
}: AdvanceRequestTemplateProps) => {
  const bbTableData = formData.borrowing_base_data
    ? generateTables(formData.borrowing_base_data)
    : []
  if (bbTableData.length) {
    bbTableData[0].title = ''
  }
  return (
    <Document>
      <Page style={localStyles.body}>
        <Text style={localStyles.title}>NOTICE OF BORROWING</Text>
        <Text style={{ ...localStyles.text, marginBottom: 20 }}>
          {moment.utc(requestDate).format('DD MMMM, YYYY') || '[tbd], 202[_]'}
        </Text>
        <Text style={localStyles.text}>Variant Alternative Income Fund</Text>
        <Text style={localStyles.text}>10300 SW Greenburg Road, Suite 308</Text>
        <Text style={localStyles.text}>Portland, Oregon 97223</Text>
        <Text style={{ ...localStyles.text, marginBottom: 20 }}>
          Attention: J.B. Hayes
        </Text>
        <Text style={{ ...localStyles.text, marginBottom: 20 }}>
          Ladies and Gentlemen:
        </Text>
        <Text style={localStyles.text}>
          Reference is made to the Loan and Security Agreement, dated as of{' '}
          {loanAgreementDate
            ? moment.utc(loanAgreementDate).format('DD MMMM, YYYY')
            : `[●],
          2024`}{' '}
          (as it may be amended, restated, supplemented, or otherwise modified
          from time to time, the{' '}
          <Text style={localStyles.textBold}>“Loan Agreement”</Text>), among the
          undersigned, as Borrower, the lenders party thereto from time to time
          (each a <Text style={localStyles.textBold}>“Lender”</Text> and
          collectively, the <Text style={localStyles.textBold}>“Lenders”</Text>
          ), and Variant Alternative Income Fund, as administrative, payment and
          collateral agent on behalf of the Lenders. Terms defined in the Loan
          Agreement are used herein with the same meanings. This notice
          constitutes a Notice of Borrowing, and Borrower hereby requests an
          Advance under the Loan Agreement (
          <Text style={localStyles.textBold}>“Borrowing”</Text>), and in that
          connection Borrower specifies the following information with respect
          to the Borrowing requested hereby:
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid black',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              width: '55%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: 12,
            }}
          >
            <Text style={{ ...localStyles.text, margin: 0, marginLeft: 16 }}>
              (A)
            </Text>
            <Text style={{ ...localStyles.text, margin: 0, width: 220 }}>
              Aggregate principal amount of requested Borrowing:
            </Text>
          </View>
          <Text style={{ ...localStyles.text, margin: 0 }}>
            {formData?.amount
              ? formatCurrency(formData?.amount)
              : '$[____________]'}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid black',
            borderTop: 'none',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              width: '55%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: 12,
            }}
          >
            <Text style={{ ...localStyles.text, margin: 0, marginLeft: 16 }}>
              (B)
            </Text>
            <Text style={{ ...localStyles.text, margin: 0, width: 220 }}>
              Date of requested Borrowing (which must be a Business Day):
            </Text>
          </View>
          <Text style={{ ...localStyles.text, margin: 0 }}>
            {advanceDate
              ? moment.utc(advanceDate).format('DD MMMM, YYYY')
              : '[TBD]'}
          </Text>
        </View>
      </Page>
      <Page style={localStyles.body}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{ ...localStyles.textBold, textDecoration: 'underline' }}
          >
            Company Name:
          </Text>
          <Text style={{ ...localStyles.textBold, marginRight: 148 }}>
            RAISTONE CAPITAL LLC
          </Text>
        </View>
        <Text style={localStyles.textBold}>
          Borrowing Base{' '}
          {formData.calculation_date
            ? moment.utc(formData.calculation_date).format('DD MMMM, YYYY')
            : '[date]'}
        </Text>
        {bbTableData.length && (
          <Table key={bbTableData[0].key} data={bbTableData[0]} />
        )}
        <Footer />
      </Page>
    </Document>
  )
}
