import React, { useContext, useEffect, useState } from 'react'
import { formatISO } from 'date-fns'
import moment from 'moment'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { CovenantType } from '@interfaces/manage-monitor-covenant'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { Card, CardBody } from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'
import { CashflowService } from '@services/api-manage/monitor-cashflow'
import { MonitorFilterService } from '@services/api-manage/monitor-filter'

import SurplusChart from '../borrowing-base/borrowing-base/surplus-chart'
import CovenantList from '../covenant/list'

const LOADING = 'Loading...'

const Overview = ({ facility }: { facility?: Facility }) => {
  const {
    company,
    activeFilters,
    appliedFilters,
    setAppliedFilters,
    optionFilters,
  } = useContext(AuthContext)
  const { activeFacilityId } = activeFilters
  const { activeFacilityId: appliedFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const appliedFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === appliedFacilityId
  )
  const isMasterFacility = appliedFacility?.is_master_facility

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      activeFacilityId !== undefined &&
      activeFilters.calculationDate !== undefined &&
      !facility
    ) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  const borrowingRequest = {
    slug_name: appliedFacility?.slug_name ?? company?.slug_name ?? '',
    facility_id: appliedFacilityId,
    calculation_date: activeFilters.calculationDate
      ? formatISO(activeFilters.calculationDate)
      : '', // default to empty string if As Of date does not exist
  }

  const { data: borrowingBase, isFetched: isFetchedBbData } = useQuery(
    ['borrowing-base', borrowingRequest],
    () => BorrowingBaseService.get(borrowingRequest),
    {
      ...getStaleMins(),
      enabled:
        !isMasterFacility &&
        !!company?.lastManagePipeline &&
        appliedFacility?.features?.includes('borrowing-base'),
    }
  )

  const filters = {
    slugName:
      facility?.slug_name ??
      appliedFacility?.slug_name ??
      company?.slug_name ??
      '',
    facility: facility?.facility_id ?? appliedFacilityId,
  }

  const { data: statisticData, isFetched: isFetchedStatistics } = useQuery(
    ['paymentScheduleStatistic', filters],
    () => CashflowService.getDisbursementSummary(filters),
    {
      ...getStaleMins(),
      enabled:
        ((!!facility && facility?.features?.includes('cashflows')) ||
          (!!appliedFacility &&
            appliedFacility?.features?.includes('cashflows'))) &&
        !!company?.slug_name,
    }
  )

  const { data: calculationDate, isFetched: isFetchedCalculationDate } =
    useQuery(
      ['get-latest-child-facility-calculation-date', [facility]],
      () => MonitorFilterService.getLatestCalculationDateForFacility(facility),
      {
        ...getStaleMins(),
        enabled: !!isMasterFacility && !!facility,
      }
    )

  let isReady = false

  if (isMasterFacility) {
    isReady =
      (!!facility && facility?.features?.includes('cashflows')
        ? isFetchedStatistics
        : true) && isFetchedCalculationDate
  } else {
    isReady =
      (!!appliedFacility && appliedFacility?.features?.includes('cashflows')
        ? isFetchedStatistics
        : true) &&
      (!!appliedFacility &&
      appliedFacility?.features?.includes('borrowing-base')
        ? isFetchedBbData
        : true)
  }

  const outstandingPrincipal =
    borrowingBase?.find(
      f => f.display_name === 'Total Lender Principal Balance'
    )?.value ??
    statisticData?.data?.outstanding_principal ??
    0

  const lendersInfo = appliedFacility?.lenders_info
    ? JSON.parse(appliedFacility.lenders_info)
    : {}
  const committedAmount = Array(Object.keys(lendersInfo).length)
    .fill('')
    .reduce((prev: number, _: any, idx: number) => {
      try {
        const lenderInfo = lendersInfo[`lender_${idx + 1}`]
        if (lenderInfo) {
          return (
            prev + Number(lenderInfo[`lender_${idx + 1}_committed_amount`] ?? 0)
          )
        }
        return prev
      } catch (error) {
        return 0
      }
    }, 0)

  const amountAvailable = (committedAmount ?? 0) - outstandingPrincipal

  const cashCoverage =
    (statisticData?.data?.cash_available ?? 0) -
    (statisticData?.data?.next_payment_amount ?? 0)

  const onClick = (url: string, facility?: Facility): string => {
    if (facility) {
      const searchParams = new URLSearchParams()
      searchParams.set('pid', facility?.slug_name ?? '')
      searchParams.set('facilityID', String(facility?.facility_id))
      url += `?${searchParams.toString()}`
    }
    return url
  }

  return (
    <div>
      <div className="flex gap-6 justify-center">
        {(facility?.features?.includes('borrowing-base') ||
          appliedFacility?.features?.includes('borrowing-base')) && (
          <NavLink
            to={onClick('/manage/monitor/borrowing-base', facility)}
            className={`flex-1 ${facility ? 'max-w-[33%]' : ''}`}
          >
            <Card className="h-full rounded-md">
              <SurplusChart
                facility={facility}
                facilityCalculationDate={calculationDate}
              />
            </Card>
          </NavLink>
        )}
        {appliedFacility?.features?.includes('cashflows') && !facility && (
          <NavLink to={`/manage/monitor/cashflows`} className="flex-1">
            <Card className="rounded-md h-full">
              <CardBody>
                <Table
                  columns={[
                    {
                      title: 'Payment Information',
                      field: 'payment_info',
                      head: {
                        colSpan: 2,
                        className: 'text-center',
                      },
                    },
                    {
                      field: 'payment_value',
                      head: {
                        colSpan: 0,
                      },
                    },
                  ]}
                  data={[
                    {
                      payment_info: 'Next Payment Date',
                      payment_value: isReady
                        ? statisticData?.data?.next_payment_date
                          ? `${moment
                              .utc(statisticData?.data?.next_payment_date)
                              .format('YYYY-MM-DD')} <br />${moment
                              .utc(statisticData?.data?.next_payment_date)
                              .fromNow()}`
                          : '-'
                        : LOADING,
                    },
                    {
                      payment_info: 'Cash Available',
                      payment_value: isReady
                        ? `${Intl.NumberFormat(undefined, {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          }).format(
                            statisticData?.data?.cash_available ?? 0
                          )} USD`
                        : LOADING,
                    },
                    {
                      payment_info: 'Next Payment value',
                      payment_value: isReady
                        ? `${Intl.NumberFormat(undefined, {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          }).format(
                            statisticData?.data?.next_payment_amount ?? 0
                          )} USD`
                        : LOADING,
                    },
                    {
                      payment_info: 'Cash Coverage',
                      payment_value: isReady
                        ? `<span class="${
                            cashCoverage < 0
                              ? 'text-danger-main'
                              : 'text-success-main'
                          }">${cashCoverage < 0 ? '(' : ''}${Intl.NumberFormat(
                            undefined,
                            {
                              style: 'decimal',
                              maximumFractionDigits: 2,
                            }
                          ).format(Math.abs(cashCoverage ?? 0))} USD${
                            cashCoverage < 0 ? ')' : ''
                          }</span>`
                        : LOADING,
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </NavLink>
        )}
        {appliedFacility?.features?.includes('actions') && !facility && (
          <NavLink
            to={`/manage/monitor/actions?${company?.slug_name}&tab=advance-request`}
            className="flex-1"
          >
            <Card className="rounded-md h-full  hover:cursor-pointer">
              <CardBody className="">
                <Table
                  columns={[
                    {
                      title: 'Funds Available',
                      field: 'fund_info',
                      head: {
                        colSpan: 2,
                        className: 'text-center',
                      },
                    },
                    { field: 'fund_value', colSpan: 0 },
                  ]}
                  data={[
                    {
                      fund_info: 'Drawdown Period End',
                      fund_value: isReady
                        ? appliedFacility?.draw_period_end_date
                          ? `${moment
                              .utc(appliedFacility.draw_period_end_date)
                              .format('YYYY-MM-DD')} <br />${moment
                              .utc(appliedFacility.draw_period_end_date)
                              .fromNow()}`
                          : '-'
                        : LOADING,
                    },
                    {
                      fund_info: 'Committed Amount',
                      fund_value: isReady
                        ? `${Intl.NumberFormat(undefined, {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          }).format(committedAmount ?? 0)} USD`
                        : LOADING,
                    },
                    {
                      fund_info: 'Outstanding Principal',
                      fund_value: isReady
                        ? `${Intl.NumberFormat(undefined, {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          }).format(
                            outstandingPrincipal ? outstandingPrincipal : 0
                          )} USD`
                        : LOADING,
                    },
                    {
                      fund_info: 'Amount Available',
                      fund_value: isReady
                        ? `<span class="${
                            amountAvailable < 0
                              ? 'text-danger-main'
                              : 'text-success-main'
                          }">${
                            amountAvailable < 0 ? '(' : ''
                          }${Intl.NumberFormat(undefined, {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          }).format(Math.abs(amountAvailable ?? 0))} USD${
                            amountAvailable < 0 ? ')' : ''
                          }</span>`
                        : LOADING,
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </NavLink>
        )}
      </div>
      {(facility?.features?.includes('portfolio-covenants') ||
        appliedFacility?.features?.includes('portfolio-covenants')) && (
        <div className="mt-6">
          <Card className="rounded-md">
            <CardBody>
              <CovenantList type={CovenantType.portfolio} facility={facility} />
            </CardBody>
          </Card>
        </div>
      )}
      {(facility?.features?.includes('financial-covenants') ||
        appliedFacility?.features?.includes('financial-covenants')) && (
        <div className="mt-6">
          <Card className="rounded-md">
            <CardBody>
              <CovenantList type={CovenantType.financial} facility={facility} />
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  )
}
export default Overview
