import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  title: {
    width: '100%',
    marginBottom: 10,
    paddingBottom: 4,
    fontSize: 15,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    borderBottom: '1px solid black',
  },

  text: {
    margin: 6,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  textBold: {
    margin: 6,
    fontSize: 12,
    fontFamily: 'Times-Bold',
  },

  textSpaced: {
    margin: 10,
    fontSize: 12,
    fontFamily: 'Times-Roman',
  },

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid black',
  },

  tableColumn: {
    borderWidth: 1,
    borderColor: 'black',
    fontSize: 12,
    fontFamily: 'Times-Roman',
    padding: 6,
  },

  tableColumnVariant: {
    backgroundColor: '#EDEDED',
  },

  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    paddingHorizontal: 4,
  },

  disclosure: {
    width: '100%',
    marginBottom: 10,
    marginTop: 100,
    paddingBottom: 4,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    borderBottom: '1px solid black',
  },
})
