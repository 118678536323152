import React from 'react'

import Table from '@components/table'
import {
  CovenantDetailResponse,
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'

import { useSpecialCovenantDetail } from '../helper'

interface CovenantDetailVintageTableOnlyProps {
  covenant: CovenantDetailResponse
  type: CovenantType
  summary: CovenantListResponse
}

const CovenantDetailVintageTableOnly = ({
  covenant,
  summary,
}: CovenantDetailVintageTableOnlyProps) => {
  const { tableColumns, tableData, tableHeaderData } = useSpecialCovenantDetail(
    covenant,
    summary
  )

  return (
    <div className="gap-6 [&>div]:w-full">
      <Table
        containerClass="nohover overflow-auto [&>table>thead>tr>td]:font-semibold"
        allowOverflow={false}
        data={tableData}
        headerData={tableHeaderData}
        columns={tableColumns as any}
      />
    </div>
  )
}

export default CovenantDetailVintageTableOnly
