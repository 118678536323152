import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  text: {
    marginHorizontal: 10,
    marginVertical: 8,
    fontSize: 10,
    fontFamily: 'Times-Roman',
  },

  textBold: {
    marginHorizontal: 10,
    marginVertical: 8,
    fontSize: 10,
    fontFamily: 'Times-Bold',
    fontWeight: 'bold',
  },

  viewHorizontalFlexIndented: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: 32,
  },
})
