import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 55,
  },

  text: {
    margin: 6,
    fontSize: 12,
    fontFamily: 'Helvetica',
    lineHeight: 1.5,
  },

  textBold: {
    margin: 6,
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    lineHeight: 1.5,
  },

  title: {
    width: '100%',
    marginBottom: 10,
    paddingBottom: 4,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    lineHeight: 1.5,
  },
})
