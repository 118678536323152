import React, { useContext, useEffect, useState } from 'react'
import { isArray } from 'lodash'
import { useQuery } from 'react-query'

import { User } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import OptionSearch from '@components/atoms/search'
import AuthContext from '@contexts/auth'
import { BellAlertIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  Avatar,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'

import { fetchUsers } from '../../helpers'

interface EditSubsDialogProps {
  open: boolean
  handler: () => void
  subscribers: User[]
  showActions: boolean
  confirmHandler: (subs: User[]) => void
}

export const EditSubsDialog = ({
  open,
  handler,
  confirmHandler,
  subscribers,
  showActions,
}: EditSubsDialogProps) => {
  const { company } = useContext(AuthContext)
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const [selectedUsers, setSelectedUsers] = useState<User[]>(subscribers)

  const { data: internalUsers } = useQuery(['userListInternal'], () =>
    fetchUsers({ slug_name: company?.slug_name ?? '', is_external: false })
  )

  const { data: externalUsers } = useQuery(['userListExternal'], () =>
    fetchUsers({ slug_name: company?.slug_name ?? '', is_external: true })
  )

  const onSelect = (userId: any) => {
    setShowOptions(false)
    if (!!users) {
      const selectedUserIdx = users.findIndex(
        (user: User) => user.user_id == userId
      )
      isArray(selectedUsers) &&
        setSelectedUsers([...selectedUsers, users[selectedUserIdx]])
      users.splice(selectedUserIdx, 1)
    }
  }

  const removeSubscriber = (index: number) => {
    const newUserList = [...selectedUsers]
    const removedSub = newUserList.splice(index, 1)
    setSelectedUsers(newUserList)
    setUsers(prevUsers => [...prevUsers, removedSub[0]])
  }

  useEffect(() => {
    if (isArray(internalUsers) && isArray(externalUsers)) {
      const allUsers = [...internalUsers, ...externalUsers]
      const filteredUsers = allUsers.filter(
        user =>
          !selectedUsers.some(selectedUser => selectedUser.email === user.email)
      )
      setUsers(filteredUsers)
    }
  }, [internalUsers, externalUsers, selectedUsers])

  return (
    <Dialog
      open={open}
      handler={handler}
      size="lg"
      className="flex flex-col h-[65%]"
    >
      <DialogHeader>
        <BellAlertIcon className="w-10 m-2" />
        Audit Subscribers
        <div onClick={() => handler()} className="absolute right-10">
          <XMarkIcon className="w-7 cursor-pointer" onClick={handler} />
        </div>
      </DialogHeader>
      <DialogBody className="flex flex-col px-10">
        <div className="flex flex-col h-full">
          <div className="text-sm font-light mb-2">
            Subscribers will receive email notifications regarding audit
            generation, item rejection, requests for more information and audit
            finalisation.
          </div>

          <div className="flex relative flex-wrap z-[5000] max-h-[150px] w-[100%]">
            <div className="flex w-full flex-wrap h-[125px] overflow-y-auto border rounded-lg mb-2">
              {selectedUsers.length > 0 ? (
                <div className="flex flex-wrap">
                  {selectedUsers.map((user: User, idx: number) => {
                    return (
                      <div key={idx}>
                        <div className="flex flex-row bg-primary-surface-1 rounded-full px-2 pr-4 py-2 text-sm font-semibold m-2">
                          <Avatar
                            src={user.picture}
                            alt="avatar"
                            variant="circular"
                            className="row-span-2 w-8 h-8 self-center"
                          />
                          <div className="flex flex-col ml-1">
                            <div>{user.name}</div>
                            <div className="text-xs font-light">
                              {user.email}
                            </div>
                          </div>
                          <div className="flex flex-col ml-1 justify-center">
                            {showActions && (
                              <XMarkIcon
                                className="w-5 cursor-pointer hover:text-primary-main"
                                onClick={() => removeSubscriber(idx)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className="flex justify-center w-full h-full items-center">
                  No Subscribers
                </div>
              )}
            </div>
          </div>
          {showActions && (
            <div className="z-[9999]">
              {isArray(users) && (
                <OptionSearch
                  availableOptions={
                    users.map((user: User) => {
                      return {
                        actionIcon: null,
                        label: user.name ?? user.email ?? '',
                        value: user.user_id,
                        disabledHint: '',
                        displayhint: false,
                      }
                    }) ?? [
                      {
                        actionIcon: null,
                        label: 'No Options available',
                        value: '',
                        disabledHint: 'No Options available',
                        displayhint: false,
                      },
                    ]
                  }
                  searchParam={'user'}
                  handleChange={onSelect}
                  showOptions={showOptions}
                />
              )}
            </div>
          )}
        </div>
      </DialogBody>
      <DialogFooter className="pr-10">
        {showActions && (
          <>
            <Button color="secondary" className="mx-2" onClick={handler}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="mx-2 px-10"
              onClick={() => confirmHandler(selectedUsers)}
            >
              Save
            </Button>
          </>
        )}
      </DialogFooter>
    </Dialog>
  )
}

export default EditSubsDialog
