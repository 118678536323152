import React, { useContext, useEffect, useState } from 'react'

import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFacility from '@components/filters/filter-facility'
import BaseLayout from '@components/layouts/base-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'

import UnderwritingAudit from './underwriting'
const UnderwritingAuditL3 = () => {
  const { company, activeFilters, setAppliedFilters, appliedFilters } =
    useContext(AuthContext)

  const { activeFacilityId } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && activeFacilityId !== undefined) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters, activeFacilityId])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = appliedFilters.activeFacilityId === 1

  return (
    <BaseLayout
      title="Underwriting Audit"
      filters={{
        left: (
          <FilterCurrency
            hideSelect
            dateAsOfType={filterDateAsOfType.bankData}
            displayDateAsOf={false}
          />
        ),
        middle: <FilterFacility />,
      }}
      disableReset={disableReset}
    >
      <UnderwritingAudit />
    </BaseLayout>
  )
}

export default UnderwritingAuditL3
