import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFacility from '@components/filters/filter-facility'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { Facility } from '@interfaces/facility'

import PaymentSchedules from './payment-schedule'

const CashflowsL3 = () => {
  const {
    company,
    activeFilters,
    optionFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)

  const { activeFacilityId } = activeFilters
  const { activeFacilityId: appliedActiveFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.[appliedActiveFacilityId]

  const isMasterFacility = activeFacility?.is_master_facility

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && activeFacilityId !== undefined) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  /**
   * observe reset button disable state criteria
   */
  const disableReset = appliedActiveFacilityId === facilities?.[0]?.facility_id

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  const tabs = (facility?: Facility) => [
    {
      label: 'Payment Schedule',
      component: <PaymentSchedules facility={facility} />,
    },
  ]

  const masterFacilityTabs = isMasterFacility
    ? facilities
        .filter((f: Facility) => f.slug_name)
        .map((f: Facility) => ({
          label: f.facility_name,
          component: (
            <L3TabsLayout
              title="Cashflows"
              disableReset={disableReset}
              tabs={tabs(f)}
            />
          ),
        }))
    : []
  masterFacilityTabs.unshift({
    label: 'All',
    component: (
      <L3TabsLayout
        title="Cashflows"
        disableReset={disableReset}
        tabs={tabs(activeFacility)}
      />
    ),
  })

  return (
    <L3TabsLayout
      title="Cashflows"
      filters={{
        left: (
          <FilterCurrency
            hideSelect
            dateAsOfType={filterDateAsOfType.bankData}
          />
        ),
        middle: <FilterFacility />,
      }}
      disableReset={disableReset}
      tabs={isMasterFacility ? masterFacilityTabs : tabs()}
    />
  )
}
export default CashflowsL3
