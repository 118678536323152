import { useContext, useEffect, useState } from 'react'
import { Moment } from 'moment'

import AuthContext from '@contexts/auth'

export const useSpecialtyCapitalWaterfallCustomData = (
  effectiveDate: Moment
) => {
  const { appliedFilters, company } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const [data, setData] = useState<any>([true, {}])

  const fileName = `${Date.now()}_specialty_capital_remittance_report.xlsx`

  useEffect(() => {
    setData([
      false,
      {
        excelUploadCategory: 'Remittance Report',
        excelUploadTitle: `Remittance Report - ${effectiveDate.format(
          'Do MMMM YYYY'
        )}`,
        fileUrl: `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacilityId}&tab=document-centre&fileName=${fileName}`,
      },
    ])
  }, [effectiveDate])

  return data
}
