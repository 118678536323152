import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

import Button from '@components/atoms/button'
import AuthContext from '@contexts/auth'
import {
  BookmarkIcon,
  ChevronRightIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Tooltip,
} from '@material-tailwind/react'

import MonitorDonutChartItem from './items/donut-chart'
import MonitorDashboardGroupedGraphItem from './items/grouped-graph'
import MonitorDashboardHorizontalColumnGraphItem from './items/horizontal-column-graph'
import MonitorDashboardMultilineGraphItem from './items/multiline-graph'
import MonitorDashboardNumberTableItem from './items/number-table'
import MonitorDashboardStackedAreaItem from './items/stacked-area'
import MonitorDashboardStackedGraphItem from './items/stacked-graph'
import MonitorDashboardTableItem from './items/table'
import CovenantL4 from './covenant'
import { getInitDashboardLayout, saveDashboardLayoutToLs } from './helper'

import 'react-grid-layout/css/styles.css'
import './style.css'

const ResponsiveGridLayout = WidthProvider(Responsive)

const MonitorDashboardTabL4 = ({ items }: { items: DashboardResponse[] }) => {
  const COLS_NUMBER = 6 //in grid units
  const ITEM_HEIGHT = 4 //in grid units

  const { appliedFilters, company } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const key = `${company?.slug_name}_${activeFacilityId}_${items[0].tab}`

  const _renderItem = (i: DashboardResponse) => {
    switch (i.display) {
      case 'multiline_graph':
        return <MonitorDashboardMultilineGraphItem item={i} />
      case 'total_stacked_graph':
      case 'stacked_graph':
        return <MonitorDashboardStackedGraphItem item={i} />
      case 'grouped_graph':
        return <MonitorDashboardGroupedGraphItem item={i} />
      case 'donut_chart':
        return <MonitorDonutChartItem item={i} />
      case 'stacked_area':
      case 'total_stacked_area':
      case 'line_graph':
        return <MonitorDashboardStackedAreaItem item={i} />
      case 'covenant':
        return <CovenantL4 type={i.display_type as any} />
      case 'number_table':
        return <MonitorDashboardNumberTableItem item={i} />
      case 'table':
        return <MonitorDashboardTableItem item={i} />
      case 'horizontal_column_graph':
        return <MonitorDashboardHorizontalColumnGraphItem item={i} />
      case 'combo_series':
        return <MonitorDashboardGroupedGraphItem item={i} />
      default:
        return <></>
    }
  }

  const sortedItems = useMemo(
    () => items.sort((a, b) => a.dashboard_id - b.dashboard_id),
    [items]
  )
  // Set up initial layout
  const generateInitialLayout = (breakpoint: string) => {
    return sortedItems.map((item: DashboardResponse, idx: number) => {
      return {
        i: key + item.dashboard_id,
        x: breakpoint === 'md' ? idx % COLS_NUMBER : 0,
        y:
          breakpoint === 'md'
            ? Math.floor(idx / COLS_NUMBER) * ITEM_HEIGHT
            : idx * ITEM_HEIGHT,
        w: breakpoint === 'md' ? COLS_NUMBER : 1,
        h: ['number_table', 'table'].includes(item.display) ? 2 : ITEM_HEIGHT,
        minH: ['number_table', 'table'].includes(item.display)
          ? 2
          : ITEM_HEIGHT,
        minW: 2,
        isDraggable: isEditing,
        isResizable: isEditing,
      }
    })
  }
  const [layouts, setLayouts] = useState<any>({})

  // Save layout to local storage when chosen to save
  //TODO - save to DB
  const saveLayoutToLs = () => {
    setIsEditing(false)
    saveDashboardLayoutToLs(key, {
      md: layouts.md.map((item: any) => ({
        ...item,
        isDraggable: false,
        isResizable: false,
      })),
      sm: layouts.sm.map((item: any) => ({
        ...item,
        isDraggable: false,
        isResizable: false,
      })),
    })
  }

  // Update state when layout changes in editing modfe
  const onLayoutChange = (currentLayout: any, allLayouts: any) => {
    setLayouts(allLayouts)
  }

  // Activate editing/resizing
  useEffect(() => {
    if (layouts.md?.length || layouts.sm?.length) {
      setLayouts({
        md: layouts.md.map((item: any) => ({
          ...item,
          isDraggable: isEditing,
          isResizable: isEditing,
        })),
        sm: layouts.sm.map((item: any) => ({
          ...item,
          isDraggable: isEditing,
          isResizable: isEditing,
        })),
      })
    }
  }, [isEditing])

  useEffect(() => {
    const lsItem = getInitDashboardLayout()

    const initialLayoutFromLs = lsItem[key]

    if (!!initialLayoutFromLs) {
      setLayouts(initialLayoutFromLs)
    } else {
      setLayouts({
        md: generateInitialLayout('md'),
        sm: generateInitialLayout('sm'),
      })
    }
    //gives dashboards time to load as well as user breathing space after navigating to dashboards page
    Object.keys(lsItem).length == 0 &&
      setTimeout(() => {
        setIsDialogOpen(true)
      }, 5000)

    return () => {
      setLayouts({
        md: [],
        sm: [],
      })
    }
  }, [activeFacilityId])

  return (
    <div>
      <div className="w-full flex justify-end">
        {!isEditing && (
          <Tooltip
            content={
              <div>
                <div>Edit Layout</div>
              </div>
            }
            placement="left-start"
          >
            <div>
              <Button onClick={() => setIsEditing(true)}>
                <PencilIcon className="w-4" />
              </Button>
            </div>
          </Tooltip>
        )}
        {isEditing && (
          <Tooltip content={<div>Save Layout</div>} placement="left-start">
            <div>
              <Button onClick={saveLayoutToLs}>
                <BookmarkIcon className="w-4" />
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      <ResponsiveGridLayout
        className="layout mb-20"
        layouts={layouts}
        breakpoints={{ md: 768, sm: 480 }}
        cols={{ md: COLS_NUMBER, sm: 1 }}
        onLayoutChange={onLayoutChange}
      >
        {sortedItems.map((i: DashboardResponse) => {
          return (
            <div
              key={key + i.dashboard_id}
              className={`px-16 py-8 ${
                isEditing && `border rounded-lg shadow-lg cursor-move`
              } `}
            >
              {_renderItem(i)}
            </div>
          )
        })}
      </ResponsiveGridLayout>
      <Dialog
        open={isDialogOpen}
        handler={() => setIsDialogOpen(!isDialogOpen)}
        size="xl"
      >
        <DialogHeader>
          <div>{'Did you know?'}</div>
        </DialogHeader>
        <DialogBody className="text-sm">
          <div className="flex flex-col px-5">
            <div className="my-2">
              You can resize and reorganize dashboards to better suit your data
              viewing preferences.
            </div>
            <div className="w-full flex justify-center">
              <img
                src="/gifs/resize-demo.gif"
                className="flex border rounded-lg shadow-md w-40 md:w-80"
              />
            </div>
            <div className="my-2 inline">
              To enable editing, click the pencil icon.{' '}
              <PencilIcon className="w-8 p-1 border rounded-lg inline mx-2" />
              Resize items using the resizer
              <ChevronRightIcon className="w-6 inline mx-1 rotate-45 stroke-[#FF7E35]" />
              at the bottom-right corner of each dashboard and drag and drop to
              reposition them. When finished, click the save button at the
              top-right corner of the page to preserve your configuration.{' '}
              <BookmarkIcon className="w-8 p-1 border rounded-lg inline ml-2" />
            </div>
            <div className="my-2 font-bold">
              Note: Configurations are saved in your browser. Clearing the
              browser cache or using a different browser/device may reset the
              layout.
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={() => {
              setIsDialogOpen(!isDialogOpen)
              saveLayoutToLs()
            }}
          >
            Got it
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default MonitorDashboardTabL4
