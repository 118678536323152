import { MathExpressionEvaluator } from '@helpers/math'
import { WaterfallDetailResponse } from '@interfaces/manage-monitor-waterfall'

import { FX_DEFINED_TERM } from './constants'

export const equationParser = (
  equation: string,
  details: WaterfallDetailResponse[]
): number => {
  const fxRate = Number(
    details.find(d => d.defined_term === FX_DEFINED_TERM)
      ?.cascade_calculation || 1
  )
  const tableDetails = details.filter(d => d.defined_term !== FX_DEFINED_TERM)
  const equationParts = equation.split(':')
  for (let i = 0; i < equationParts.length; i++) {
    const res = parseInt(equationParts[i], 10)
    if (!isNaN(res)) {
      const detail = tableDetails.find(d => d.priority === res)
      const casc_calc =
        detail?.cascade_calculation && detail?.operation?.includes('div')
          ? detail?.cascade_calculation / fxRate
          : detail?.cascade_calculation
      const value = detail?.value ?? detail?.input_value ?? casc_calc ?? 0
      equationParts[i] = value.toString()
    }
  }
  const rebuiltEquation = equationParts.join('')
  return new MathExpressionEvaluator().evaluate(rebuiltEquation)
}
