import React from 'react'

import Typography from '@components/atoms/typography'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import {
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'

import { useCovenantDetail } from '../helper'

import CovenantDetailRegular from './regular'
import CovenantDetailTable from './table'
import CovenantDetailVintage from './vintage'
import CovenantDetailVintageTableOnly from './vintage-table-only'

export interface CovenantDetailProps {
  list_item: CovenantListResponse
  type: CovenantType
}
const CovenantDetail = ({ list_item, type }: CovenantDetailProps) => {
  const { data, isLoading } = useCovenantDetail({
    id: list_item.covenant_id,
    type,
  })

  const _chartRenderer = () => {
    switch (data?.params.special_covenent) {
      case 'Vintage':
        return (
          <CovenantDetailVintage
            type={type}
            covenant={data}
            summary={list_item}
          />
        )
      case 'Vintage Table Only':
        return (
          <CovenantDetailVintageTableOnly
            type={type}
            covenant={data}
            summary={list_item}
          />
        )
      case 'Table':
        return (
          <CovenantDetailTable
            type={type}
            covenant={data}
            summary={list_item}
          />
        )
      default:
        return (
          <CovenantDetailRegular
            list_item={list_item}
            type={type}
            covenant={data}
          />
        )
    }
  }

  return isLoading ? (
    <div className="flex justify-center gap-2">
      <ArrowPathIcon className="animate-spin text-primary-main w-4" />
      <Typography className="text-center">Loading</Typography>
    </div>
  ) : (
    _chartRenderer()
  )
}

export default CovenantDetail
