import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { formatDownloadURL } from '@helpers/downloadURL-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { FacilityDetailsService } from '@services/api-manage/facility-details'

import { getDates } from '../helper'

export const useCoignComplianceCertData = () => {
  const { optionFilters, appliedFilters, company } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities } = optionFilters
  const activeFacility = facilities.find(
    (facility: Facility) => facility.facility_id === activeFacilityId
  )
  const [data, setData] = useState<any>([true, {}])
  const fileName = `${moment().unix()}_compliance_certificate_covenants.xlsx`

  const filters = {
    facility_id: activeFacilityId,
    facility: activeFacility.facility_name,
    slug_name: company?.slug_name ?? '',
  }

  const { data: partyDetailsData, isFetching: isFetchingPartyDetails } =
    useQuery(
      ['getPartyDetails', filters],
      () => {
        return FacilityDetailsService.getDealPartyDetailsRequest(filters)
      },
      getStaleMins()
    )

  useEffect(() => {
    const bankingInfo = partyDetailsData
      ? JSON.parse(
          partyDetailsData.find(entry => entry.type === 'Banking')
            ?.supplementary ?? '{}'
        )
      : {}
    setData([
      isFetchingPartyDetails ?? false,
      {
        fileUrl: formatDownloadURL(
          `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacilityId}&tab=document-centre&fileName=${fileName}`,
          78
        ),
        bankingInfo,
        effectiveDateOptions: getDates('2024-04-12')?.monthly,
      },
    ])
  }, [partyDetailsData])

  return data
}
