import React from 'react'

import Table from '@components/table'

import { is_number, is_percentage } from '../helper'

const DetailTable = ({ data }: { data: any[] }) => {
  const table_column: any[] = Object.keys(data?.[0] ?? {}).reduce(
    (_p: any[], d: string) => {
      const is_right_aligned =
        is_percentage(data?.[0][d]) || is_number(data?.[0][d])

      return [
        ..._p,
        {
          title: d.replaceAll('_', ' '),
          field: d,
          align: is_right_aligned ? 'right' : 'center',
          head: { align: 'center' },
        },
      ]
    },
    []
  )
  const table_data = data
  return (
    <Table
      containerClass="[&>table>thead>tr>th]:capitalize [&>table>tbody>tr:last-child>td]:font-semibold"
      columns={table_column}
      data={table_data}
    />
  )
}
export default DetailTable
