import React from 'react'
import moment from 'moment'

import { CovenantRawResponse } from '@interfaces/manage-monitor-covenant'
import { TemplateProps } from '@interfaces/manage-reporting'
import { Document, Link, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../components'
import { styles } from '../../../template_style'

import { styles as obStyles } from './style'

export const OrangeBankQuarterlyPaymentDemandTemplate = ({
  agreementDate,
  formData,
  customData,
}: TemplateProps) => {
  const covData = customData?.covenantData?.find(
    (a: CovenantRawResponse) =>
      moment.utc(a.cohort_date).format('YYYY-MM-DD') ===
      formData['effective-date']
  )

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={obStyles.title}>FORM OF PAYMENT DEMAND</Text>
        <Text style={{ ...obStyles.text, textIndent: 300 }}>
          Date: {moment().format('DD MMMM, YYYY')}
        </Text>
        <Text style={obStyles.text}>International Finance Corporation</Text>
        <Text style={obStyles.text}>2121 Pennsylvania Avenue, N.W.</Text>
        <Text style={obStyles.text}>Washington, D.C. 20433</Text>
        <Text style={obStyles.text}>U. S. A.</Text>
        <Text style={obStyles.text}>
          Attention: Director, Financial Institution Group
        </Text>
        <Text style={obStyles.textBold}>
          I.{' '}
          <Text style={{ ...obStyles.textBold, textDecoration: 'underline' }}>
            Agreement
          </Text>
        </Text>
        <Text style={obStyles.text}>
          Orange Bank Africa (the &quot;Beneficiary&quot;) refers to the Risk
          Sharing Agreement dated{' '}
          {moment.utc(agreementDate).format('DD MMMM, YYYY')} (the
          &quot;Agreement&quot;) between the Beneficiary and IFC, and to the
          Final Quarterly Report dated {moment().format('DD MMMM, YYYY')} in
          respect of the Quarterly Reporting Period ending on{' '}
          {moment(formData['effective-date']).format('DD MMMM, YYYY')}. This is
          a Payment Demand. Capitalized terms have the meanings given to them in
          the Agreement.
        </Text>
        <View style={obStyles.containerCenteredContent}>
          <Text style={obStyles.textBold}>
            II.{' '}
            <Text
              style={{
                ...obStyles.textBold,
                textDecoration: 'underline',
                width: 'fit-content',
              }}
            >
              Representations
            </Text>
          </Text>
        </View>
        <Text style={{ ...obStyles.textIndented }}>
          The following MSME Facility(ies) has/have become (a) Defaulted MSME
          Facility(ies):
        </Text>
        <View style={{ ...obStyles.containerView, paddingLeft: 36 }}>
          <Link src={customData.fileUrl} style={obStyles.textIndented}>
            {customData.fileUrl}
          </Link>
        </View>
        <View style={obStyles.containerCenteredContent}>
          <Text style={obStyles.textBold}>
            III.{' '}
            <Text style={{ ...obStyles.textBold, textDecoration: 'underline' }}>
              Payment Demand Amount
            </Text>
          </Text>
        </View>
        <Text style={obStyles.textIndented}>
          The Beneficiary demands an IFC Payment Amount equal to the Dollar
          Equivalent of 0, which Local Currency amount equals:
        </Text>
        <Text style={{ ...obStyles.text, marginTop: '16px' }}>XOF:</Text>
        <View style={obStyles.tableWrapper}>
          <View wrap={false} style={obStyles.tableView}>
            <View style={obStyles.table}>
              <View style={obStyles.tableBody}>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <View
                      style={{
                        ...obStyles.containerView,
                        width: '100%',
                        paddingLeft: 10,
                      }}
                    >
                      <Text style={{ ...obStyles.tableCell, width: 20 }}>
                        (a)
                      </Text>
                      <Text style={obStyles.text}>
                        the IFC Percentage of the Aggregate Net Loss Amount:{' '}
                        <Text style={obStyles.textUnderline}>minus</Text>
                      </Text>
                    </View>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      50% * {covData?.charge_off_amount}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...obStyles.tableCol,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Text style={obStyles.tableCell}>
                      {covData?.charge_off_amount_paidback}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <View
                      style={{
                        ...obStyles.containerView,
                        width: '100%',
                        paddingLeft: 10,
                      }}
                    >
                      <Text style={{ ...obStyles.tableCell, width: 20 }}>
                        (b)
                      </Text>
                      <Text style={obStyles.text}>
                        the Beneficiary’s Outstanding IFC Reimbursement Amount;
                      </Text>
                    </View>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>[xof]</Text>
                  </View>
                  <View
                    style={{
                      ...obStyles.tableCol,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Text style={obStyles.tableCell}>
                      {covData?.previous_current_balance}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>Total</Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell} />
                  </View>
                  <View
                    style={{
                      ...obStyles.tableCol,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Text style={obStyles.tableCell}>
                      {covData?.current_balance}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style={obStyles.text}>
          in each case determined as of the Quarterly Determination Date.
        </Text>
        <View style={{ ...obStyles.underlineView, marginTop: '64px' }} />
      </Page>
      <Page style={styles.body}>
        <Text style={obStyles.text}>
          You are requested to pay such amount to the following account:
        </Text>
        <View style={obStyles.tableWrapper}>
          <View wrap={false} style={obStyles.tableView}>
            <View style={obStyles.table}>
              <View style={obStyles.tableBody}>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>Intermediary Bank</Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['intermediary-bank'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>Bank Address</Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['bank-address'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>SWIFT</Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['swift'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell} />
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}> </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>Recipient Name</Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['recipient-name'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>Recipient Address</Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['recipient-address'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>Recipient Bank Name</Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['recipient-bank-name'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      Recipient Bank Account Number
                    </Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['recipient-bank-account-number'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      Recipient SWIFT Number
                    </Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['recipient-swift-number'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
                <View style={obStyles.tableRow}>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      Recipient Bank Address
                    </Text>
                  </View>
                  <View style={obStyles.tableCol}>
                    <Text style={obStyles.tableCell}>
                      {formData['recipient-bank-address'] ?? '[*]'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style={obStyles.textBold}>
          IV.{' '}
          <Text style={{ ...obStyles.textBold, textDecoration: 'underline' }}>
            Representations
          </Text>
        </Text>
        <View style={{ marginLeft: '38px' }}>
          <Text style={obStyles.text}>
            The Beneficiary represents to IFC that:
          </Text>
        </View>
        <View style={{ ...obStyles.containerView, paddingLeft: 38 }}>
          <Text style={{ ...obStyles.text, width: '20px' }}>(i)</Text>
          <Text style={obStyles.text}>
            each of the SME Facilities set forth in Section II (Defaulted MSME
            Facilities) above, as well as each Defaulted MSME Facility set forth
            in each previous Final Monthly Report and Final Quarterly Report is
            (including all amendments and waivers relating to such MSME
            Facility) both an Eligible SME Facility and meets the requirements
            for a Defaulted SME Facility;
          </Text>
        </View>
        <View style={{ ...obStyles.containerView, paddingLeft: 38 }}>
          <Text style={{ ...obStyles.text, width: '20px' }}>(ii)</Text>
          <Text style={obStyles.text}>
            each of the representations set out at Section 3.02 (IFC Policies;
            E&S Management System) of the Agreement is true and accurate as of
            the date of this Payment Demand; and
          </Text>
        </View>
        <View style={{ ...obStyles.containerView, paddingLeft: 38 }}>
          <Text style={{ ...obStyles.text, width: '20px' }}>(iii)</Text>
          <Text style={obStyles.text}>
            it has complied with and is not in breach of any of its obligations
            under the Agreement, including such obligations set forth in Article
            IV (Particular Covenants).
          </Text>
        </View>
        <View style={obStyles.containerCenteredContent}>
          <Text style={obStyles.textBold}>
            V.{' '}
            <Text style={{ ...obStyles.textBold, textDecoration: 'underline' }}>
              Deliverables
            </Text>
          </Text>
        </View>
        <View style={{ marginLeft: '38px' }}>
          <Text style={obStyles.text}>
            The Beneficiary encloses with this Payment Demand:
          </Text>
        </View>
        <View style={{ ...obStyles.containerView, paddingLeft: 38 }}>
          <Text style={{ ...obStyles.text, width: '20px' }}>(i)</Text>
          <Text style={obStyles.text}>
            the list of Defaulted MSME Facilities reported to BIC over the
            Quarterly Reporting Period;
          </Text>
        </View>
        <View style={{ ...obStyles.containerView, paddingLeft: 38 }}>
          <Text style={{ ...obStyles.text, width: '20px' }}>(ii)</Text>
          <Text style={obStyles.text}>
            the following evidence that it has initiated all appropriate
            collection proceedings in order to enforce the collection of all
            amounts outstanding under each of the Defaulted MSME Facilities
            listed above including 90 DPD; 181DPD logs of SMS;
          </Text>
        </View>
        <View style={{ ...obStyles.containerView, paddingLeft: 38 }}>
          <Text style={{ ...obStyles.text, width: '20px' }}>(iii)</Text>
          <Text style={obStyles.text}>
            Use of funds: evidence that any Eligible MSME Facility granted is
            declared on Tik Tak at onboarding for the sectors of activity listed
            in Annex 1 hereof on a declarative basis for the purpose of
            determining an Eligible MSME Facility and, when applicable, is
            declared on Tik Tak for
          </Text>
        </View>
        <View wrap={false}>
          <Text style={obStyles.text}>Orange Bank Africa</Text>
          <View style={{ ...obStyles.containerView, alignItems: 'flex-end' }}>
            <Text style={{ ...obStyles.text, minWidth: '42px' }}>By:</Text>
            <View style={obStyles.underlineView}>
              <Text style={styles.signTags}>[sig|req|signer1|signature]</Text>
            </View>
          </View>
          <View style={{ ...obStyles.containerView, alignItems: 'flex-end' }}>
            <Text style={{ ...obStyles.text, minWidth: '42px' }}>Name:</Text>
            <View style={obStyles.underlineView}>
              <Text style={obStyles.text}>
                {formData?.signers?.[0]?.name || `[Authorized Officer Name]`}
              </Text>
            </View>
          </View>
          <View style={{ ...obStyles.containerView, alignItems: 'flex-end' }}>
            <Text style={{ ...obStyles.text, minWidth: '42px' }}>Title:</Text>
            <View style={obStyles.underlineView}>
              <Text style={obStyles.text}>
                {formData?.signers?.[0]?.title || `[Authorized Officer Title]`}
              </Text>
            </View>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}
