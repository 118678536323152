import React from 'react'

import { AuditReportSummary } from '@interfaces/manage-audit'
import {
  Footer,
  Table,
} from '@pages/manage/monitor/actions/document-centre/templates/components'
import { Document, Image, Page, Text } from '@react-pdf/renderer'

import {
  formatAuditCoverData,
  formatCashExecSummaryData,
  formatCashPrevalanceData,
} from '../helpers/data-formatting'

import { styles } from './style'

interface ReportProps {
  data: AuditReportSummary
}

const CashAuditReportStartTemplate = ({ data }: ReportProps) => {
  const auditCoverData = formatAuditCoverData(data.audit_summary)
  const ExecSummaryData = formatCashExecSummaryData(data.item_data)
  const prevalanceData = formatCashPrevalanceData(data.prevalance_data)

  return (
    <Document>
      <Page style={styles.body}>
        <Image style={styles.image} src="/cascade-long-logo.png" />
        <Text style={styles.coverTitle}>Payment Audit</Text>
        <Table data={auditCoverData} />
      </Page>
      <Page style={styles.body}>
        <Text style={styles.sectionTitle}>Executive Summary</Text>
        <Table data={ExecSummaryData} tight={true} />
      </Page>
      <Page style={styles.body}>
        <Text style={styles.sectionTitle}>Prevalence of Payment Flags</Text>
        <Table data={prevalanceData} />

        <Text style={styles.sectionTitle}>
          Methodology: Oversample Suspicious Payments
        </Text>

        <Text style={styles.text}>
          Cascade promotes a methodology that oversamples suspicious payments so
          that the tested sample is targeted at suspicious payments. Alongside
          the oversample of suspicious payments, there is also a random sample
          included as well. Oversampling suspicious payments in a cash audit can
          be valuable for several reasons:
        </Text>
        <Text style={styles.text}>
          {`1. Identifying fraud and irregularities: Suspicious payments are more likely to be associated with fraudulent activities or irregular financial transactions. By oversampling these payments, Cascade increases the likelihood of detecting such anomalies.`}
        </Text>
        <Text style={styles.text}>
          {`2. Risk mitigation: Oversampling helps Cascade reduce the risk of overlooking potentially problematic payments. It ensures a more comprehensive review of transactions, which can uncover issues that might have been missed in a smaller sample. `}
        </Text>
        <Text
          style={styles.text}
        >{`3. Improved accuracy: By focusing on suspicious payments, Cascade can enhance the precision of the audit process. This can lead to more accurate findings, which is essential for providing a reliable assessment of the organization's financial records. `}</Text>
        <Text
          style={styles.text}
        >{`4. Deterrence and prevention: The knowledge that suspicious payments are being closely scrutinized can act as a deterrent to fraudulent behavior within an organization. This can help prevent future instances of fraud or irregularities. `}</Text>
        <Text
          style={styles.text}
        >{`5. Demonstrating due diligence: By actively targeting and investigating suspicious payments, Cascade demonstrates due diligence in the audit process. This can be important in situations where stakeholders, such as investors or regulators, are assessing the quality of the audit.`}</Text>

        <Text style={styles.text}>
          {`It's important to note that while oversampling suspicious payments can be valuable, Cascade also randomly samples payments to ensure a comprehensive and effective examination of an organization's loan tape. Additionally, maintaining the confidentiality and security of the audit process is crucial to avoid tipping off potential wrongdoers. For this reason Cascade does not publish specific rules that are used in detecting suspicious payments.`}
        </Text>
      </Page>
      <Page style={styles.body}>
        <Text style={styles.text}>
          For each tested sample, a total of {data.audit_summary.sample_size}{' '}
          payments are flagged for review. If the population is not large enough
          to fill the tested sample in any bucket, the sample will increase in
          the random sample tested data set. The payments that are sampled are
          broken down into the following categories:
        </Text>
        <Text style={styles.subtitle}>Tier 0 Sample: Random Sample</Text>
        <Text style={styles.text}>
          Creating a random sample in a cash audit is an essential step to
          ensure that the audit is representative of the financial transactions
          and minimizes bias. The goal is to select a sample of transactions
          that is both random and statistically significant.
        </Text>
        <Text style={styles.subtitle}>Tier 1 Sample : Delinquency</Text>
        <Text style={styles.text}>
          Tier 1 samples include payments from borrowers where there was a
          delinquency and then a lump sum payment. This population is
          oversampled as it will drive down overall delinquency data of a
          platform.
        </Text>
        <Text style={styles.subtitle}>Tier 2 Sample: Frequency</Text>
        <Text style={styles.text}>
          Tier 2 samples include payments from borrowers where there is an
          unusual frequency of payments in relationship to the expected schedule
          of payments. This population is oversampled as it could represent
          double counting of entries or irregular behaviors.
        </Text>
        <Text style={styles.subtitle}>Tier 3 Sample: Disbursed</Text>
        <Text style={styles.text}>
          Tier 3 samples include payments from disbursements (outflows) where
          the disbursement was flagged. Disbursements that are flagged based on
          multiple factors but are either loans that could help support growth
          numbers of a platform or loans that might have not followed the
          process.
        </Text>

        <Text style={styles.subtitle}>Tier 4 Sample: Benford’s Law</Text>
        <Text style={styles.text}>
          {`Benford’s law describes the relative frequency distribution for leading digits of numbers in datasets. Leading digits with smaller values occur more frequently than larger values. This law states that approximately 30% of numbers start with a 1 while less than 5% start with a 9. According to this law, leading 1s appear 6.5 times as often as leading 9s. Benford’s law is also known as the First Digit Law. Analysts have used it extensively to look for fraud and manipulation in financial records, tax returns, applications, and decision-making documents. They compare the distribution of leading digits in these datasets to Benford’s law. When the leading digits don’t follow the distribution, it’s a red flag for fraud in some datasets.`}
        </Text>

        <Text style={styles.subtitle}>Tier 5 Sample: Multiple Flags</Text>
        <Text style={styles.text}>
          Any payment that is flagged from 2 or more tiers.
        </Text>

        <Footer />
      </Page>
    </Document>
  )
}

export default CashAuditReportStartTemplate
