export const bankingTableRows = [
  {
    title: 'Intermediary Bank',
    key: 'intermediaryBank',
    manualEntryKey: 'intermediary-bank',
  },
  {
    title: 'Bank Address',
    key: 'intermediaryBankAddress',
    manualEntryKey: 'bank-address',
  },
  {
    title: 'SWIFT',
    key: 'intermediarySwift',
    manualEntryKey: 'swift',
  },
  {
    title: 'Recipient Name',
    key: 'recipientName',
    manualEntryKey: 'recipient-name',
  },
  {
    title: 'Recipient Address',
    key: 'recipientAddress',
    manualEntryKey: 'recipient-address',
  },
  {
    title: 'Recipient Bank Name',
    key: 'recipientBankName',
    manualEntryKey: 'recipient-bank-name',
  },
  {
    title: 'Recipient Bank Account Number',
    key: 'recipientBankAccountNumber',
    manualEntryKey: 'recipient-bank-account-number',
  },
  {
    title: 'Recipient SWIFT Number',
    key: 'recipientSwiftNumber',
    manualEntryKey: 'recipient-swift-number',
  },
  {
    title: 'Recipient Bank Address',
    key: 'recipientBankAddress',
    manualEntryKey: 'recipient-bank-address',
  },
  {
    title: 'Beneficiary Bank Name',
    key: 'beneficiaryBankName',
  },
  {
    title: 'Beneficiary Bank Address',
    key: 'beneficiaryBankAddress',
  },
  {
    title: 'Beneficiary SWIFT Number',
    key: 'beneficiarySwift',
  },
  {
    title: 'Beneficiary Bank Account Number',
    key: 'aac',
    manualEntryKey: 'beneficiary-bank-account-number',
  },
  {
    title: 'ABA Routing Number',
    key: 'aba',
  },
  {
    title: 'Wire Routing Number',
    key: 'wireRoutingNumber',
  },
  {
    title: 'ACH Routing Number',
    key: 'achRoutingNumber',
  },
]
