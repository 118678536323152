import React, { useContext, useState } from 'react'
import moment from 'moment'
import { useMutation } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth0, User } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { numberFormatter } from '@helpers/number-formatter'
import {
  ArrowPathIcon,
  BellAlertIcon,
  DocumentArrowDownIcon,
  DocumentCheckIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import { Tooltip } from '@material-tailwind/react'
import { AuditService } from '@services/api-manage/audit'
import DocumentService from '@services/api-manage/docs'

import EditSubsDialog from '../dialogs/edit-subscribers/edit-subscriber-dialog'
import ConfirmDialog from '../dialogs/finalise/finalise-dialog'
import GenerateReportDialog from '../dialogs/report-generate/generate-report-dialog'
import VoidDialog from '../dialogs/void/void-dialog'
import { mfpa001 } from '../reports/custom/mfs/mf_pa001'

interface SummaryTableProps {
  auditSummary: any
  auditData: any
  refetch: () => any
  access: string
  auditType: 'Payment' | 'Underwriting'
}

export const SummaryTableWithActions = ({
  auditSummary,
  auditData,
  refetch,
  access,
  auditType,
}: SummaryTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
  const [isVoidDialogOpen, setIsVoidDialogOpen] = useState<boolean>(false)

  const [isDownloadDialogOpen, setIsDownloadDialogOpen] =
    useState<boolean>(false)
  const [isSubscriberDialogOpen, setIsSubscriberDialogOpen] =
    useState<boolean>(false)
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities } = optionFilters
  const activeFacility = facilities.find(
    (f: Facility) => f.facility_id == activeFacilityId
  )

  const { user } = useAuth0()
  const filters = {
    slug_name: company?.slug_name,
    type: auditType.toLowerCase(),
    user_id: user?.sub,
    id: auditSummary.audit_id,
    facility_id: activeFacilityId,
  }
  //finalise audit
  const { mutate: finaliseAudit, isLoading: isFinalising } = useMutation(
    () => {
      setIsConfirmDialogOpen(false)
      return AuditService.finaliseAudit(filters)
    },
    {
      onSuccess: () => {
        refetch()
        toast.success('Audit Finalised')
      },
      onError: () => {
        toast.error('An unexpected error occured')
      },
    }
  )

  const { mutate: voidAudit } = useMutation(
    async () => {
      toast.loading('Voiding Audit...')
      setIsVoidDialogOpen(!isVoidDialogOpen)
      return AuditService.voidAudit(filters)
    },
    {
      onSuccess: () => {
        refetch()
        toast.dismiss()
        toast.success('Audit voided sucessfully')
      },
      onError: () => {
        refetch()
        toast.dismiss()
        toast.error('An error occurred while voiding audit')
      },
    }
  )
  const { mutate: editSubscribers } = useMutation(
    async (subs: User[]) => {
      toast.loading('Updating Audit...')
      setIsSubscriberDialogOpen(!isSubscriberDialogOpen)
      return AuditService.updateSubscribers({
        ...filters,
        subscribers: subs.map((su: User) => su.email),
      })
    },
    {
      onSuccess: () => {
        refetch()
        toast.dismiss()
        toast.success('Audit updated sucessfully')
      },
      onError: () => {
        refetch()
        toast.dismiss()
        toast.error('An error occurred while updating audit')
      },
    }
  )

  const { mutate: downloadFile } = useMutation(
    async (docData: any) => {
      toast.loading('Downloading data...')
      return DocumentService.getPresignedUrl(
        JSON.parse(docData.supplementary_data),
        activeFacility?.slug_name ?? company?.slug_name,
        activeFacilityId
      )
    },
    {
      onSuccess: res => {
        toast.dismiss()
        window.open(String(res.data), '_blank')
      },
      onError: () => {
        toast.dismiss()
        toast.error('An error occurred while downloading')
      },
    }
  )

  const columns =
    auditType == 'Payment'
      ? [
          { title: 'Audit Item ID', field: 'item_display_id', align: 'center' },
          {
            title: company?.slug_name == 'mfs' ? 'ID' : 'Payment ID',
            field: company?.slug_name == 'mfs' ? 'id' : 'payment_id',
            align: 'center',
          },
          {
            title: company?.slug_name == 'mfs' ? 'Account Number' : 'Loan ID',
            align: company?.slug_name == 'mfs' ? 'account_number' : 'center',
            field: 'loan_id',
          },
          {
            title: company?.slug_name == 'mfs' ? 'Date' : 'Date of Payment',
            field: company?.slug_name == 'mfs' ? 'date' : 'payment_date',
            align: 'center',
            render: (record: any) => {
              return (
                <div>
                  {moment(
                    company?.slug_name == 'mfs'
                      ? record.date
                      : record.payment_date,
                    'DD-MM-YYYY'
                  ).format('YYYY-MM-DD')}
                </div>
              )
            },
          },
          {
            title: company?.slug_name == 'mfs' ? 'Balance' : 'Total Payment',
            align: 'center',
            field: 'payment_total',
            render: (record: any) => {
              return <div>{numberFormatter(record.payment_total)}</div>
            },
          },
          ,
          {
            title: 'Status',
            align: 'center',
            field: 'status',
          },
          {
            title: 'Actions',
            field: '',
            align: 'center',
            render: (record: any) => (
              <Button
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams)
                  newSearchParams.set('itemId', record.item_id)
                  setSearchParams(newSearchParams.toString())
                }}
              >
                {['FINALISED', 'VOIDED'].includes(
                  auditSummary.audit_status.toUpperCase()
                )
                  ? 'View'
                  : 'Review'}
              </Button>
            ),
          },
        ]
      : [
          { title: 'Audit Item ID', field: 'item_display_id', align: 'center' },
          {
            title: 'Loan ID',
            align: 'center',
            field: 'loan_id',
          },
          {
            title: 'Date Disbursed',
            field: 'disbursed_date',
            align: 'center',
            render: (record: any) => {
              return (
                <div>
                  {moment(record.disbursed_date, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                  )}
                </div>
              )
            },
          },
          {
            title: 'Disbursed Amount',
            align: 'center',
            field: 'disbursed_principal',
            render: (record: any) => {
              return <div>{record.disbursed_principal.toFixed(2)}</div>
            },
          },
          ,
          {
            title: 'Status',
            align: 'center',
            field: 'status',
          },
          {
            title: 'Actions',
            field: '',
            align: 'center',
            render: (record: any) => (
              <Button
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams)
                  newSearchParams.set('itemId', record.item_id)
                  setSearchParams(newSearchParams.toString())
                }}
              >
                {auditSummary.audit_status.toUpperCase() == 'FINALISED'
                  ? 'View'
                  : 'Review'}
              </Button>
            ),
          },
        ]
  return (
    <div>
      <div className="flex justify-end py-3">
        {!!auditSummary.audit_id && access.includes('upload') && (
          <div className="flex flex-row justify-between">
            <Tooltip content={<div>Audit Subscribers</div>} placement="top">
              <div>
                <Button
                  className="mx-2"
                  onClick={() =>
                    setIsSubscriberDialogOpen(!isSubscriberDialogOpen)
                  }
                >
                  <BellAlertIcon className={'w-7'} />
                </Button>
              </div>
            </Tooltip>
            {!['FINALISED', 'VOIDED'].includes(auditSummary.audit_status) &&
              access.includes('upload') && (
                <Tooltip content={<div>Void Audit</div>} placement="top">
                  <div>
                    <Button
                      className="mx-2"
                      onClick={() => setIsVoidDialogOpen(!isVoidDialogOpen)}
                    >
                      <XCircleIcon className={'w-7'} />
                    </Button>
                  </div>
                </Tooltip>
              )}
            <Tooltip content={<div>Refresh</div>} placement="top">
              <div>
                <Button className="mx-2" onClick={() => refetch()}>
                  <ArrowPathIcon className={'w-7'} />
                </Button>
              </div>
            </Tooltip>
          </div>
        )}
        {access.includes('approve/reject') &&
          auditSummary.audit_status_control == 'FINALISATION REQUIRED' &&
          !!auditData.length &&
          !['FINALISED', 'VOIDED'].includes(auditSummary.audit_status) && (
            <Tooltip content={<div>Finalise Audit</div>} placement="top">
              <div>
                <Button
                  disabled={isFinalising}
                  className="relative"
                  onClick={() => setIsConfirmDialogOpen(true)}
                >
                  {!isFinalising && (
                    <div className="animate-ping absolute -top-1 -right-1 w-4 h-4 rounded-full bg-primary-main opacity-75"></div>
                  )}

                  {isFinalising ? (
                    <ArrowPathIcon className="w-7 animate-spin" />
                  ) : (
                    <DocumentCheckIcon className="w-7" />
                  )}
                </Button>
              </div>
            </Tooltip>
          )}

        {auditSummary.audit_status == 'FINALISED' && access.includes('export') && (
          <Tooltip content={<div>Download Report</div>} placement="top">
            <div>
              <Button
                className="mx-2"
                onClick={() =>
                  auditSummary.audit_id != mfpa001.audit_id
                    ? setIsDownloadDialogOpen(true)
                    : downloadFile(mfpa001)
                }
              >
                <DocumentArrowDownIcon className={'w-7'} />
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      <div>
        {!!auditData.length ? (
          <>
            <div className="m-5 flex">
              <Typography className="text-sm font-medium my-1 text-left">
                Following {auditType == 'Payment' ? 'payments' : 'loans'} has
                been flagged for this audit and requires reviewing.
              </Typography>
            </div>
            <Table columns={columns as any} data={auditData} loading={false} />
          </>
        ) : (
          <div className="w-full flex justify-center font-light text-sm">
            No data to display
          </div>
        )}
        {isConfirmDialogOpen && (
          <ConfirmDialog
            open={isConfirmDialogOpen}
            handler={() => setIsConfirmDialogOpen(!isConfirmDialogOpen)}
            confirmHandler={finaliseAudit}
          />
        )}
        {isVoidDialogOpen && (
          <VoidDialog
            open={isVoidDialogOpen}
            handler={() => setIsVoidDialogOpen(!isVoidDialogOpen)}
            confirmHandler={voidAudit}
          />
        )}
        {isDownloadDialogOpen && auditData && (
          <GenerateReportDialog
            open={isDownloadDialogOpen}
            handler={() => setIsDownloadDialogOpen(!isDownloadDialogOpen)}
            auditDisplayId={auditSummary.audit_display_id}
            auditId={auditSummary.audit_id}
            auditType={auditType}
          />
        )}
        {isSubscriberDialogOpen && auditData && (
          <EditSubsDialog
            open={isSubscriberDialogOpen}
            handler={() => setIsSubscriberDialogOpen(!isSubscriberDialogOpen)}
            subscribers={auditSummary.subscribers}
            confirmHandler={editSubscribers}
            showActions={
              !['FINALISED', 'VOIDED'].includes(auditSummary.audit_status)
            }
          />
        )}
      </div>
    </div>
  )
}

export default SummaryTableWithActions
