import React from 'react'
import moment from 'moment'

import { TemplateProps } from '@interfaces/manage-reporting'
import { Document, Link, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../components'
import { styles } from '../../../template_style'

import { styles as obStyles } from './style'

export const OrangeBankMonthlyComplianceCertTemplate = ({
  agreementDate,
  formData,
  customData,
}: TemplateProps) => (
  <Document>
    <Page style={styles.body}>
      <Text style={obStyles.title}>Monthly Report Letter</Text>
      <Text style={{ ...obStyles.text, textIndent: 300 }}>
        Date: {moment().format('DD MMMM, YYYY')}
      </Text>
      <Text style={obStyles.text}>International Finance</Text>
      <Text style={obStyles.text}>Corporation 2121</Text>
      <Text style={obStyles.text}>Pennsylvania Avenue</Text>
      <Text style={obStyles.text}>N.W. Washington</Text>
      <Text style={obStyles.text}>D.C. 20433</Text>
      <Text style={obStyles.text}>U. S. A.</Text>
      <Text style={obStyles.text}>
        Attention: Director, Financial Institution Group
      </Text>
      <Text style={obStyles.textBold}>I. Agreement</Text>
      <Text style={obStyles.text}>
        Orange Bank Africa (the &quot;Beneficiary&quot;) refers to the Risk
        Sharing Agreement dated{' '}
        {moment.utc(agreementDate).format('DD MMMM, YYYY')} (the
        &quot;Agreement&quot;) between the Beneficiary and IFC, and to the
        Monthly Report dated {moment().format('DD MMMM, YYYY')} in respect of
        the Monthly Reporting Period ending on{' '}
        {moment(formData['effective-date']).format('DD MMMM, YYYY')}.
      </Text>
      <Text
        style={{
          ...obStyles.textIndented,
          textDecoration: 'underline',
          marginBottom: 0,
        }}
      >
        Monthly Report
      </Text>
      <Text style={{ ...obStyles.text, marginLeft: '38px' }}>
        The following information is prepared for all facilities granted by the
        Beneficiary over the Monthly Reporting Period and all Eligible MSME
        Facilities granted in previous Monthly Reporting Periods and whose
        balances were not nil as of the Monthly Determination Date:
      </Text>
      <Text style={{ ...obStyles.text, marginLeft: '46px' }}>
        <Link src={customData.fileUrl}>{customData.fileUrl}</Link>
      </Text>
      <Text style={obStyles.textBold}>
        II.{' '}
        <Text style={{ ...obStyles.textBold, marginLeft: 100 }}>
          Representations
        </Text>
      </Text>
      <Text style={obStyles.textIndented}>
        The Beneficiary represents to IFC that:
      </Text>
      <View style={obStyles.containerView}>
        <Text style={obStyles.textIndented}>(i)</Text>
        <Text style={{ ...obStyles.text, marginLeft: '46px' }}>
          each of the SME Facilities set forth in Section I (Monthly Report)
          above, meets the requirements for an Eligible MSME Facility.
        </Text>
      </View>
      <View style={obStyles.containerView}>
        <Text style={obStyles.textIndented}>(ii)</Text>
        <Text style={{ ...obStyles.text, marginLeft: '43px' }}>
          each of the representations set out at Section 3.02 (IFC Policies; E&S
          Management System) of the Agreement is true and accurate as of the
          date of this Payment Demand; and
        </Text>
      </View>
      <View style={obStyles.containerView}>
        <Text style={obStyles.textIndented}>(iii)</Text>
        <Text style={{ ...obStyles.text, marginLeft: '40px' }}>
          it has complied with and is not in breach of any of its obligations
          under the Agreement, including such obligations set forth in Article
          IV (Particular Covenants).
        </Text>
      </View>
      <View wrap={false}>
        <Text style={obStyles.text}>Orange Bank Africa</Text>
        <View style={{ ...obStyles.containerView, alignItems: 'flex-end' }}>
          <Text style={{ ...obStyles.text, minWidth: '42px' }}>By:</Text>
          <View style={obStyles.underlineView}>
            <Text style={styles.signTags}>[sig|req|signer1|signature]</Text>
          </View>
        </View>
        <View style={{ ...obStyles.containerView, alignItems: 'flex-end' }}>
          <Text style={{ ...obStyles.text, minWidth: '42px' }}>Name:</Text>
          <View style={obStyles.underlineView}>
            <Text style={obStyles.text}>
              {formData?.signers?.[0]?.name || `[Authorized Officer Name]`}
            </Text>
          </View>
        </View>
        <View style={{ ...obStyles.containerView, alignItems: 'flex-end' }}>
          <Text style={{ ...obStyles.text, minWidth: '42px' }}>Title:</Text>
          <View style={obStyles.underlineView}>
            <Text style={obStyles.text}>
              {formData?.signers?.[0]?.title || `[Authorized Officer Title]`}
            </Text>
          </View>
        </View>
      </View>
      <Footer />
    </Page>
  </Document>
)
