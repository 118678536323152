import React, { useContext, useEffect, useState } from 'react'

import FilterCalculationDate from '@components/filters/filter-calculation-date'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFacility from '@components/filters/filter-facility'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { Alert, Typography } from '@material-tailwind/react'

import { useDashboard } from './helper'

const MonitorDashboardL3 = () => {
  const [isProcessing, setIsProcessing] = useState(true)
  const {
    activeFilters,
    appliedFilters,
    optionFilters,
    company,
    setAppliedFilters,
  } = useContext(AuthContext)

  const { activeFacilityId } = activeFilters
  const {
    activeFacilityId: appliedActiveFacilityId,
    calculationDate: appliedCalculationDate,
  } = appliedFilters
  const { facilities = [], initialDate } = optionFilters
  const appliedFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === appliedActiveFacilityId
  )
  const isMasterFacility =
    typeof appliedFacility?.is_master_facility === 'boolean' &&
    appliedFacility?.is_master_facility === true

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    appliedActiveFacilityId === facilities?.[0]?.facility_id &&
    (appliedCalculationDate === initialDate ||
      appliedCalculationDate === company?.date_end)

  const { isFetching, tabs, error } = useDashboard(appliedActiveFacilityId)
  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      ((!isMasterFacility && appliedCalculationDate !== undefined) ||
        isMasterFacility) &&
      activeFacilityId !== undefined
    ) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    ;(tabs.length > 0 || error) && setIsProcessing(false)
  }, [isFetching])

  useEffect(() => setFilterReady(false), [company])
  return (
    <L3TabsLayout
      title="Dashboard"
      filters={{
        left: (
          <FilterCurrency
            hideSelect
            dateAsOfType={filterDateAsOfType.bankData}
          />
        ),
        middle: <FilterFacility />,
        right: <FilterCalculationDate hideSelect={isMasterFacility} />,
      }}
      disableReset={disableReset}
      tabs={tabs}
      empty={
        isProcessing || isFetching ? (
          <div className="flex justify-center">
            <ArrowPathIcon className="animate-spin text-primary-main w-8" />
          </div>
        ) : error ? (
          <div className="w-full flex justify-center">
            <Alert className="w-1/2 text-danger-main border border-danger-main text-center">
              Unexpected Error has occured.
            </Alert>
          </div>
        ) : (
          <Typography className="text-center">No data available</Typography>
        )
      }
      switchable
    />
  )
}

export default MonitorDashboardL3
