import { useContext } from 'react'
import { AxiosError } from 'axios'
import moment from 'moment'
import { useMutation } from 'react-query'

import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { Facility } from '@interfaces/facility'
import { CollateralListUploadRequest } from '@interfaces/manage-monitor-borrowing-base'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

export const useWriteCollateralFileHook = () => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )
  const { displayToastError } = useToastNotification()

  const { mutate: uploadFile } = useMutation(
    (params: CollateralListUploadRequest) => {
      return BorrowingBaseService.uploadCollateralReportData(params)
    },
    {
      onError: (err: AxiosError) => {
        return displayToastError(
          err.response?.status,
          'Collateral file upload failed'
        )
      },
    }
  )

  const afterEffect = (
    effectiveDate: string,
    customData: Record<string, any>
  ) => {
    if (customData.fileUrl) {
      uploadFile({
        facility_id: activeFacility?.facility_id,
        slug_name: activeFacility?.slug_name ?? company?.slug_name,
        calculation_date: moment(effectiveDate)
          .endOf('month')
          .format('YYYY-MM-DD'),
        file_name: customData.fileUrl,
      })
    }
  }

  return { afterEffect }
}
