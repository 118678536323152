import React from 'react'

import { Text, View } from '@react-pdf/renderer'

import { CascadeLogo } from './cascade-logo'
import { styles } from './style'

export const Footer = () => (
  <View style={styles.pageFooter}>
    <View style={styles.footerImage}>
      <CascadeLogo />
    </View>

    <Text style={styles.footerText}> Cascade Debt </Text>
    <Text style={styles.footerText}> www.cascadedebt.com </Text>
  </View>
)
