import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

import FilterDateRange from '@components/filters/filter-date-range'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterDocCentre from '@components/filters/filter-doc-centre'
import FilterFacility from '@components/filters/filter-facility'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { Facility } from '@interfaces/facility'

import AdvanceRequest from './advance-request'
import DocumentCentre from './document-centre'
import Waterfall from './waterfall'
const ActionsL3 = () => {
  const {
    activeFilters,
    appliedFilters,
    setAppliedFilters,
    company,
    optionFilters,
  } = useContext(AuthContext)
  const { facilities } = optionFilters
  const {
    activeFacilityId,
    docCategory,
    docStatus,
    docKeyword,
    range,
    dateEnd,
    dateStart,
    dateEndCohort,
    dateStartCohort,
    currency,
    docSigner,
  } = activeFilters
  const {
    activeFacilityId: appliedActiveFacilityId,
    docCategory: appliedDocCategory,
    docStatus: appliedDocStatus,
    docKeyword: appliedDocKeyword,
    range: appliedRange,
    dateEnd: appliedDateEnd,
    dateStart: appliedDateStart,
    dateEndCohort: appliedDateEndCohort,
    dateStartCohort: appliedDateStartCohort,
    currency: appliedCurrency,
    docSigner: appliedDocSigner,
  } = appliedFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id == appliedActiveFacilityId
  )
  const currentFilters = {
    docKeyword: appliedDocKeyword,
    docCategory: appliedDocCategory,
    docStatus: appliedDocStatus,
    dateStart: appliedDateStart,
    dateEnd: appliedDateEnd,
    docSigner: appliedDocSigner,
  }

  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `${activeFacility?.facility_id}_manage_monitor_actions`

  const shouldSetFilters = () =>
    (!filterReady ||
      (docCategory && !appliedDocCategory) ||
      (currency && !appliedCurrency)) &&
    dateStart !== dateEnd &&
    activeFacilityId &&
    (activeFacilityId !== appliedActiveFacilityId ||
      docCategory !== appliedDocCategory ||
      docStatus !== appliedDocStatus ||
      docKeyword !== appliedDocKeyword ||
      range !== appliedRange ||
      dateEnd !== appliedDateEnd ||
      dateStart !== appliedDateStart ||
      dateEndCohort !== appliedDateEndCohort ||
      dateStartCohort !== appliedDateStartCohort ||
      docSigner !== appliedDocSigner)

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (shouldSetFilters()) {
      setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters, appliedFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * This monstrosity is due to the filters being set from different places in the app.
   * There is no guarantee that that the values of the filters are set the same way across different clients.
   * Client A can set some values while company B has those values as undefined.
   *
   * In addition to that, we don't have a notion of initial state of the filters which make things more complicated.
   */
  const disableReset =
    (!currentFilters?.docKeyword ||
      optionFilters.initialDocCentreFilters?.docKeyword ===
        currentFilters?.docKeyword) &&
    (!currentFilters?.docCategory?.category ||
      optionFilters.initialDocCentreFilters?.docCategory?.category ===
        currentFilters?.docCategory?.category) &&
    (Array.isArray(currentFilters?.docCategory?.subcategory) &&
    Array.isArray(currentFilters?.docCategory?.subcategory)
      ? JSON.stringify(
          optionFilters.initialDocCentreFilters?.docCategory?.subcategory?.sort()
        ) === JSON.stringify(currentFilters?.docCategory?.subcategory?.sort())
      : !currentFilters?.docCategory?.subcategory ||
        optionFilters.initialDocCentreFilters?.docCategory?.subcategory ===
          currentFilters?.docCategory?.subcategory) &&
    (Array.isArray(optionFilters.initialDocCentreFilters?.docStatus?.status) &&
    Array.isArray(currentFilters?.docStatus?.status)
      ? JSON.stringify(
          optionFilters.initialDocCentreFilters?.docStatus?.status?.sort()
        ) === JSON.stringify(currentFilters?.docStatus?.status?.sort())
      : !currentFilters?.docStatus?.status ||
        optionFilters.initialDocCentreFilters?.docStatus?.status ===
          currentFilters?.docStatus?.status) &&
    (!currentFilters?.docStatus?.display ||
      optionFilters.initialDocCentreFilters?.docStatus?.display ===
        currentFilters?.docStatus?.display) &&
    moment(optionFilters.initialDocCentreFilters?.dateStart).format(
      'YYYY-MM-DD'
    ) === moment(currentFilters?.dateStart).format('YYYY-MM-DD') &&
    moment(optionFilters.initialDocCentreFilters?.dateEnd).format(
      'YYYY-MM-DD'
    ) === moment(currentFilters?.dateEnd).format('YYYY-MM-DD') &&
    currentFilters?.docSigner ===
      optionFilters.initialDocCentreFilters?.docSigner &&
    appliedActiveFacilityId === 1

  const [searchParams] = useSearchParams()

  const tab = searchParams.get('tab')

  const tabs = [
    { label: 'Document Centre', component: DocumentCentre },
    {
      label: 'Waterfall',
      component: Waterfall,
      feature: 'has_waterfall_report',
    },
    {
      label: 'Advance Request',
      component: AdvanceRequest,
      feature: 'has_advance_request',
    },
  ]
    .filter(
      t =>
        filter_access(
          `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`
        ) &&
        (t.feature && !!activeFacility ? activeFacility?.[t.feature] : true)
    )
    .map(t => {
      const canSign = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'sign'
      )
      const canCreate = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'create'
      )
      return {
        ...t,
        component: <t.component canSign={canSign} canCreate={canCreate} />,
      }
    })

  return (
    <L3TabsLayout
      title="Cashflows"
      filters={{
        left:
          tab == 'document-centre' ? (
            <FilterDocCentre />
          ) : (
            <FilterCurrency
              hideSelect
              dateAsOfType={filterDateAsOfType.bankData}
            />
          ),
        middle: [
          <FilterDateRange
            key="date-range-filter"
            isCurrentDate
            addMonthToEndDate
            noLimits={true}
          />,
          <FilterFacility key="facility-filter" />,
        ],
      }}
      disableReset={disableReset}
      tabs={tabs}
    />
  )
}
export default ActionsL3
