import React from 'react'
import moment from 'moment'

import {
  AllTableData,
  PortfolioCovenantCertTemplateProps,
} from '@interfaces/manage-reporting'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Footer, Table } from '../../../components'
import { styles } from '../../../template_style'

const PortfolioCovenantCertTemplate = ({
  tableData,
  agent,
  borrower,
  guarantors,
  agreementDate,
  effectiveDate,
  formData,
}: PortfolioCovenantCertTemplateProps) => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>
          Form of Compliance Certificate – Portfolio Covenants{' '}
        </Text>
        <Text style={styles.text}>{moment().format('DD/MM/YYYY')}</Text>
        <Text style={styles.text}>{agent ? agent : `[Agent]`}</Text>
        <Text style={styles.text}>Reference is made to:</Text>
        <Text style={styles.text}>
          Loan and Security Agreement, dated:{' '}
          {agreementDate ?? `[Date of Agreement]`} (as amended, restated,
          supplemented or otherwise modified from time to time), the “Loan
          Agreement”
        </Text>
        <Text style={styles.text}>Among :</Text>
        <Text style={styles.text}>
          the Borrower: {borrower ?? `[Borrower]`}
        </Text>
        <Text style={styles.text}>
          the Guarantor/s: {guarantors ?? `[Intentionally left blank]`}
        </Text>
        <Text style={styles.text}>
          the Lenders: the financial institutions or entities from time to time
          defined therein
        </Text>
        <Text style={styles.text}>the Agent: {agent ?? `[Agent]`}</Text>
        <Text style={styles.text}>
          Effective date of Compliance Certificate: {effectiveDate}
        </Text>
        <Text style={styles.text}>
          Capitalized terms used but not defined herein have the meanings
          specified in the Loan Agreement.
        </Text>
        <Text style={styles.text}>
          The undersigned is an officer of the Borrower, knowledgeable of all of
          the Obligors’ financial matters, is authorized to provide this
          certification regarding, and on behalf of, the Borrower, and hereby
          certifies in such capacity, that:
        </Text>
        <Text style={styles.text}>
          In accordance with the terms and conditions of the Loan Agreement, the
          undersigned has made, or has caused to be made under the undersigned’s
          supervision, a review of the Obligor’s compliance for the period
          covered by this Compliance Certificate with all covenants, conditions
          and terms under the Loan Agreement, and all representations and
          warranties contained therein, and
        </Text>
        <Text style={styles.textNumbered}>
          (i) except as described in the disclosure attached hereto, such review
          did not disclose, and the undersigned has no knowledge of, the
          existence of any condition or event which constitutes an Event of
          Default that has occurred and is continuing as of the date of this
          Compliance Certificate and
        </Text>
        <Text style={styles.textNumbered}>
          (ii) attached is disclosure that specifies, in reasonable detail, the
          nature of any such condition or event and any action taken or proposed
          to be taken with respect thereto.
        </Text>
        {tableData.map((data: AllTableData, index: number) =>
          Table({ data, index: index + 1 })
        )}
        <View wrap={false}>
          <Text style={styles.text}>Sincerely,</Text>
          <Text style={styles.text}>{borrower ?? `[Borrower]`}</Text>
          <Text style={styles.signTags}>[sig|req|signer1|signature]</Text>
          <Text style={styles.text}>
            {formData?.signers?.[0]?.name || `[Authorized Officer Name]`}
          </Text>
          <Text style={styles.text}>
            {formData?.signers?.[0]?.title || `[Authorized Officer Title]`}
          </Text>
          <Text style={styles.signTags}>[date|req|signer1|signature_date]</Text>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

export default PortfolioCovenantCertTemplate
