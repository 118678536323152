export const getFileFolder = (fileName: string) => {
  try {
    switch (true) {
      case fileName?.toLowerCase().includes('collateral'):
        return 'collateral-report'
      case fileName?.toLowerCase().includes('compliance'):
        return 'compliance-cert'
      case fileName?.toLowerCase().includes('waterfall'):
        return 'waterfall'
      default:
        throw new Error(`Cannot determine file folder of ${fileName}`)
    }
  } catch (error) {
    console.error(error)
    return null
  }
}
