import moment from 'moment'

export const getDates = (startDate: string) => {
  const result: Record<'monthly' | 'quarterly', string[]> = {
    monthly: [],
    quarterly: [],
  }
  const beginningOfTime = moment.utc(startDate)
  const today = moment.utc()
  let date = moment(today)

  const addToQuarterly = (d: moment.Moment) => {
    const quarterMonths = [3, 6, 9, 12]
    const month = d.month() + 1
    if (quarterMonths.includes(month)) {
      result.quarterly.push(d.format('YYYY-MM-DD'))
    }
  }

  while (date.isAfter(beginningOfTime)) {
    date = date.endOf('month').startOf('day')
    // add today's date if it's the end of the month
    if (date.isSameOrBefore(today)) {
      result.monthly.push(date.format('YYYY-MM-DD'))
      addToQuarterly(date)
    }
    date = date.subtract(1, 'month')
  }

  return result
}
