import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import Pagination from '@components/pagination'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { getOriginLabel } from '@helpers/status-formatter'
import {
  ArrowPathIcon,
  EllipsisHorizontalIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline'
import { CloudArrowUpIcon } from '@heroicons/react/24/solid'
import { Facility } from '@interfaces/facility'
import { AdvanceRequestResponse } from '@interfaces/manage-monitor-action'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import DocumentService from '@services/api-manage/docs'
import { ActionService } from '@services/api-manage/monitor-action'

import SignatureHistoryDialog from '../document-centre/dialogs/audit-dialog'

import { ACTIONS } from './constants'
import {
  AdvanceRequestHistoryDialog,
  ArchiveAdvanceRequestDialog,
  CreateAdvanceRequestDialog,
  UploadUpdateAdvanceRequestDialog,
} from './dialogs'
import StatusLabel from './status-label'

const AdvanceRequestL4 = ({
  canSign,
  canCreate,
}: {
  canSign: boolean
  canCreate: boolean
}) => {
  const { user } = useAuth0()
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [docCentreAction, setDocCentreAction] = useState<string>('')
  const facilityParam = searchParams.get('facility')

  const PER_PAGE = 15
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  const { activeFacilityId = 0 } = appliedFilters
  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id == activeFacilityId
  )
  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility_id: activeFacility?.facility_id,
    facility: activeFacility?.facility_name ?? '',
    page: page + 1,
    per_page: perPage,
  }

  const {
    data: advanceRequest,
    isFetching,
    refetch,
  } = useQuery(
    ['advance_request', filters],
    () => ActionService.getAdvanceRequest(filters),
    { ...getStaleMins(), enabled: !!activeFacility }
  )
  const { data = [], total = 0 } = advanceRequest ?? {}

  /**
   * Getting category for creating advance request
   */
  const { data: categoriesResult = [] } = useQuery(
    ['reporting-categories', { ...filters, action: 'create' }],
    () =>
      DocumentService.listCategories({
        ...filters,
        action: 'create',
      }),
    getStaleMins()
  )
  const category = categoriesResult?.find(
    cat => cat.category === 'Advance Request'
  )

  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])
  const [action, setAction] = useState<ACTIONS | undefined>(undefined)
  const [selectedId, setSelectedId] = useState<string | number | undefined>(
    undefined
  )
  const [docCentreId, setDocCentreId] = useState<string>()
  const selectedData = data?.find(
    (d: { id: number | undefined }) => d.id === selectedId
  )

  const _handler = (needUpdate?: boolean) => {
    if (needUpdate) {
      refetch()
    }
    setAction(undefined)
    setSelectedId(undefined)
    setDocCentreId(undefined)
    setDocCentreAction('')
  }

  const { mutate: getArByDocId } = useMutation(
    (params: {
      id: string
      slug_name: string
      facility_id: number
      facility: string
    }) => {
      return ActionService.getAdvanceRequestByDocId(params)
    },
    {
      onSuccess: (data: any) => {
        if (!!data[0]) {
          setSelectedId(data[0].id)
          setAction(
            docCentreAction == 'sign'
              ? ACTIONS.Sign
              : docCentreAction == 'view'
              ? ACTIONS.History
              : docCentreAction === 'void'
              ? ACTIONS.Archive
              : undefined
          )
        } else {
          toast.error('Advance Request does not exist', { autoClose: false })
          _handler(true)
        }
      },
    }
  )

  const { mutate: downloadAdvanceRequest } = useMutation(
    (params: { slug_name: string; facility_id: number; file: string }) => {
      return ActionService.downloadAdvanceRequest(params)
    },
    {
      onSuccess: res => {
        window.open(String(res), '_blank')
      },
    }
  )

  const showSign = (doc: AdvanceRequestResponse): boolean => {
    const docSignees = doc.signature_request?.signees
      ? JSON.parse(doc.signature_request.signees)
      : []
    const signatureIndex = docSignees.findIndex(
      (s: any) => s.signer_email_address === user?.email
    )
    const categoryOptions = category?.signers
      ? JSON.parse(category?.signers)
      : {}
    const signersTurn = categoryOptions.enforceSignOrder
      ? docSignees.slice(0, signatureIndex).every((s: any) => s.signed_at) &&
        !docSignees[signatureIndex]?.signed_at
      : signatureIndex >= 0
    return (
      doc.status === 'pending' &&
      !!doc.signature_request.signees &&
      signatureIndex >= 0 &&
      signersTurn &&
      canSign
    )
  }

  const expandedRowRender = (record: any) => {
    return (
      <div className="[&>div]:!mb-0">
        <Table
          columns={[
            {
              title: 'Lender',
              field: 'lender',
              align: 'center',
              width: '20%',
              render: (r: any) => (
                <Typography className="text-sm capitalize">
                  {r.lender?.replaceAll('_', ' ')}
                </Typography>
              ),
            },
            {
              title: 'Accrual Date',
              field: 'funded_date',
              align: 'center',
              render: (r: any) => r.funded_date,
            },
            {
              title: 'Amount',
              field: 'amount',
              width: '20%',
              align: 'right',
              head: { align: 'center' },
              render: (r: any) =>
                `${Intl.NumberFormat(undefined, {
                  maximumFractionDigits: 2,
                }).format(r.payout_amount)} ${record.currency}`,
            },
            {
              title: 'Proof of Transfer',
              field: 'proof_of_transfer_file',
              width: '25%',
              align: 'center',
              render: (r: any) =>
                r.proof_of_transfer_file ? (
                  <Typography
                    onClick={() => {
                      downloadAdvanceRequest({
                        slug_name:
                          activeFacility?.slug_name ?? company?.slug_name ?? '',
                        facility_id: activeFacility?.facility_id,
                        file: r.proof_of_transfer_file,
                      })
                    }}
                    className="flex items-center justify-center text-sm hover:text-primary-main cursor-pointer"
                  >
                    {r.proof_of_transfer_file}
                  </Typography>
                ) : (
                  '-'
                ),
            },
            {
              title: 'Additional Note',
              field: 'comment',
              align: 'center',
              render: (r: any) => r.comment || '-',
            },
            ...(canCreate
              ? [
                  {
                    title: '',
                    field: 'id',
                    width: '50px',
                    render: (r: any) => {
                      return (
                        <Menu placement="left-end">
                          <MenuHandler>
                            <button className="p-2 text-sm cursor-pointer rounded hover:bg-primary-surface-2 hover:text-primary-main">
                              <EllipsisHorizontalIcon className="w-4 h-4" />
                            </button>
                          </MenuHandler>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                setSelectedId(record.id)
                                setAction(ACTIONS.Update)
                              }}
                              className="flex hover:bg-primary-surface-2 hover:text-primary-main"
                            >
                              <PencilIcon className="mr-2 w-4 h-4" />
                              Edit
                            </MenuItem>
                            {r.proof_of_transfer_file ? null : (
                              <MenuItem
                                onClick={() => {
                                  setSelectedId(record.id)
                                  setAction(ACTIONS.Update)
                                }}
                                className="flex hover:bg-primary-surface-2 hover:text-primary-main"
                              >
                                <CloudArrowUpIcon className="mr-2 w-4 h-4" />
                                Upload Proof of Transfer
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      )
                    },
                  },
                ]
              : []),
          ]}
          data={record.lenders}
        />
      </div>
    )
  }

  const onExpand = (index: number) => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(i => i !== index)
      : [...expandedIndexes, index]

    setExpandedIndexes(indexes)
  }

  useEffect(() => {
    setExpandedIndexes([])
  }, [page])

  useEffect(() => {
    setPage(0)
  }, [company])

  useEffect(() => {
    setSelectedId(undefined)
    const dcId = searchParams.get('dcId')
    if (!!dcId && dcId != docCentreId) {
      setDocCentreId(dcId)
      searchParams.delete('dcId')
    }
    const docCentreActionParam = searchParams.get('dCAction')
    if (!!docCentreActionParam && docCentreActionParam != docCentreAction) {
      setDocCentreAction(docCentreActionParam)
      searchParams.delete('dCAction')
    }
    if (dcId || docCentreActionParam) {
      setSearchParams(searchParams)
    }
  }, [searchParams])

  useEffect(() => {
    if (
      !isFetching &&
      !!docCentreAction &&
      !!docCentreId &&
      !!activeFacility &&
      !facilityParam
    ) {
      switch (docCentreAction) {
        case 'create': {
          setAction(ACTIONS.Create)
          break
        }
        case 'upload': {
          setAction(ACTIONS.Upload)
          break
        }
        case 'sign':
        case 'view':
        case 'void': {
          const params = {
            id: docCentreId,
            slug_name: activeFacility.slug_name ?? company?.slug_name ?? '',
            facility_id: activeFacility.facility_id,
            facility: activeFacility.facility_name,
          }
          getArByDocId(params)

          break
        }
        default:
          break
      }
    }
  }, [isFetching, docCentreAction, activeFacility])

  return (
    <div className="flex flex-col p-4">
      {(canCreate || canSign) && (
        <>
          <CreateAdvanceRequestDialog
            open={!!action && [ACTIONS.Create].includes(action)}
            handler={_handler}
            docCentreId={docCentreId ?? ''}
            category={category as CategoriesResult}
            canSign={canSign}
          />
          <UploadUpdateAdvanceRequestDialog
            data={selectedData}
            docCentreId={docCentreId}
            open={
              !!action &&
              [ACTIONS.Upload, ACTIONS.Update, ACTIONS.Sign].includes(action)
            }
            handler={_handler}
            canSign={canSign}
            category={category ?? ({} as CategoriesResult)}
            action={action}
          />
        </>
      )}
      {canCreate && (
        <>
          <ArchiveAdvanceRequestDialog
            data={selectedData}
            open={!!action && [ACTIONS.Archive].includes(action)}
            handler={_handler}
          />
        </>
      )}
      <AdvanceRequestHistoryDialog
        data={selectedData}
        open={!!action && [ACTIONS.History].includes(action) && !!selectedData}
        handler={_handler}
      />
      <SignatureHistoryDialog
        docData={selectedData?.signature_request}
        open={!!action && [ACTIONS.Download].includes(action)}
        handler={_handler}
      />
      {canCreate && (
        <div className="flex justify-end items-center gap-4 mb-6">
          {!!category && (
            <Button
              color="primary"
              className="mb-2"
              onClick={() => {
                setSelectedId(undefined)
                setAction(ACTIONS.Create)
              }}
            >
              <PlusIcon className="w-4 h-4 mr-2" />
              Create
            </Button>
          )}
          <Button
            color="primary"
            className="mb-2"
            onClick={() => {
              setSelectedId(undefined)
              setAction(ACTIONS.Upload)
            }}
          >
            <CloudArrowUpIcon className="w-4 h-4 mr-2" />
            Upload
          </Button>
          <Button color="default" className="mb-2 " onClick={() => refetch()}>
            <ArrowPathIcon
              className={`${isFetching ? 'animate-spin' : ''} w-4 h-4 `}
            />
          </Button>
        </div>
      )}
      <Table
        loading={isFetching}
        columns={[
          { title: 'Request Date', field: 'date', align: 'center' },
          {
            title: 'Capital Requested',
            field: 'amount',
            align: 'right',
            head: { align: 'center' },
            render: (r: any) =>
              `${Intl.NumberFormat(undefined, {
                maximumFractionDigits: 2,
              }).format(r.amount)} ${r.currency}`,
          },
          {
            title: 'Advance Request Document',
            field: 'file',
            align: 'center',
            width: 200,
            render: (r: any) =>
              r.file ? (
                <Button
                  onClick={() => {
                    if (r.file && r.file.includes('.pdf')) {
                      downloadAdvanceRequest({
                        slug_name:
                          activeFacility?.slug_name ?? company?.slug_name ?? '',
                        facility_id: activeFacility?.facility_id,
                        file: r.file,
                      })
                    } else if (r.file) {
                      setSelectedId(r.id)
                      setAction(ACTIONS.Download)
                    } else {
                      setSelectedId(r.id)
                      setAction(ACTIONS.Update)
                    }
                  }}
                >
                  View
                </Button>
              ) : (
                '-'
              ),
          },
          {
            title: 'Origin',
            field: 'signature_request',
            render: r => {
              return getOriginLabel(r.signature_request.dropbox_api_supp)
            },
          },
          {
            title: 'Actions',
            field: 'actions',
            align: 'center',
            width: 200,
            render: (r: any) => {
              return r.status === 'nullified' ? (
                ''
              ) : canCreate || canSign ? (
                <Button
                  onClick={() => {
                    setSelectedId(r.id)
                    setAction(ACTIONS.Update)
                  }}
                >
                  {showSign(r) ? 'Sign' : 'Update'}
                </Button>
              ) : (
                ''
              )
            },
          },
          {
            title: 'Status',
            field: 'status',
            align: 'center',
            width: 210,
            render: (r: any) => <StatusLabel request={r} />,
          },
          {
            title: '',
            field: 'id',
            width: '50px',
            render: (r: any) => {
              return (
                <Menu placement="left-end">
                  <MenuHandler>
                    <button className="p-2 text-sm cursor-pointer rounded hover:bg-primary-surface-2 hover:text-primary-main">
                      <EllipsisHorizontalIcon className="w-4 h-4" />
                    </button>
                  </MenuHandler>
                  <MenuList>
                    {r.file
                      ? null
                      : canCreate && (
                          <MenuItem
                            onClick={() => {
                              setSelectedId(r.id)
                              setAction(ACTIONS.Update)
                            }}
                            className="flex hover:bg-primary-surface-2 hover:text-primary-main"
                          >
                            <CloudArrowUpIcon className="mr-2 w-4 h-4" />
                            Upload Document
                          </MenuItem>
                        )}
                    <MenuItem
                      onClick={() => {
                        setSelectedId(r.id)
                        setAction(ACTIONS.History)
                      }}
                      className="flex hover:bg-primary-surface-2 hover:text-primary-main"
                    >
                      <QueueListIcon className="mr-2 w-4 h-4" />
                      History
                    </MenuItem>
                    {canCreate && (
                      <MenuItem
                        onClick={() => {
                          setSelectedId(r.id)
                          setAction(ACTIONS.Archive)
                        }}
                        className="flex hover:bg-danger-surface !text-danger-main"
                      >
                        <MinusCircleIcon className="mr-2 w-4 h-4" />
                        Set to Null
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )
            },
          },
        ]}
        data={data}
        expandable={{
          expandedIndexes,
          expandedRowRender,
          onExpand,
        }}
      />
      <Pagination
        id="user-table-pagination"
        total={total}
        page={page}
        onChangePage={p => setPage(p)}
        limit={perPage}
        limits={Array(2)
          .fill('')
          .map((_, i) => PER_PAGE * (i + 1))}
        onChangeLimit={l => setPerPage(l)}
      />
    </div>
  )
}

export default AdvanceRequestL4
