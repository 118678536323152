import React from 'react'
import { useMutation } from 'react-query'

import Button from '@components/atoms/button'
import { useToastNotification } from '@helpers/notification-hook'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, DialogBody, DialogHeader } from '@material-tailwind/react'
import { WaterfallService } from '@services/api-manage/monitor-waterfall'

interface Props {
  slug: string
  facilityId: number
  waterfallId: string
  dialogOpen: boolean
  closeHandler: (x?: boolean) => void
}

export const VoidConfirmationDialog = ({
  slug,
  facilityId,
  waterfallId,
  dialogOpen,
  closeHandler,
}: Props) => {
  const { displayToastError, displayToastSuccess } = useToastNotification()
  const { mutate: voidWaterfall, isLoading: voidLoading } = useMutation(
    () =>
      WaterfallService.void({
        slug,
        facilityId: facilityId.toString(),
        waterfallId,
      }),
    {
      onSuccess: () => {
        displayToastSuccess('Waterfall report has been recalled')
        closeHandler(true)
      },
      onError: () => {
        displayToastError(undefined, 'Failed to recall waterfall report')
      },
    }
  )
  return (
    <Dialog open={dialogOpen} handler={() => closeHandler()} size="md">
      <DialogHeader className="text-xl flex justify-between">
        Recall waterfall
        <XMarkIcon
          onClick={() => closeHandler()}
          className="w-6 h-6 cursor-pointer"
        />
      </DialogHeader>
      <DialogBody divider className="flex flex-col overflow-y-auto gap-4">
        <div className="w-full">
          Are you sure you want to recall this waterfall?
        </div>
        <div className="flex flex-row w-full justify-end gap-4">
          <Button
            color="default"
            onClick={() => closeHandler()}
            disabled={voidLoading}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => voidWaterfall()}
            disabled={voidLoading}
          >
            Yes, Recall it
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  )
}
