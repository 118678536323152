import React from 'react'

import Table from '@components/table'
import { CovenantType } from '@interfaces/manage-monitor-covenant'

import { useCovenantDashboard } from './helper'
import CovenantVintageL4 from './vintage'

interface CovenantL4Props {
  type: CovenantType
}

const CovenantL4 = ({ type }: CovenantL4Props) => {
  const { isFetching, isFetchingDetail, columns, list, footerData } =
    useCovenantDashboard(type)

  const common_table_data = list.filter(x => !x.covenants[0]?.special_covenant)
  const special_table_data = list.filter(
    x => !!x.covenants[0]?.special_covenant
  )
  return (
    <>
      {common_table_data.length > 0 && (
        <Table
          loading={isFetching || !!isFetchingDetail}
          containerClass="bg-neutral-border-1 max-h-[calc(100vh-100px)] overflow-auto [&>table]:w-auto [&>table>thead]:sticky [&>table>thead]:top-0 [&>table>thead>tr>th]:capitalize [&>table>tfoot>tr>td:nth-child(1)]:text-right [&>table>tfoot>tr>td>*]:font-bold"
          columns={columns as any}
          data={common_table_data}
          footerData={[footerData]}
        />
      )}

      {special_table_data.map(d => {
        if (d.covenants[0]?.special_covenant === 'vintage') {
          return (
            <CovenantVintageL4
              key={d.title}
              covenant={d.covenants[0]}
              summary={d.covenant[0]}
            />
          )
        }
        return <></>
      })}
    </>
  )
}

export default CovenantL4
